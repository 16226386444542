import { AppBackground, Button, Drop, HeaderWithBack, Input, Text } from "components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg'
import { ReactComponent as PawIcon } from '../../../../assets/icons/paw.svg'
import { ReactComponent as XSimpleIcon } from '../../../../assets/icons/x_simple_grey.svg'
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/simple_arrow.svg'
import { ReactComponent as Paw } from '../../../../assets/icons/paw.svg'
import { BodyContainer, BodyScroll, CameraPreviewContainer, Container, DateContainer, InputContainer, InputWrapper, PreviewButtonContainer, StyledKeyboardAvoidingView } from "./styles";
import { CamaraCard, ImageTypeSelector } from "../../components/CameraCard";
import DatePicker from 'react-datepicker';
import { useAppDispatch, useAppSelector } from "store";
import { FormikValues } from "formik";
import { usePetVaccines } from "business-logic";

const AddVaccine: React.FC = () => {
    const location = useLocation();
  
    const type = location.state.type ?? 'OPTIONAL';

    const vaccines = useAppSelector((state) => state.petDocumentation.vaccines)
    const mandatoryVaccines = useAppSelector((state) => state.petDocumentation.mandatoryVaccines)
    const actualVaccineUrl = useAppSelector((state) => state.petDocumentation.actualVaccineUrl)
    const [showModal, setShowModal] = useState(false)
    const [date, setDate] = useState(new Date())
    const navigate = useNavigate()

    const onNextPress = (values: FormikValues) => {
        navigate('/services/passport/vaccines')
    }

    const { setFieldValue, values, handleBlur, handleChange, errors, handleSubmit, getVaccines } = usePetVaccines(onNextPress)

    const onBackPress = () => {
        navigate('/services/passport/vaccines')
    }

    useEffect(() => {
        console.debug('ERRORS', errors)
    }, [errors])

    useEffect(() => {
        if (vaccines.length <= 0 || mandatoryVaccines.length <= 0) {
            getVaccines()
        }
    }, [vaccines, mandatoryVaccines])

    const handleSubmitForm = () => {
    setFieldValue('vaccineDate', date).then(() => {
        if (actualVaccineUrl) {
        setFieldValue('vaccineUrl', actualVaccineUrl).then(() => {
            handleSubmit()
        })
        }
    })
    }

    const handleDateChange = (date: Date | null) => {
        setDate(date || new Date())
    }
    const onGalleryPress = (file: File, preview: string) => {

    }

    const handlePreviewPress = () => {
        setShowModal(!showModal)
    }

    return (
        <AppBackground isSafe>
            <HeaderWithBack
                title={('Agregar vacuna')}
                backPress={onBackPress}
                backIcon={<BackIcon />}
            />
            <Container>
                <StyledKeyboardAvoidingView>
                <InputContainer>
                    <Drop
                        placeholder={('Selecciona la vacuna')}
                        icon={<DropdownIcon />}
                        data={(type === "MANDATORY" ? mandatoryVaccines : vaccines).map((vaccine) => ({ title: vaccine.name, id: vaccine.guid, data: { ...vaccine } }))}
                        active={(type === "MANDATORY" ? mandatoryVaccines : vaccines).findIndex((obj) => obj.guid === values.vaccineGuid)}
                        selectedItem={(type === "MANDATORY" ? mandatoryVaccines : vaccines).findIndex((obj) => obj.guid === values.vaccineGuid)}
                        onPress={(item) => {
                            setFieldValue('vaccineGuid', item.id)
                        }}
                        buttonIcon={<Paw width={24} height={24} />}
                        allowIconAndText
                        variant="outlined"
                    />
                    {!!errors.vaccineGuid ? (
                    <Text type="small" color="error">
                        {errors.vaccineGuid.toString()}
                    </Text>
                    ) : (<></>)}
                </InputContainer>
                <BodyScroll>
                    <BodyContainer>
                    <CameraPreviewContainer>
                        <CamaraCard
                            title={('Elige una foto')}
                            subtitle={('Sube una foto de la cartilla de vacunación de tu mascota')}
                            onGalleryPress={onGalleryPress}
                            url={actualVaccineUrl}
                            type={ImageTypeSelector.VACCINE}
                        />
                        {errors.vaccineUrl ? (
                        <Text type="small" color="error">
                            {errors.vaccineUrl.toString()}
                        </Text>
                        ) : (<></>)}
                        {actualVaccineUrl ? (
                        <PreviewButtonContainer>
                            <Button
                                title={('Vista previa')}
                                icon={<PawIcon width={24} height={24} />}
                                size="block"
                                textType="cta_medium"
                                borderRadius={5}
                                textColor="background0"
                                allowIconAndText
                                color="primary"
                                onPress={handlePreviewPress}
                            />
                        </PreviewButtonContainer>
                        ) : (
                        <></>
                        )}
                    </CameraPreviewContainer>
                    <DateContainer>
                        <Text type="H3" color="foreground0" fontWeight="700" textAlign="center">
                            {('Cita de vacunación')}
                        </Text>
                        <DatePicker 
                            selected={date}
                            date={date} 
                            onChange={handleDateChange}  
                            dateFormat="yyyy/MM/dd"
                            locale="es"
                            placeholderText="Seleccionar fecha"
                        />
                    </DateContainer>
                    <InputWrapper>
                        <Text type="H3" color="foreground0" fontWeight="700" textAlign="center">
                        {('Referencia de lote')}
                        </Text>
                        <InputContainer>
                        <Input
                            inputProps={{
                            value: values.vaccineLote,
                            onChangeText: handleChange('vaccineLote'),
                            onBlur: handleBlur('vaccineLote'),
                            placeholder: ('Lote'),
                            }}
                        />
                        {errors.vaccineLote && (
                            <Text type="small" color="error">
                            {errors.vaccineLote.toString()}
                            </Text>
                        )}
                        </InputContainer>
                    </InputWrapper>
                    </BodyContainer>
                </BodyScroll>
                </StyledKeyboardAvoidingView>
                <Button
                    title={('Agregar')}
                    icon={<PawIcon width={24} height={24} />}
                    size="block"
                    textType="cta_medium"
                    borderRadius={5}
                    textColor="background0"
                    allowIconAndText
                    color="primary"
                    onPress={handleSubmitForm}
                />
            </Container>
            {/* <Modal
                isVisible={showModal}
                backdropOpacity={0.7}
                useNativeDriver
                useNativeDriverForBackdrop
                hideModalContentWhileAnimating
                onBackdropPress={handlePreviewPress}>
                <ModalContainer>
                <ModalHeader>
                    <Text type="H5">{t('petDocumentation.vaccine.preview')}</Text>
                    <TouchableOpacity onPress={handlePreviewPress}>
                    <XSimpleIcon width={30} height={30} />
                    </TouchableOpacity>
                </ModalHeader>
                <Image source={{ uri: values.vaccineUrl }} style={{ width: 300, height: 300 }} />
                </ModalContainer>
            </Modal> */}
        </AppBackground>
    )
}

export default AddVaccine;