import styled from '@emotion/native'
import { TouchableOpacity } from 'react-native'
import Text from '../../atoms/Text'

export const Container = styled(TouchableOpacity)(({ theme }) => ({
  alignItems: 'center',
  maxWidth: 100,
  rowGap: 5,
}))

export const LabelText = styled(Text)(({ theme }) => ({
  fontWeight: 'bold',
  fontFamily: 'bold',
}))
