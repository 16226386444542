import styled from '@emotion/native'
import { ScrollView, View } from 'react-native'
import Text from '../../atoms/Text'
import ResourceImage from '../../atoms/ResourceImage'

export const Container = styled(ScrollView)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: theme.sizes.sm,
}))

export const InsuranceTitle = styled(Text)(({ theme }) => ({
  width: 180,
  fontWeight: '700',
  textAlign: 'center',
  paddingBottom: theme.sizes.sm,
}))

export const CardContainer = styled(View)(({ theme }) => ({
  gap: theme.sizes.sm,
  width: '100%',
}))

export const BottomCardText = styled(Text)(() => ({
  textAlign: 'center',
}))

export const ItemsContainer = styled(View)(({ theme }) => ({
  width: '100%',
  paddingVertical: theme.sizes.sm,
  gap: theme.sizes.sm,
}))

export const StaticButton = styled.View(({ theme }) => ({
  paddingVertical: theme.sizes.sm,
  paddingHorizontal: theme.sizes.sm,
}))
