import React from 'react'
import { StyleProp, TextProps, TextStyle } from 'react-native'
import { Colors } from 'themes'
import { StyledText, TextProps as StyledTextProps, textAlign } from './style'

export interface CustomTextProps extends StyledTextProps, TextProps {
  numberOfLines?: number
  style?: StyleProp<TextStyle>
  color?: keyof Colors
  children?: React.ReactNode
  textAlign?: textAlign
}

const Text: React.FC<CustomTextProps> = ({
  children,
  type,
  color,
  fontSize,
  fontWeight,
  fontFamily,
  lineHeight,
  letterSpacing,
  numberOfLines,
  textAlign,
  style,
  ...extra
}) => {
  return (
    <StyledText
      type={type}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      numberOfLines={numberOfLines}
      style={style}
      textAlign={textAlign}
      {...extra}>
      {children}
    </StyledText>
  )
}

export default Text
