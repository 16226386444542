import { BenfitItem, PetPicture, PetTemplate, Text } from "components";
import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "store";
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil-icon.svg'
import { ReactComponent as Health } from '../../../../assets/icons/health.svg'
import { ReactComponent as IdBadge } from '../../../../assets/icons/id_badge.svg'
import { ReactComponent as Vaccine } from '../../../../assets/icons/vaccine.svg'
import { ReactComponent as CheckIcon } from '../../../../assets/icons/rounded_check.svg'
import { ReactComponent as PawIcon } from '../../../../assets/icons/paw_blue.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/rounded_cross.svg'
import { ReactComponent as StoreIcon } from '../../../../assets/icons/store_icon.svg'
import { Platform, ScrollView } from "react-native";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePet, usePetAddinsurance } from "business-logic";
import { ActiveContainer, ActiveText, BenefitContainer, BenefitsContainer, BulletButton, BulletsContainer, Container, DatacContainer, LabelText, ModalContainer, PassContainer, PetImage, PictureContainer, PkPassPress, QRImage } from "./styles";
import Modal from 'react-native-modal'

interface BenefitItemProps {
  icon: React.JSX.Element
  label: string
  mark: React.JSX.Element
  onPress?: () => void
}

const Passport: React.FC = () => {

  const {
    name,
    avatar: photo,
    qrCode: qrUri,
    benefits: petBenefits,
    guid: petGUID,
    hasAllVaccines,
    hasInsurance,
    hasDocument,
    allowPkpass,
  } = useAppSelector((state) => state.selectedPet)
  const navigate = useNavigate()

  const { getPetDetails } = usePet()
  const [editModalVisible, setEditModalVisible] = React.useState(false)
  const [hasExternalInsurance, setHasExternalInsurance] = React.useState(false)
  const { getInsurance } = usePetAddinsurance()


  useEffect(() => {
    const hasInsurance = async () => {
      const insurance = await getInsurance()
      setHasExternalInsurance(insurance?.hasInsurance ?? false)
    }
    getPetDetails()
    hasInsurance()

  }, [])

  const onDocumentPress = () => {
    navigate('/services/passport/documents')
  }

  const onVaccinePress = () => {
    navigate('/services/passport/vaccines')
  }

  const onInsurancePress = async () => {
    navigate('/services/passport/insurance')
  }

  const benefits = useMemo(() => {
    if (petBenefits) {
      return [
        {
          icon: <IdBadge width={50} height={50}/>,
          label: ('Documentación'),
          mark: hasDocument ? <CheckIcon width={25} height={25}/> : <CrossIcon height={25} width={25}/>,
          onPress: onDocumentPress,
        },
        {
          icon: <Vaccine width={50} height={50}/>,
          label: ('Vacunas'),
          mark: hasAllVaccines ? <CheckIcon width={25} height={25}/> : <CrossIcon height={25} width={25}/>,
          onPress: onVaccinePress,
        },
        {
          icon: <Health width={50} height={50}/>,
          label: ('Seguro'),
          mark: (hasInsurance || hasExternalInsurance) ? <CheckIcon width={25} height={25}/> : <CrossIcon height={25} width={25}/>,
          onPress: onInsurancePress,
        },
      ] as unknown as BenefitItemProps[]
    }
    return []
  }, [petBenefits, petGUID, hasAllVaccines, hasInsurance, hasDocument, hasExternalInsurance])

  const active = useMemo(() => {
    return hasAllVaccines && hasInsurance && hasDocument
  }, [hasAllVaccines, hasInsurance, hasDocument])

  const onPetNamePress = () => {
    setEditModalVisible(true)
  }

  return (
    <React.Fragment>
    <Modal
      isVisible={editModalVisible}
      backdropOpacity={0.7}
      useNativeDriver
      useNativeDriverForBackdrop
      hideModalContentWhileAnimating
      onBackdropPress={() => setEditModalVisible(false)}
      style={{ margin: 10, justifyContent: 'center' }}>
      <ModalContainer>
        <Text
          type="H2"
          style={{
            textAlign: 'center',
          }}>
          ¿Qué deseas editar?
        </Text>
        <BulletsContainer>
          <BulletButton
            onPress={() => {
              setEditModalVisible(false)
              navigate('/services/passport/PetEdit')
            }}>
            <PawIcon width={30} height={30} />
            <Text type="H4">Mascota</Text>
          </BulletButton>
          {/* <BulletButton
            onPress={() => {
              setEditModalVisible(false)
              // navigation.navigate('PetDocumentation')
            }}>
            <IdBadge width={30} height={30} />
            <Text type="H4">Documentación</Text>
          </BulletButton>
          <BulletButton
            onPress={() => {
              setEditModalVisible(false)
              // navigation.navigate('PetVaccines')
            }}>
            <Vaccine width={30} height={30} />
            <Text type="H4">Vacunación</Text>
          </BulletButton>
          <BulletButton
            onPress={() => {
              setEditModalVisible(false)
              onInsurancePress()
            }}>
            <Health width={30} height={30} />
            <Text type="H4">Seguro animal</Text>
          </BulletButton> */}
        </BulletsContainer>
      </ModalContainer>
    </Modal>
      <ScrollView>
        <Container>
          <DatacContainer>
            <PetPicture
              src={photo}
              isWeb
              onStatePress={onPetNamePress}
              showState={true}
              PencilIcon={<PencilIcon width={15} height={15}/>}
            />
            <Text type="H3">{`${('Hola soy ')}${name}`}</Text>
          </DatacContainer>
          <BenefitsContainer>
            {benefits.map((benefit, index) => (
              <BenefitContainer
                key={index} 
                onClick={() => benefit.onPress?.()}
              >
                {benefit.icon}
                <LabelText type="small">{benefit.label}</LabelText>
                {benefit.mark}
              </BenefitContainer>
            ))}
          </BenefitsContainer>
          {qrUri && (
            <QRImage
              aspectRatio={1}
              source={{
                uri: qrUri,
              }}
              placeHolder={false}
            />
          )}
          {/* {allowPkpass && <PassContainer>{<StoreIcon/>}</PassContainer>} */}
          <ActiveContainer active={active}>
            <ActiveText type="H3">{active ? ('Activo') : ('Inactivo')}</ActiveText>
            <ActiveText type="H5">{active ? ('PetPass ID activo') : ('PetPass ID inactivo')}</ActiveText>
          </ActiveContainer>
        </Container>
      </ScrollView>
    </React.Fragment>
  )
};

export default Passport;