import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'
import Text from '../../../atoms/Text'

export const AddIcon = styled(TouchableOpacity)<{ width: number; aspectRatio: number }>(
  ({ theme, width, aspectRatio }) => ({
    borderColor: theme.colors.primary,
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    aspectRatio: aspectRatio,
    width,
  }),
)

export const ResourceContainer = styled(View)<{ width: number; aspectRatio: number }>(
  ({ theme, width, aspectRatio }) => ({
    alignItems: 'center',
    width: width,
    aspectRatio: aspectRatio,
  }),
)

export const PercentageContainer = styled(View)(({ theme }) => ({
  backgroundColor: theme.colors.primary,
  borderRadius: 15,
  position: 'absolute',
  bottom: 0,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.sizes.xs,
  paddingVertical: theme.sizes.xxxs,
  paddingHorizontal: theme.sizes.xs,
}))

export const LabelContainer = styled(View)(({ theme }) => ({
  borderRadius: 15,
  position: 'relative',
  bottom: 0,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.sizes.xs,
  margin: 0,
  padding: 0,
}))

export const PercentageText = styled(Text)(({ theme }) => ({
  color: 'white',
  fontWeight: '900',
}))

export const LabelText = styled(Text)(({ theme }) => ({
  width: '100%',
  margin: 0,
  padding: 0,
  textAlign: 'center',
}))
