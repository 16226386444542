import React, { FC } from 'react'
import { InfoContainer, ItemContainer } from './style'
import { ReactComponent as CheckOutlineIcon } from '../../assets/icons/check_icon_blue_outline.svg'
import { Text } from 'components'

export interface ItemWithIconProps {
  title: string
  description: string
  onPress?: () => void
}

export const ItemWithIcon: FC<ItemWithIconProps> = ({ title, description, onPress }) => {
  return (
    <ItemContainer onPress={() => onPress?.()}>
      <CheckOutlineIcon />
      <InfoContainer>
        <Text type="H5" fontWeight="bold" fontFamily="bold">
          {title}
        </Text>
        <Text type="caption">{description}</Text>
      </InfoContainer>
    </ItemContainer>
  )
}
