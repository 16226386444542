import React, { PropsWithChildren } from "react";
import { MainWrapper } from "./styles";

const AuthContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <MainWrapper>
      {children}
    </MainWrapper>
  )
}

export default AuthContainer;