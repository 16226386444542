import { useTheme } from '@emotion/react';
//import { LoginTemplate } from 'components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContainer from '../../components/AuthContainer';

const LoginScreen: FC = () => {

  const theme = useTheme()
  const onSuccess = () => {
    //navigation.navigate('BottomTabs', { screen: 'Home' })
  }

  //const { formik, apiError } = useLogin({ onSuccess })
  //const { values, errors, handleChange, handleBlur, handleSubmit } = formik
  const onForgotPasswordPress = () => {
    //navigation.navigate('ForgotPassword')
  }

  const onSignUpPress = () => {
    //navigation.navigate('Register')
  }

  const onBackPress = () => {
    //navigation.goBack()
  }

  const onFocus = () => {
    // setApiError('')
  }

  return (
    <AuthContainer>
      <text>Login</text>
    </AuthContainer>
  )
} 

export default LoginScreen;