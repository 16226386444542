import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'
import Text from '../../../atoms/Text'
import ResourceImage from '../../../atoms/ResourceImage'

export const ItemContainer = styled(View)<{ width: number; aspectRatio: number }>(({ theme, width, aspectRatio }) => ({
  borderColor: '#32323230',
  borderWidth: 1,
  width: width,
  // height: 180,
  aspectRatio: aspectRatio,
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.sizes.xs,
  overflow: 'hidden',
}))

export const Title = styled(Text)(({ theme }) => ({
  width: '100%',
}))

export const PriceContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'flex-end',
  width: '100%',
}))

export const AddButton = styled(TouchableOpacity)(({ theme }) => ({}))

export const PriceText = styled(Text)(({ theme }) => ({}))

export const Resource = styled(ResourceImage)(({ width }: { width: number }) => ({
  width: width,
}))

export const TextContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
}))

export const OldPriceText = styled(Text)(({ theme }) => ({
  textDecorationLine: 'line-through',
  fontSize: theme.sizes.xxs * 1.2,
  color: '#8E8E8E',
}))

export const PercentageOffContainer = styled(View)<{ background?: string }>(({ theme, background }) => ({
  position: 'absolute',
  left: theme.sizes.xxxs,
  bottom: 0,
  paddingHorizontal: theme.sizes.xs,
  paddingVertical: theme.sizes.xxxs,
  borderRadius: 20,
  backgroundColor: background ?? theme.colors.quaternary,
}))
