import { useSos } from 'business-logic'
import useLocation from 'business-logic/dist/Auth/Misc'
import { AppBackground, Button, HeaderWithBack, Input } from 'components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyboardAvoidingView, Platform } from 'react-native'
// import Map, { Region } from 'react-native-maps'
import { useDispatch } from 'react-redux'
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg'
import { ReactComponent as Paw } from '../../../../assets/icons/paw.svg'
import { ReactComponent as MarkerIcon} from '../../../../assets/icons/sos_map_marker.svg'
import { ContentContainer, FormContainer, MapContainer } from './style'
import { useNavigate } from 'react-router-dom'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '700px',
}

interface MapCoordinates {
  lat: number
  lng: number
}

const apiKey = 'AIzaSyCydlBSzE5sbL00JEoEf4BBviSduFu5rdk'

export const SosSelectLocation: React.FC = () => {
  const dispatch = useDispatch()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  })
  const navigate = useNavigate()
  const [currentLocation, setCurrentLocation] = useState<MapCoordinates>()

  const [googleMap, setMap] = useState<google.maps.Map>()

  const onSosSubmit = async () => {
    await createSos(values.values)
    navigate(-1)
  }
  const { createSos, setFieldValue, ...values } = useSos({ onSubmit: onSosSubmit })
  const onLocateSuccess = async (lat: number, lon: number) => {
    handleZoomToLocation(lat, lon)
    console.debug('initial location', lat, lon)
  }

  useEffect(() => {
    locate(true)
  }, [])

  const onLocateError = (error: any) => {
    console.debug(error.code, error.message)
  }
  const locate = useLocation({ onSuccess: onLocateSuccess, onError: onLocateError })

  const handleZoomToLocation = (latitude: number, longitude: number) => {
    // mapRef.current?.animateToRegion(
    //   {
    //     latitude,
    //     longitude,
    //     latitudeDelta: 0.01, // Small number for zooming in
    //     longitudeDelta: 0.01, // Small number for zooming in
    //   },
    //   1000,
    // ) // Duration of animation, in milliseconds
  }

  // const handleRegionChangeComplete = (newRegion: Region) => {
  //   values.setFieldValue('latitude', newRegion.latitude)
  //   values.setFieldValue('longitude', newRegion.longitude)
  // }

  const onUnmount = useCallback(function callback() {
    setMap(undefined)
  }, [])

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          // seta(JSON.stringify(result.state))
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const coordinates = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }
              const bounds = new window.google.maps.LatLngBounds(coordinates)
              map.fitBounds(bounds)
              setCurrentLocation(coordinates)
            },
            (error) => {
              console.error('Error getting user location:', error)
              const bounds = new window.google.maps.LatLngBounds({
                lat: -3.745,
                lng: -38.523,
              })
              map.fitBounds(bounds)
            },
          )
        } else if (result.state === 'denied') {
          console.error('User denied geolocation permission')
          const bounds = new window.google.maps.LatLngBounds({
            lat: -3.745,
            lng: -38.523,
          })
          map.fitBounds(bounds)
        }
      })
    } else {
      const bounds = new window.google.maps.LatLngBounds({
        lat: -3.745,
        lng: -38.523,
      })
      map.fitBounds(bounds)
    }

    setMap(map)
  }, [])

  const getRegionForCoordinates = async (region: MapCoordinates) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${region.lat},${region.lng}&key=${apiKey}`,
    )
    const data = await response.json()

    let address = ''
    const length = data.results[0].address_components.length - 1
    data.results[0].address_components.map((name: any, index: number) => {
      if (name.long_name) {
        address += name.long_name + (index === length ? '' : ', ')
      }
    })
  }

  const handleRegionChangeComplete = () => {
    const newCenter = googleMap?.getCenter()
    if (newCenter) {
      const innerMarkerCoords = {
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      }
      getRegionForCoordinates(innerMarkerCoords)
      // set new center
      setCurrentLocation({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      })
      setFieldValue('latitude', newCenter.lat())
      setFieldValue('longitude', newCenter.lng())
    }
  }

  return (
    <AppBackground isSafe>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 10 : 20}>
        <HeaderWithBack title="SOS" backIcon={<BackIcon />} backPress={() => navigate(-1)} />
        <ContentContainer>
          {isLoaded && (
            <MapContainer id="map-container">
              <GoogleMap
                onDragEnd={handleRegionChangeComplete}
                options={{ streetViewControl: false }}
                mapContainerStyle={containerStyle}
                zoom={13.9}
                onLoad={onLoad}
                onUnmount={onUnmount}
                id="map">
                {currentLocation && (
                  <Marker
                    position={{ lat: currentLocation.lat, lng: currentLocation.lng }}
                    icon={{
                      url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Blue marker icon
                    }}
                  />
                )}
              </GoogleMap>
            </MapContainer>
          )}
          <FormContainer>
            <Input
              inputProps={{
                value: values.values.description,
                onChangeText: values.handleChange('description'),
                onBlur: values.handleBlur('description'),
                placeholder: 'Comentarios SOS (Opcional)',
              }}
            />
            <Button
              onPress={values.handleSubmit}
              title={'Continuar'}
              icon={<Paw width={24} height={24} />}
              allowIconAndText
              size="block"
              textType="cta_medium"
              borderRadius={5}
              textColor="background0"
              color="primary"
            />
          </FormContainer>
        </ContentContainer>
      </KeyboardAvoidingView>
    </AppBackground>
  )
}

export default SosSelectLocation
