import styled from '@emotion/native'
import { View } from 'react-native'
import Text from '../Text'

export const Container = styled(View)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.sizes.xs,
  paddingHorizontal: theme.sizes.xxs,
}))

export const TextContainer = styled(Text)(({ theme }) => ({
  marginHorizontal: theme.sizes.xxs,
}))

export const Line = styled(View)(({ theme }) => {
  return {
    flex: 1,
    height: 1,
    backgroundColor: theme.colors.foreground1,
  }
})
