"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDimensions = void 0;
var react_1 = require("react");
var react_native_1 = require("react-native");
var react_native_device_info_1 = require("react-native-device-info");
// Define base dimensions for different screen types
var BASE_DIMENSIONS = {
    Small: { width: 350, height: 640 },
    Medium: { width: 600, height: 960 },
    Large: { width: 900, height: 1200 },
    'Extra-Large': { width: 1200, height: 1600 },
};
var useDimensions = function () {
    var width = (0, react_native_1.useWindowDimensions)().width;
    var screenType = (0, react_1.useMemo)(function () {
        if (react_native_1.Platform.OS === 'web') {
            if (width < 600) {
                return 'Small';
            }
            else if (width >= 600 && width < 900) {
                return 'Medium';
            }
            else if (width >= 900 && width < 1200) {
                return 'Large';
            }
            else {
                return 'Extra-Large';
            }
        }
        else {
            var deviceType = react_native_device_info_1.default.getDeviceType();
            switch (deviceType) {
                case 'Handset':
                    return 'Small';
                case 'Tablet':
                    return 'Medium';
                case 'Tv':
                    return 'Large';
                default:
                    return 'Extra-Large';
            }
        }
    }, [width]);
    // Use screenType to determine the guideline base dimensions
    var guidelineBaseWidth = BASE_DIMENSIONS[screenType].width;
    var scale = (0, react_1.useCallback)(function (value, factor) {
        if (factor === void 0) { factor = 0.4; }
        return value + ((width / guidelineBaseWidth) * value - value) * factor;
    }, [width, guidelineBaseWidth]);
    var selectDeviceType = (0, react_1.useCallback)(function (spec, defaultValue) {
        var _a;
        return (_a = spec[screenType]) !== null && _a !== void 0 ? _a : defaultValue;
    }, [screenType]);
    return {
        screenType: screenType,
        scale: scale,
        selectDeviceType: selectDeviceType,
    };
};
exports.useDimensions = useDimensions;
