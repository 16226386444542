import React, { ReactElement } from 'react'
import { ResourceContentProps } from '../interfaces'
import { ActionContainer, ItemContainer, LocationContainer, NameText, TextContainer } from './style'
import ResourceImage from '../../../atoms/ResourceImage'
import Text from '../../../atoms/Text'
import { TouchableOpacity } from 'react-native'

export interface ExtraDataServiceResource {
  text?: string
  variant: 'selected' | 'unselected'
  action?: React.ReactNode
  onPress: () => void
}

export type ServiceItem = ResourceContentProps<ExtraDataServiceResource>

export const ServiceResource: React.FC<ServiceItem> = ({ value, width }) => {
  const onResourcePress = () => {
    value.extraData?.onPress()
    console.debug('onResourcePress')
  }
  return (
    <TouchableOpacity onPress={onResourcePress}>
      <ItemContainer width={width} aspectRatio={value.aspectRatio} variant={value.extraData?.variant || ''}>
        {value.extraData?.variant == 'selected' ? (
          <React.Fragment>
            <ActionContainer variant={value.extraData?.variant || ''}>{value.extraData?.action}</ActionContainer>
            <TextContainer variant={value.extraData?.variant || ''}>
              <NameText type="caption">{value.title}</NameText>
            </TextContainer>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextContainer variant={value.extraData?.variant || ''}>
              <NameText type="caption" color="primary">
                {value.title}
              </NameText>
              <Text type="caption" color="primary">
                {value.extraData?.text}
              </Text>
            </TextContainer>
            <ActionContainer variant={value.extraData?.variant || ''}>{value.extraData?.action}</ActionContainer>
          </React.Fragment>
        )}
      </ItemContainer>
    </TouchableOpacity>
  )
}
