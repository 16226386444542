import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'
import Text from '../../atoms/Text'

export const HeaderContainer = styled(View)<{ color?: string }>(({ theme, color }) => ({
  flexDirection: 'row',
  backgroundColor: color ?? theme.colors.background0,
  padding: 10,
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

export const BackButton = styled(TouchableOpacity)(({ theme }) => ({
  borderRadius: 8,
  padding: 10,
}))

export const TitleHeader = styled(Text)(({ theme }) => ({
  paddingHorizontal: theme.sizes.md,
  alignSelf: 'center',
}))

export const IconContainer = styled(View)(({ theme }) => ({
  alignSelf: 'center',
}))

export const EmptyView = styled(View)<{width: number}>(({ theme,width }) => ({
  width,
}))
