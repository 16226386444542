var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { addXHeaders, checkUserStatus, signInEmailWithPassword, } from 'apis';
import { changeAppState, loadSelectedPetGuid, loadTokenInformation, loadUserToken, setFavVet, setLoading, setPetLoadingStatus, useAppDispatch, } from 'store';
export var useLogin = function (_a) {
    var onNextPress = _a.onNextPress;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var validationSchema = yup.object().shape({
        email: yup.string().email(t('login.invalid_email')).required(t('login.email_required')),
        password: yup.string().required(t('login.password_required')),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            var res, e_1;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
            return __generator(this, function (_t) {
                switch (_t.label) {
                    case 0:
                        dispatch(setLoading(true));
                        _t.label = 1;
                    case 1:
                        _t.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, signInEmailWithPassword({
                                email: formValues.email,
                                password: formValues.password,
                            })];
                    case 2:
                        res = _t.sent();
                        console.debug('SING IN ACTION', res.data);
                        // * Need to validate send to to otp
                        if ((_a = res.data) === null || _a === void 0 ? void 0 : _a.needValidation) {
                            console.debug('NEED VALIDATION');
                            dispatch(loadUserToken({
                                token: (_b = res.data) === null || _b === void 0 ? void 0 : _b.userToken,
                            }));
                            dispatch(loadTokenInformation({
                                token: (_c = res.data) === null || _c === void 0 ? void 0 : _c.userToken,
                                e360AppGUID: (_e = (_d = res.data) === null || _d === void 0 ? void 0 : _d.e360AppGUID) !== null && _e !== void 0 ? _e : '',
                                e360AppUserGUID: (_g = (_f = res.data) === null || _f === void 0 ? void 0 : _f.e360AppUserGUID) !== null && _g !== void 0 ? _g : '',
                            }));
                            // * NEED TO EXECUTE THE GET USER CHECK STATUS
                            onNextPress === null || onNextPress === void 0 ? void 0 : onNextPress({ formValues: formValues, success: true, needToValidate: true });
                            return [2 /*return*/];
                        }
                        console.debug('NO NEED VALIDATION');
                        dispatch(loadTokenInformation({
                            token: (_h = res.data) === null || _h === void 0 ? void 0 : _h.token,
                            e360AppGUID: (_k = (_j = res.data) === null || _j === void 0 ? void 0 : _j.e360AppGUID) !== null && _k !== void 0 ? _k : '',
                            e360AppUserGUID: (_m = (_l = res.data) === null || _l === void 0 ? void 0 : _l.e360AppUserGUID) !== null && _m !== void 0 ? _m : '',
                        }));
                        // * Add the tokens to the interceptors
                        addXHeaders({
                            e360AppGUID: (_p = (_o = res.data) === null || _o === void 0 ? void 0 : _o.e360AppGUID) !== null && _p !== void 0 ? _p : '',
                            e360AppUserGUID: (_r = (_q = res.data) === null || _q === void 0 ? void 0 : _q.e360AppUserGUID) !== null && _r !== void 0 ? _r : '',
                            token: (_s = res.data) === null || _s === void 0 ? void 0 : _s.token,
                        });
                        // * NEED TO EXECUTE THE GET USER CHECK STATUS
                        onNextPress === null || onNextPress === void 0 ? void 0 : onNextPress({ formValues: formValues, success: true });
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _t.sent();
                        onNextPress === null || onNextPress === void 0 ? void 0 : onNextPress({ formValues: formValues, success: false });
                        return [3 /*break*/, 5];
                    case 4:
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    var checkUserStatusApi = function (_a) {
        var lat = _a.lat, lon = _a.lon;
        return __awaiter(void 0, void 0, void 0, function () {
            var resUserStatus;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        dispatch(setLoading(true));
                        return [4 /*yield*/, checkUserStatus({
                                lat: lat,
                                lon: lon,
                            })];
                    case 1:
                        resUserStatus = _b.sent();
                        dispatch(changeAppState({
                            appNavigationState: 'LOGGED_IN',
                            isSignUp: true,
                            avatar: resUserStatus.data.avatar,
                            counters: resUserStatus.data.counters,
                            guid: resUserStatus.data.guid,
                            petpassRole: resUserStatus.data.petPassRole,
                            role: resUserStatus.data.role,
                            status: resUserStatus.data.status,
                            userName: resUserStatus.data.userName,
                            favVetGUID: resUserStatus.data.petpassUserFavVetGUID,
                            email: resUserStatus.data.email,
                        }));
                        dispatch(setFavVet({ favVetGUID: resUserStatus.data.petpassUserFavVetGUID }));
                        if (resUserStatus.data.pets.length > 0) {
                            console.debug('PET GUID ON USER LOAD', resUserStatus.data.pets[0].guid);
                            dispatch(loadSelectedPetGuid(resUserStatus.data.pets[0].guid));
                            dispatch(setPetLoadingStatus(true));
                        }
                        else {
                            dispatch(loadSelectedPetGuid(''));
                            dispatch(setPetLoadingStatus(true));
                        }
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                }
            });
        });
    };
    return __assign(__assign({}, values), { checkUserStatusApi: checkUserStatusApi });
};
