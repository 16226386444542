var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { addPetPassPet } from 'apis';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { loadQrUri, setLoading, useAppDispatch, useAppSelector } from 'store';
export var useAddPetSuccess = function () {
    var dispatch = useAppDispatch();
    var t = useTranslation().t;
    var _a = useAppSelector(function (state) { return state.petData; }), name = _a.name, momBreed = _a.momBreed, petBreed = _a.petBreed, dadBreed = _a.dadBreed, isSterilized = _a.isSterilized, specie = _a.specie, age = _a.age, photo = _a.photo, birthday = _a.birthday;
    var addPet = function (_a) {
        var tracker = _a.tracker;
        return __awaiter(void 0, void 0, void 0, function () {
            var years, months, res, e_1;
            var _b, _c, _d, _e, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        dispatch(setLoading(true));
                        _g.label = 1;
                    case 1:
                        _g.trys.push([1, 4, 5, 6]);
                        years = (_b = age === null || age === void 0 ? void 0 : age.years) !== null && _b !== void 0 ? _b : 0;
                        months = (_c = age === null || age === void 0 ? void 0 : age.months) !== null && _c !== void 0 ? _c : 1;
                        return [4 /*yield*/, addPetPassPet({
                                name: name,
                                petTypeGUID: specie.guid,
                                petBreedGUID: (_d = petBreed === null || petBreed === void 0 ? void 0 : petBreed.guid) !== null && _d !== void 0 ? _d : '',
                                motherBreedGUID: (_e = momBreed === null || momBreed === void 0 ? void 0 : momBreed.guid) !== null && _e !== void 0 ? _e : '',
                                fatherBreedGUID: (_f = dadBreed === null || dadBreed === void 0 ? void 0 : dadBreed.guid) !== null && _f !== void 0 ? _f : '',
                                ageByMonths: years * 12 + months,
                                birthday: birthday !== null && birthday !== void 0 ? birthday : '',
                                isSterilised: isSterilized,
                                weight: 10, // TODO: get weight from UI
                                character: '', // TODO: get character from UI
                                hairColor: '', // TODO: get hair color from UI
                                hairLength: '', // TODO: get hair length from UI
                                hasInsurance: false, // TODO: get insurance from UI
                                photo: photo,
                                smartPetTag: tracker !== null && tracker !== void 0 ? tracker : '',
                            })];
                    case 2:
                        res = _g.sent();
                        return [4 /*yield*/, dispatch(loadQrUri(res.data.qrCode))];
                    case 3:
                        _g.sent();
                        if (res.success) {
                            Toast.show({
                                text1: res.message || t('common.success'),
                                text2: res.messageDescription || t('common.success'),
                                type: res.messageStatus || 'success',
                            });
                        }
                        else {
                            Toast.show({
                                text1: res.message || t('common.error'),
                                text2: res.messageDescription || t('common.error'),
                                type: res.messageStatus || 'error',
                            });
                        }
                        return [2 /*return*/, res.success];
                    case 4:
                        e_1 = _g.sent();
                        console.debug('ERROR BL ADD PET', e_1);
                        Toast.show({
                            text1: t('common.error'),
                            text2: e_1.message || t('common.error'),
                            type: 'error',
                        });
                        return [2 /*return*/, false];
                    case 5:
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return { addPet: addPet };
};
