import React, { Component } from 'react'
import { Container, LabelText } from './style'
import { SvgProps } from 'react-native-svg'

interface BenfitItemProps {
  icon:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
  label: string
  onPress?: () => void
  mark:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
}

const BenfitItem: React.FC<BenfitItemProps> = ({ icon: Icon, mark: Mark, label, onPress }) => {
  return (
    <Container onPress={() => onPress?.()}>
      <Icon width={50} height={50} />
      <LabelText type="small">{label}</LabelText>
      <Mark width={25} height={25} />
    </Container>
  )
}

export default BenfitItem
