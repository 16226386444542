import React, { useState } from 'react'
import { View } from 'react-native'
import SelectionButton from '../selectionButton'
import { useTheme } from '@emotion/react'
import { FlatList, ListRenderItem } from 'react-native'

interface Props {
  children: string[]
  limit?: number
  itemsSelected?: string[]
  getSelectedItems: (items: string[]) => void
}

const ButtonScroller: React.FC<Props> = ({ children, limit = 3, itemsSelected = [], getSelectedItems }) => {
  const theme = useTheme()

  const [selectedItems, setSelectedItems] = useState<string[]>(itemsSelected)

  const renderItem: ListRenderItem<string> = ({ item }) => {
    const isSelected = selectedItems.includes(item)

    const handlePress = () => {
      let newSelectedItems: string[] = selectedItems
      if (isSelected) {
        newSelectedItems = selectedItems.filter((value) => value !== item)
        setSelectedItems(newSelectedItems)
      } else {
        if (selectedItems.length < limit) {
          newSelectedItems = [...selectedItems, item]
          setSelectedItems(newSelectedItems)
        }
      }
      getSelectedItems(newSelectedItems)
    }

    return (
      <SelectionButton
        title={item}
        onPress={handlePress}
        isSelected={isSelected}
        size="medium"
        textType="cta_large"
        borderRadius={50}
        textColor="foreground2"
        selectionTextColor="background0"
        color="background2"
        selectionColor="primary"
      />
    )
  }

  const renderSeparator = () => <View style={{ width: theme.sizes.md }} />

  return (
    <FlatList
      data={children}
      keyExtractor={(item) => item}
      horizontal
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      renderItem={renderItem}
      ItemSeparatorComponent={renderSeparator}
      style={{ paddingTop: theme.sizes.md, paddingBottom: theme.sizes.xs }}
    />
  )
}

export default ButtonScroller
