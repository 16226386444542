import DefaultResourceContent from './DefaultResourceContent'

export * from './BannerResource'
export * from './RatingResource'
export * from './AddResource'
export * from './ProgressResource'
export * from './ServiceResource'
export * from './SelectedProductResource'

export * from './interfaces'
export { DefaultResourceContent }
