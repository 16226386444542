"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lightColors = void 0;
exports.lightColors = {
    primary: '#163B6C',
    secondary: '#2D81FF',
    tertiary: '#FF2E35',
    quaternary: '#DFD4FB',
    quinary: '#FF2E35',
    senary: '#172E8A',
    error: '#FF4B4B',
    success: '#45ABAD',
    warning: '#E6CF63',
    info: '#007AFF',
    foreground0: 'black',
    foreground1: '#F5F5F7',
    foreground2: '#6E85E3',
    foreground3: '#2D81FF',
    foreground4: '#D1D1D1',
    foreground5: '#858585',
    background0: 'white',
    background1: 'rgb(232,232,232)',
    background2: 'rgb(246,246,246)',
    background3: '#BDBDBD',
    background4: '#333333',
    background5: '#666871',
};
