import React, { useMemo } from 'react'
import { Image } from 'react-native'

export interface ResourceImageProps {
  source: any
  width?: number
  height?: number
  aspectRatio: number
  placeHolder?: boolean
  borderRadius?: number
  style?: any
}

const calculateWidth = (height: number, aspectRatio: number) => {
  return height * aspectRatio
}

const ResourceImage: React.FC<ResourceImageProps> = ({
  source,
  width,
  height,
  aspectRatio,
  style,
  placeHolder,
  borderRadius,
}) => {
  const sideWidth = useMemo(() => {
    if (width) {
      return width
    }
    if (height) {
      return calculateWidth(height, aspectRatio)
    }

    return 0
  }, [width, height, aspectRatio])

  return (
    <Image
      testID="ResourceImage"
      source={source}
      style={[{ width: sideWidth, aspectRatio, borderRadius }, style]}
      resizeMode="contain"
    />
  )
}

export default ResourceImage
