import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'
import { ResourceImage } from 'components'
import webStyled from '@emotion/styled'

export const PetPictureContainer = styled(View)<{ width: number; aspectRatio: number }>(
  ({ theme, width, aspectRatio }) => ({
    alignItems: 'center',
    width: width,
    aspectRatio: aspectRatio,
    borderColor: '#ECECEC',
    borderWidth: 3,
    borderRadius: 100,
    margin: 5,
  }),
)

export const PressableContainer = styled(TouchableOpacity)<{ width: number; aspectRatio: number }>(
  ({ theme, width, aspectRatio }) => ({
    alignItems: 'center',
    width: width,
    aspectRatio: aspectRatio,
    borderColor: '#ECECEC',
    borderWidth: 3,
    borderRadius: 100,
    margin: 5,
  }),
)

export const StateContainer = styled.View<{
  x: number
  y: number
}>(({ theme, x, y }) => ({
  backgroundColor: theme.colors.primary,
  borderRadius: 15,
  position: 'absolute',
  bottom: y,
  left: x,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.sizes.xs,
  padding: theme.sizes.xxs,
  borderWidth: 2,
  borderColor: theme.colors.primary,
}))

export const Picture = styled(ResourceImage)(({ theme }) => ({
  margin: '5%',
  padding: 0,
  flex: 1,
  with: '100%',
  height: '100%',
  borderRadius: 100,
  objectFit: 'cover',
}))

export const WebPicture = webStyled.img(({ theme }) => ({
  padding: 0,
  flex: 1,
  with: '100%',
  height: '100%',
  borderRadius: 100,
  objectFit: 'cover',
}))