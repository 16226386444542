import styled from '@emotion/native'
import { View } from 'react-native'
import Text from '../../../atoms/Text'
import Button from '../../button'
import ResourceImage from '../../../atoms/ResourceImage'

export const ItemContainer = styled(View)<{ width: number; aspectRatio: number }>(({ theme, width, aspectRatio }) => ({
  borderRadius: 20,
  flexDirection: 'row',
  width: width,
  aspectRatio: aspectRatio,
}))

export const DataContainer = styled(View)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'flex-start',
  paddingLeft: theme.sizes.sm,
}))

export const Title = styled(Text)(({ theme }) => ({
  fontWeight: 'bold',
  padding: 0,
  margin: 0,
}))

export const Subtitle = styled(Text)(({ theme }) => ({}))

export const BannerButton = styled(Button)(({ theme }) => ({
  marginVertical: theme.sizes.xs,
  marginTop: theme.sizes.sm,
}))

export const ImageContainer = styled(View)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}))

export const Resource = styled(ResourceImage)(({}) => ({
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  borderRadius: 20,
}))
