import { FC } from "react"
import { BodyContainer, Container, HeaderContainer, InputContainer, StepContainer } from "./styles"
import Button from "../../molecules/button"
import { useTranslation } from "react-i18next"
import { TouchableOpacity } from "react-native"
import { FormikErrors, FormikHandlers } from "formik"
import Text from "../../atoms/Text"
import Input from "../../molecules/input"
import { OtpInputField } from "../../molecules/OtpInputField"

interface UpdateEmailTemplateProps {
  backIcon: React.ReactNode
  handleBack: () => void
  handleEmailSubmit: () => void
  handleOtpSubmit: () => void
  currentStep: number
  emailInputProps: {
    onChange: FormikHandlers['handleChange']
    onBlur: FormikHandlers['handleBlur']
    value: string
    errors: FormikErrors<any>
  }
  otpInputProps: {
    onChange: FormikHandlers['handleChange']
    value: string
    errors: FormikErrors<any>
  }
  isUpdateEmail: boolean
  onResendCode: () => void
  timer: number
}

const UpdateEmailTemplate: FC<UpdateEmailTemplateProps> = ({
  backIcon,
  handleBack,
  handleEmailSubmit,
  handleOtpSubmit,
  currentStep,
  emailInputProps,
  otpInputProps,
  isUpdateEmail,
  timer,
  onResendCode
}) => {

  const {t} = useTranslation()

  return (
    <Container>
      <HeaderContainer>
        <TouchableOpacity
          onPress={() => handleBack()}
        >
          {!!backIcon && backIcon}
        </TouchableOpacity>
      </HeaderContainer>
      <BodyContainer>
        {currentStep === 0 ? (
          <StepContainer>
            <Text type="H5" textAlign="center">
              {isUpdateEmail ? t("updateEmail.updateTitle") : t("updateEmail.title")}
            </Text>
            <InputContainer>
              <Input
                inputProps={{
                  placeholder: t("common.email"),
                  onChangeText: emailInputProps.onChange,
                  onBlur: emailInputProps.onBlur,
                  value: emailInputProps.value
                }}
              />
              {emailInputProps && emailInputProps.errors && (
                <Text type="small" color="error">
                  {emailInputProps.errors.toString()}
                </Text>
              )}
            </InputContainer>
            {timer > 0 && (
              <Text type="caption" textAlign="center">
                {t("updateEmail.timer", {time: timer})}
              </Text>
            )}
          </StepContainer>
        ):(
          <StepContainer>
            <Text type="H5" textAlign="center">
              {t("updateEmail.otpSentTitle")}
            </Text>
            <InputContainer>
              <OtpInputField
                value={otpInputProps.value}
                handleChange={otpInputProps.onChange}
                maxLength={4}
                errors={otpInputProps.errors}
              />
              {otpInputProps.errors && (
                <Text type="small" color="error">
                  {otpInputProps.errors.toString()}
                </Text>
              )}
            </InputContainer>
            <TouchableOpacity
              onPress={onResendCode}
            >
              <Text
                type="H5"
                color="foreground3"
              >
                {t("updateEmail.otpResend")}
              </Text>
            </TouchableOpacity>
            {timer > 0 && (
              <Text type="caption" textAlign="center">
                {t("updateEmail.timer", {time: timer})}
              </Text>
            )}
          </StepContainer>
        )}
      </BodyContainer>
      <Button
        onPress={() => {
          if (currentStep === 0) {
            handleEmailSubmit()
          } else {
            handleOtpSubmit()
          }
        }}
        title={t("common.continue")}
        size="block"
        textType="cta_medium"
        borderRadius={5}
        textColor="background0"
        color="primary"
      />
    </Container>
  )
}

export default UpdateEmailTemplate