import styled from '@emotion/native'
import { Input, ResourceImage, Text } from 'components'
import { View } from 'react-native'

export const Container = styled(View)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.sizes.md,
  gap: theme.sizes.sm,
}))

export const DatacContainer = styled(View)(({ theme }) => ({
  alignItems: 'center',
}))

export const PassContainer = styled(View)(({ theme }) => ({
  alignItems: 'center',
  width: '100%',
  height: 25,
  maxHeight: 25,
  position: 'relative',
  bottom: 20,
}))

export const BenefitsContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '100%',
  gap: theme.sizes.md,
}))

export const QRImage = styled(ResourceImage)(({ theme }) => ({
  padding: 0,
  width: 220,
  height: 220,
}))

export const ActiveContainer = styled.View<{
  active: boolean
}>(({ theme, active }) => ({
  backgroundColor: active ? '#57a75f' : '#c30000',
  width: '100%',
  padding: theme.sizes.xxxs,
  paddingHorizontal: theme.sizes.md,
  borderRadius: theme.sizes.lg,
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ActiveText = styled(Text)(({ theme }) => ({
  color: theme.colors.background0,
  textAlign: 'center',
}))
