import styled from '@emotion/native'
import { Text } from 'components'
import { View } from 'react-native'

export const CamaraContainer = styled.TouchableOpacity(({ theme }) => ({
  gap: theme.sizes.xxxs,
  flexDirection: 'column',
  borderColor: '#D5D8E2',
  borderWidth: 2,
  borderRadius: 10,
  borderStyle: 'dashed',
  alignItems: 'center',
}))

export const CamaraSubtitleText = styled(Text)(() => ({
  textAlign: 'center',
}))

export const TitleModal = styled(Text)(() => ({
  alignSelf: 'flex-start',
}))

export const ItemsContainer = styled(View)(({ theme }) => ({
  paddingTop: theme.sizes.md,
  paddingBottom: theme.sizes.xl,
}))

export const ImageToShow = styled.ImageBackground(() => ({
  width: '100%',
  flexDirection: 'column',
  borderRadius: 10,
  alignItems: 'center',
  overlayColor: 'white',
}))

export const OpacityLayer = styled.View(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.8)', // Ajusta el color y la opacidad aquí
  borderRadius: 10,
}))

export const OverLayer = styled.View<{ hasImage?: boolean }>(({ theme, hasImage }) => ({
  // position: 'absolute',
  width: '100%',
  backgroundColor: hasImage ? '#9c9c9c50' : 'white',
  flexDirection: 'column',
  gap: theme.sizes.xxxs,
  borderRadius: 10,
  paddingVertical: theme.sizes.md,
  justifyContent: 'center',
  alignItems: 'center',
}))
