import styled from '@emotion/native'

export const Container = styled.View(({theme}) => ({
  flex: 1,
  width: '100%',
  padding: theme.sizes.md,
}))

export const HeaderContainer = styled.View(() => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const BodyContainer = styled.View(() => ({
  flex: 1,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StepContainer = styled.View(({theme}) => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.sizes.md
}))

export const InputContainer = styled.View(({theme}) => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.sizes.xxs
}))