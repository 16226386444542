import styled from '@emotion/native'
import { Platform, View, ViewStyle } from 'react-native'

export interface InputProps extends ViewStyle {}

export const Container = styled.View<{ variant: string }>(({ theme, variant }) => ({
  borderColor: theme.colors.background1,
  backgroundColor: variant === 'filled' ? theme.colors.background2 : 'transparent',
  borderRadius: variant === 'filled' ? 8 : 0,
  borderTopWidth: variant === 'filled' ? 1 : 0,
  borderLeftWidth: variant === 'filled' ? 1 : 0,
  borderRightWidth: variant === 'filled' ? 1 : 0,
  borderBottomWidth: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: theme.sizes.sm,
  flexWrap: 'nowrap',
}))

const StyledTextInput = styled.TextInput<InputProps>(({ theme }) => ({
  // width: '100%',
  flex: 1,
  color: 'black',
  fontWeight: Platform.OS === 'ios' ? '400' : undefined,
  lineHeight: theme.fontSizes.paragraph_a.lineHeight !== 0 ? theme.fontSizes.paragraph_a.lineHeight : undefined,
  letterSpacing: theme.fontSizes.paragraph_a.letterSpacing,
  textAlign: 'left',
  alignSelf: 'center',
  paddingHorizontal: theme.sizes.xs,
  paddingVertical: theme.sizes.xs,
}))

export { StyledTextInput }
