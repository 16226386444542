import { useSearch, useSos } from 'business-logic'
import { AppBackground, HeaderWithBack, LocationCard, LocationCardDataProps, Text } from 'components'
import { FormikValues } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useAppSelector } from 'store'
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg'
import { ReactComponent as MapPin } from '../../../../assets/icons/pin_filled.svg'
import { Container, PinContainer, PlaceholderContainer, ScrollViewContainer } from './style'
import { useNavigate } from 'react-router-dom'

export const SosHistory: FC = () => {
  const navigate = useNavigate()
  const [history, setHistory] = useState<LocationCardDataProps[]>([])
  const { name } = useAppSelector((state) => state.selectedPet)
  const { isLoading } = useAppSelector((state) => state.loading)

  const submite = (_formValues: FormikValues) => {
    console.debug('submite')
  }

  const { values, handleChange, handleBlur } = useSearch({ onNextPress: submite })
  const { getHistory } = useSos({})

  const handleCardPress = (sosGuid: string) => {
    navigate('/services/sos/Map', {
      state: {
        sosGuid,
      },
    })
  }

  useEffect(() => {
    const fetchHistory = async () => {
      const { data, success } = await getHistory()
      const h = data?.map((item) => {
        const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${item?.lostLat},${item?.lostLon}&zoom=18&size=500x500&markers=color:red%7Clabel:C%7C${item?.lostLat},${item?.lostLon}&key=AIzaSyADFUW8ESzmVt8pEGi2iZD8KlrNAZd6LYA`
        return {
          name: 'Estado: ' + item?.statusLabel,
          imageUrl,
          guid: item?.guid,
          location: {
            name: item?.lostIn,
            latitude: item?.lostLat,
            longitude: item?.lostLon,
          },
        }
      })
      setHistory(h as LocationCardDataProps[])
    }
    fetchHistory()
  }, [])

  return (
    <AppBackground isSafe>
      <HeaderWithBack title={'SOS'} backPress={() => navigate(-1)} backIcon={<BackIcon />} />
      <Container>
        {history?.length === 0 ? (
          <>
            {!isLoading && (
              <PlaceholderContainer>
                <Text type="H4" style={{ textAlign: 'center' }}>
                  {`${name} no tiene historial de alertas SOS`}
                </Text>
              </PlaceholderContainer>
            )}
          </>
        ) : (
          <ScrollViewContainer>
            {history?.map((item, index) => (
              <LocationCard
                data={item}
                onPress={() => handleCardPress(item.guid)}
                key={index}
                pinIcon={<PinContainer>{/* <MapPin width={15} height={15} /> */}</PinContainer>}
              />
            ))}
          </ScrollViewContainer>
        )}
      </Container>
    </AppBackground>
  )
}
