import styled from '@emotion/native'
import { Colors } from 'themes'

export type ButtonSize = 'small' | 'medium' | 'large' | 'block'

const Container = styled.View(() => ({
  alignItems: 'flex-start',
}))

const ButtonWrapper = styled.TouchableOpacity<{
  color: keyof Colors | undefined
  borderRadius: number | undefined
  size: ButtonSize
  borderColor: keyof Colors | undefined
}>(({ theme, color, borderRadius, size, borderColor }) => {
  const paddingVerticalMapping: Record<ButtonSize, number> = {
    small: theme.sizes.xxs,
    medium: theme.sizes.xs,
    large: theme.sizes.sm,
    block: theme.sizes.xs,
  }
  const paddingHorizontalMapping: Record<ButtonSize, number> = {
    small: theme.sizes.sm,
    medium: theme.sizes.lg,
    large: theme.sizes.xxl,
    block: theme.sizes.xxl,
  }
  return {
    paddingVertical: paddingVerticalMapping[size],
    paddingHorizontal: size === 'block' ? undefined : paddingHorizontalMapping[size],
    width: size === 'block' ? '100%' : undefined,
    maxWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: borderRadius || 0,
    alignSelf: 'center',
    flexGrow: 0,
    borderColor: borderColor ? theme.colors[borderColor] : theme.colors.primary,
    backgroundColor: color ? theme.colors[color] : 'transparent',
  }
})

const ContentContainer = styled.View(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Separator = styled.View<{
  size: number
}>(({ size }) => ({
  width: size,
}))

export { Container, ButtonWrapper, ContentContainer, Separator }
