import React from 'react'
import { Button, Container } from './style'
import Text from '../Text'

export interface SwitchProps {
  option1: string
  option2: string
  isFirst: boolean
  onPress?: () => void
  children?: React.ReactNode
}

const Switch: React.FC<SwitchProps> = ({ option1, option2, isFirst, onPress }) => {
  return (
    <Container activeOpacity={1} onPress={onPress}>
      <Button isActive={isFirst}>
        <Text fontWeight="bold" color={isFirst ? 'primary' : 'background3'} type="caption">
          {option1}
        </Text>
      </Button>
      <Button isActive={!isFirst}>
        <Text fontWeight="bold" color={!isFirst ? 'primary' : 'background3'} type="caption">
          {option2}
        </Text>
      </Button>
    </Container>
  )
}

export default Switch
