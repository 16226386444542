import React, { FC } from 'react'
import { DataContainer, LocationContainer, LocationNameText, MainContainer, NameText } from './style'
import Svg, { Image as ImageSvg } from 'react-native-svg'
import { LocationCardDataProps } from '../LocationCard'

interface MapCalloutCardProps {
  pinIcon: React.ReactNode
  guid: string
  imageUrl?: string
  name: string
  locationName: string
  data: LocationCardDataProps
  onPress?: (item: LocationCardDataProps) => void
}

const MapCalloutCard: FC<MapCalloutCardProps> = ({ pinIcon, guid, imageUrl, name, locationName, data, onPress }) => {
  const handlePress = () => {
    console.debug('MapCalloutCard handlePress')
    onPress && onPress(data)
  }

  return (
    <MainContainer onPress={handlePress} key={guid}>
      {imageUrl && (
        <Svg width={70} height={70 * (3 / 4)}>
          <ImageSvg width={'100%'} height={'100%'} preserveAspectRatio="xMidYMid slice" href={{ uri: imageUrl }} />
        </Svg>
      )}
      <DataContainer>
        <NameText type="caption">{name}</NameText>
        {locationName && (
          <LocationContainer>
            {pinIcon}
            <LocationNameText type="small">{locationName}</LocationNameText>
          </LocationContainer>
        )}
      </DataContainer>
    </MainContainer>
  )
}

export default MapCalloutCard
