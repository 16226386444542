var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { PETPASS_locationType, getFiltersApi, locationDetailsApi, searchByLocation, setLocationAsFavoriteApi, } from 'apis';
import { useFormik } from 'formik';
import { useState } from 'react';
import Toast from 'react-native-toast-message';
import { setLoading, useAppDispatch } from 'store';
import * as yup from 'yup';
export var useSearch = function (_a) {
    var onNextPress = _a.onNextPress;
    var dispatch = useAppDispatch();
    var _b = useState(false), locationsLoading = _b[0], setLocationsLoading = _b[1];
    var validationSchema = yup.object().shape({
        search: yup.string(),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            search: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) {
            onNextPress && onNextPress(formValues);
        },
    }), []);
    var getLocations = function (payload, loader) { return __awaiter(void 0, void 0, void 0, function () {
        var locations, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLocationsLoading(true);
                    loader && dispatch(setLoading(true));
                    return [4 /*yield*/, searchByLocation(payload)];
                case 1:
                    locations = _a.sent();
                    return [2 /*return*/, Promise.resolve(locations.data)];
                case 2:
                    e_1 = _a.sent();
                    return [2 /*return*/, Promise.resolve({ filters: [], locations: [] })];
                case 3:
                    dispatch(setLoading(false));
                    setLocationsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var setLocationAsFavorite = function (guid) { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(setLoading(true));
                    return [4 /*yield*/, setLocationAsFavoriteApi(guid)];
                case 1:
                    result = _a.sent();
                    dispatch(setLoading(false));
                    if (result.success) {
                        Toast.show({
                            text1: result.message || 'Éxito',
                            text2: result.messageDescription || 'Éxito',
                            type: result.messageStatus || 'success',
                        });
                    }
                    else {
                        Toast.show({
                            text1: result.message || 'Error',
                            text2: result.messageDescription || 'Error',
                            type: result.messageStatus || 'error',
                        });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    Toast.show({
                        text1: 'Error',
                        text2: e_2.message || 'Error',
                        type: 'error',
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getLocationDetails = function (_a) {
        var guid = _a.guid;
        return __awaiter(void 0, void 0, void 0, function () {
            var res, e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        dispatch(setLoading(true));
                        return [4 /*yield*/, locationDetailsApi({ guid: guid })];
                    case 1:
                        res = _b.sent();
                        dispatch(setLoading(false));
                        return [2 /*return*/, __assign({}, res.data)];
                    case 2:
                        e_3 = _b.sent();
                        console.debug('Error', e_3);
                        return [2 /*return*/, {
                                guid: guid,
                                galleryHeader: '',
                                name: '',
                                description: '',
                                thumb: '',
                                lat: 0,
                                long: 0,
                                address: '',
                                phone: [],
                                email: '',
                                whatsapp: '',
                                gallery: [],
                                type: PETPASS_locationType.HOTEL,
                                isOpenNow: false,
                                openAt: '',
                                schedule: [],
                                allowAppointment: false,
                                contactName: '',
                                information: [],
                                website: '',
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    var getFilters = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    dispatch(setLoading(true));
                    return [4 /*yield*/, getFiltersApi()];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.filters];
                case 2:
                    e_4 = _a.sent();
                    console.debug('Error', e_4);
                    return [3 /*break*/, 4];
                case 3:
                    dispatch(setLoading(false));
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return __assign(__assign({}, values), { getLocations: getLocations, getLocationDetails: getLocationDetails, setLocationAsFavorite: setLocationAsFavorite, getFilters: getFilters, locationsLoading: locationsLoading });
};
