import styled from '@emotion/native'
import { Input, Text } from 'components'
import { ScrollView, View } from 'react-native'

export const Container = styled(View)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.sizes.md,
  gap: theme.sizes.md,
  paddingBottom: theme.sizes.xl,
  flex: 1,
}))

export const InputSearch = styled(Input)(({}) => ({
  width: '100%',
}))

export const Title = styled(Text)(({}) => ({
  textAlign: 'center',
}))

export const PinContainer = styled(View)(() => ({}))

export const ScrollViewContainer = styled(ScrollView)(({ theme }) => ({
  width: '100%',
}))

export const PlaceholderContainer = styled(View)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
}))
