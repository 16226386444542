import React from 'react'
import { Container, SafeContainer } from './styles'
import { StyleProp, ViewStyle } from 'react-native'

export interface AppBackgroundProps {
  isSafe?: boolean
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
}
const AppBackground: React.FC<AppBackgroundProps> = (props) => {
  if (props.isSafe) {
    return <SafeContainer style={props.style}>{props.children}</SafeContainer>
  }

  return <Container style={props.style}>{props.children}</Container>
}

export default AppBackground
