import styled from '@emotion/native'
import { ResourceImage, Text } from 'components'
import { TouchableOpacity, View } from 'react-native'

export const MainContainer = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxs,
  backgroundColor: theme.colors.background0,
  width: '90%',
  borderRadius: 8,
  borderColor: '#E8E8E8',
  padding: theme.sizes.xxs,
  paddingRight: theme.sizes.xs,
  borderBottomWidth: 1,
  alignItems: 'center',
}))

export const DataContainer = styled(View)(({ theme }) => ({
  justifyContent: 'center',
  gap: theme.sizes.xxs,
  marginRight: theme.sizes.xs,
  flex: 1,
}))

export const NameText = styled(Text)<{ width: number }>(({ theme, width }) => ({
  fontWeight: '800',
  fontSize: 14,
  maxWidth: width,
}))

export const LocationNameText = styled(Text)<{ width: number }>(({ theme, width }) => ({
  fontSize: 10,
  maxWidth: width,
}))

export const LocationContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxxs,
  maxWidth: '100%',
}))

export const StatusContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxxs,
}))

export const InfoTitle = styled(Text)(({ theme }) => ({
  fontWeight: 'bold',
}))
