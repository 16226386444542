import React from 'react'
import { useTranslation } from 'react-i18next'
// import {
//   MainWrapper
// } from './styles'
//import { useRegister } from 'business-logic'
import { useDimensions } from 'themes'
import AuthContainer from '../../components/AuthContainer'

const Register: React.FC = () => {
  const { t } = useTranslation()
  const {screenType} = useDimensions()

  const onBack = () => {}

  const onSuccess = () => {
    //navigation.navigate('BottomTabs', { screen: 'Home' })
  }

  //const { formik } = useRegister({ onSuccess })

  const onTermsAndConditions = () => {
    // navigation.navigate('TermsAndConditions')
  }

  return (
    <AuthContainer>
      {/* <MainWrapper screenType={screenType}>
        <Container scrollEnabled enableOnAndroid resetScrollToCoords={{ x: 0, y: 0 }}>
          <UpperContainer>
            <BigHeader text={t('signup.title')} onBackPress={onBack} />
            <Subtitle type={'caption'} color="foreground1">
              {t('signup.subtitle')}
            </Subtitle>
            <RegisterStep1 formik={formik} />
          </UpperContainer>
          <TermsConditionsContainer>
            <TermsConditionsText onPress={onTermsAndConditions} type="caption">
              {t('signup.acceptTermsLabel')}
            </TermsConditionsText>
          </TermsConditionsContainer>
        </Container>
        <LowerContainer>
          <Button
            title={t('signup.title')}
            size="block"
            textType="cta_large"
            borderRadius={8}
            color="primary"
            onPress={formik.submitForm}
          />
        </LowerContainer>
        <text>Register</text>
      </MainWrapper> */}
      <text>Register</text>
    </AuthContainer>
  )
}

export default Register