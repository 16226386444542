import React from 'react'
import ResourceImage from '../../../atoms/ResourceImage'
import { ResourceContentProps } from '../interfaces'
import { Container } from './style'

const DefaultResourceContent: React.FC<ResourceContentProps> = ({
  value,
  width,
  imageUrlBuilder,
  style,
  placeHolder,
}) => {
  return (
    <Container width={width} aspectRatio={value.aspectRatio}>
      <ResourceImage
        source={{ uri: imageUrlBuilder({ resource: value, width }) }}
        aspectRatio={value.aspectRatio}
        placeHolder={placeHolder}
        width={width}
        style={style}
      />
      {value.extraData && value.extraData.cardOverlay && <value.extraData.cardOverlay {...value} />}
    </Container>
  )
}

export default DefaultResourceContent
