import React, { Children, useMemo } from 'react'
import Modal from 'react-native-modal'
import { ModalContainer, ModalContainerKeyboardAvoid } from './style'
import { KeyboardAvoidingView, Platform, View } from 'react-native'

export interface BottomSheetProps {
  visible: boolean
  onHide: () => void
  children?: React.ReactNode
}

const BottomSheet: React.FC<BottomSheetProps> = ({ visible, onHide, children }) => {
  const Wrapper = useMemo(
    () => (Platform.OS === 'android' ? ModalContainer : ModalContainerKeyboardAvoid),
    [Platform.OS],
  )
  return (
    <Modal
      isVisible={visible}
      style={{ justifyContent: 'flex-end', margin: 0, padding: 0 }}
      backdropOpacity={0.7}
      useNativeDriver
      useNativeDriverForBackdrop
      hideModalContentWhileAnimating
      onBackdropPress={onHide}>
      <Wrapper behavior="padding">{children}</Wrapper>
    </Modal>
  )
}

export default BottomSheet
