import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AppStateListener, listenerMiddleware } from '../listenerMiddleware';
import { appStateSlice } from '../slices/AppState-slice';
import { userSlice } from '../slices/User-slice';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { postSlice } from '../slices/Post-slice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { groupsSlice, missingDataSlice, petAdditionSlice, petDataSlice, selectedPetSlice } from '../slices';
import { loadingSlice } from '../slices/Loading-slice';
import { petDocumentationSlice } from '../slices/PetDocumentation-slice';
var appPersistConfig = {
    key: 'appState',
    storage: AsyncStorage,
};
var userPersistConfig = {
    key: 'user',
    storage: AsyncStorage,
};
var selectedPetPersistConfig = {
    key: 'selectedPet',
    storage: AsyncStorage,
};
AppStateListener();
var rootReducer = combineReducers({
    appState: persistReducer(appPersistConfig, appStateSlice.reducer),
    user: persistReducer(userPersistConfig, userSlice.reducer),
    post: postSlice.reducer,
    groups: groupsSlice.reducer,
    petAddition: petAdditionSlice.reducer,
    petData: petDataSlice.reducer,
    loading: loadingSlice.reducer,
    petDocumentation: petDocumentationSlice.reducer,
    selectedPet: persistReducer(selectedPetPersistConfig, selectedPetSlice.reducer),
    missingData: missingDataSlice.reducer,
});
export var store = configureStore({
    reducer: rootReducer,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).prepend(listenerMiddleware.middleware);
    },
});
export var persistor = persistStore(store);
