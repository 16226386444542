import React, { ReactElement } from 'react'
import { ResourceContentProps } from '../interfaces'
import { AnimatedCircularProgress } from 'react-native-circular-progress'
import { useTheme } from '@emotion/react'
import ResourceImage from '../../../atoms/ResourceImage'
import { AddIcon, LabelContainer, LabelText, PercentageContainer, PercentageText, ResourceContainer } from './style'
import Text from '../../../atoms/Text'
import { Pressable, View } from 'react-native'

export interface OnPressProgressItemInterface {
  guid: string
  resource: ProgressItem
}

export interface ExtraDataProgressResource {
  progress: number
  addIcon?: ReactElement
  label?: string
  guid?: string
  onPress?: (item: OnPressProgressItemInterface) => void
  showProgress?: boolean
}

export type ProgressItem = ResourceContentProps<ExtraDataProgressResource>

export const ProgressResource: React.FC<ProgressItem> = (props) => {
  const { imageUrlBuilder, width, value } = props
  const theme = useTheme()
  console.debug('PROGRESS RESOURCE', value)

  const handlePress = () => {
    if (value.extraData?.guid && value.extraData?.onPress) {
      value.extraData?.onPress({ guid: value.extraData?.guid, resource: props })
    }
  }

  if (value.extraData?.addIcon) {
    return (
      <View>
        <ResourceContainer width={width} aspectRatio={value.aspectRatio}>
          <AddIcon width={width - width * 0.25} aspectRatio={value.aspectRatio} onPress={handlePress}>
            {value.extraData?.addIcon}
          </AddIcon>
          <LabelContainer>
            <LabelText type="caption"></LabelText>
          </LabelContainer>
        </ResourceContainer>
      </View>
    )
  }

  return (
    <View>
      <Pressable onPress={handlePress}>
        <ResourceContainer width={width} aspectRatio={value.aspectRatio}>
          <AnimatedCircularProgress
            size={width * 0.93}
            width={3}
            fill={value.extraData?.progress || 0}
            rotation={180}
            tintColor={value.extraData?.showProgress ? theme.colors.primary : 'white'}
            onAnimationComplete={() => console.debug('onAnimationComplete')}
            childrenContainerStyle={{
              padding: 2,
            }}
            backgroundColor={value.extraData?.showProgress ? '#E5E5E5' : 'white'}>
            {(fill) => (
              <ResourceImage
                source={{ uri: imageUrlBuilder({ resource: value, width }) }}
                aspectRatio={value.aspectRatio}
                placeHolder={false}
                style={{
                  margin: 0,
                  padding: 0,
                  width: '95%',
                  height: '95%',
                  position: 'absolute',
                  borderRadius: 100,
                }}
              />
            )}
          </AnimatedCircularProgress>
          {value.extraData?.progress && value.extraData.showProgress && value.extraData.progress < 100 ? (
            <PercentageContainer>
              <PercentageText type="caption">{`${value.extraData?.progress}%`}</PercentageText>
            </PercentageContainer>
          ) : null}
        </ResourceContainer>
        {value.extraData?.label ? (
          <LabelContainer>
            <LabelText type="caption">{`${value.extraData?.label}`}</LabelText>
          </LabelContainer>
        ) : null}
      </Pressable>
    </View>
  )
}
