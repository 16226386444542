var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Toast from 'react-native-toast-message';
import { useAppDispatch, useAppSelector } from 'store';
import { askPermissionForChangeEmailApi, validatePermissionChangeEmailApi } from 'apis';
export var useEmailConfirmation = function (_a) {
    var onNextPress = _a.onNextPress;
    var t = useTranslation().t;
    var _b = useState(0), timer = _b[0], setTimer = _b[1];
    var currentEmail = useAppSelector(function (state) { return state.user; }).email;
    var _c = useState(''), token = _c[0], setToken = _c[1];
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (currentEmail) {
            handleResendCode();
        }
    }, [currentEmail]);
    var otpValidationSchema = yup.object().shape({
        otp: yup.string().required(t('updateEmail.code_required')).length(4, t('updateEmail.code_length'))
    });
    var startTimer = function () { return __awaiter(void 0, void 0, void 0, function () {
        var interval;
        return __generator(this, function (_a) {
            console.log('Start timer');
            setTimer(60);
            interval = setInterval(function () {
                setTimer(function (prev) { return prev - 1; });
            }, 1000);
            setTimeout(function () {
                clearInterval(interval);
            }, 60000);
            return [2 /*return*/];
        });
    }); };
    var otpValues = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: '',
        },
        validationSchema: otpValidationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        console.log('Submit otp');
                        return [4 /*yield*/, validatePermissionChangeEmailApi({
                                usertoken: token,
                                code: formValues.otp
                            })];
                    case 1:
                        res = _a.sent();
                        console.log('Submit otp2');
                        if (res.data.isSuccess) {
                            Toast.show({
                                type: 'success',
                                text1: 'Success',
                                text2: res.message,
                            });
                            onNextPress(formValues);
                        }
                        else {
                            Toast.show({
                                type: 'error',
                                text1: 'Error',
                                text2: res.message,
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        Toast.show({
                            type: 'error',
                            text1: 'Error',
                            text2: e_1.message,
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    var handleResendCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Resend code');
                    if (timer > 0) {
                        console.log('Timer error');
                        Toast.show({
                            type: 'error',
                            text1: 'Error',
                            text2: t("updateEmail.timerError"),
                        });
                        return [2 /*return*/];
                    }
                    console.log('Resend code2');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    console.log('Resend code3');
                    return [4 /*yield*/, askPermissionForChangeEmailApi({ email: currentEmail })];
                case 2:
                    res = _a.sent();
                    console.log('Resend code4');
                    setToken(res.data.token);
                    startTimer();
                    Toast.show({
                        type: 'success',
                        text1: 'Success',
                        text2: res.message,
                    });
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    Toast.show({
                        type: 'error',
                        text1: 'Error',
                        text2: e_2.message,
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return {
        otpValues: otpValues,
        handleResendCode: handleResendCode,
        timer: timer
    };
};
