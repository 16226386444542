import React from 'react'
import { Container, NavigationMenu } from './style'
import BottomNavigationButton from '../../atoms/bottomNavigationButton'

type Tab = {
  id: string
}

interface BottomTabsItemProps {
  active: string
  data: Tab[]
  onPress?: (tab: Tab) => void
}

const BottomTabsNavigation: React.FC<BottomTabsItemProps> = ({ active, data, onPress }) => {
  const handleChangeTab = (tab: Tab) => {
    onPress?.(tab)
  }

  return (
    <Container>
      <NavigationMenu>
        {data.map((tab, i) => (
          <BottomNavigationButton isActive={tab.id === active} onClick={() => handleChangeTab(tab)} key={i} />
        ))}
      </NavigationMenu>
    </Container>
  )
}

export default BottomTabsNavigation
