import styled from '@emotion/native'
import { View } from 'react-native'
import Text from '../../atoms/Text'

export const Container = styled(View)<{ color: string }>(({ theme, color }) => ({
  width: '100%',
  backgroundColor: color ?? theme.colors.primary,
  borderRadius: 10,
  padding: theme.sizes.md,
}))

export const DataContainer = styled(View)(({ theme }) => ({
  gap: theme.sizes.xxs,
  paddingBottom: theme.sizes.lg,
  borderColor: 'white',
  borderBottomWidth: 1,
  borderStyle: 'dashed',
}))

export const Title = styled(Text)(({ theme }) => ({
  color: 'white',
  fontWeight: '700',
}))

export const Subtitle = styled(Text)(({ theme }) => ({
  color: 'white',
}))

export const PriceContainer = styled(View)(({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 5,
  flexDirection: 'row',
  paddingTop: theme.sizes.sm,
}))

export const PticeNumber = styled(Text)(({ theme }) => ({
  color: 'white',
  fontWeight: '900',
}))

export const PriceText = styled(Text)(({ theme }) => ({
  color: 'white',
}))
