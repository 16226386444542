import React from 'react'
import { FlatList } from 'react-native'
import SelectableItem, { SelectableItemProps } from '../../atoms/SelectableItem'
import { ContentSeparator } from './styles'

export interface SelectableListProps {
  data: SelectableItemProps[]
  selectedItem?: number
  onPress?: (_: SelectableItemProps, __: number | undefined) => void
}
const SelectableList: React.FC<SelectableListProps> = ({ data, onPress, selectedItem }) => {
  const renderItem = ({ item, index }: { item: SelectableItemProps; index: number }) => {
    return <SelectableItem {...item} index={index} onPress={onPress} isSelected={selectedItem === index} />
  }

  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      style={{ flex: 1, width: '100%' }}
      contentContainerStyle={{ flex: 1 }}
      data={data}
      ItemSeparatorComponent={ContentSeparator}
      renderItem={renderItem}
    />
  )
}

export default React.memo(SelectableList)
