import { useAppDispatch, addGender, addSterilized, addBreedType } from 'store';
export var useAddPetBasicInfo = function () {
    var dispatch = useAppDispatch();
    var submite = function (_a) {
        var gender = _a.gender, sterilized = _a.sterilized, breed = _a.breed;
        dispatch(addGender(gender));
        dispatch(addSterilized(sterilized));
        dispatch(addBreedType(breed));
    };
    return {
        submite: submite,
    };
};
