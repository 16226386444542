import React from 'react'
import { ViewStyle } from 'react-native'
import { AddIconTouch, Container, DataContainer, PlaceholderContainer } from './style'
import Text from '../../atoms/Text'

export interface AddPhotoContainerProps {
  title: string
  subtitle: string
  iconPlaceholder: React.ReactNode
  photo?: React.ReactNode
  iconCamera: React.ReactNode
  onAddPhoto: () => void
  style?: ViewStyle
}

const AddPhotoContainer: React.FC<AddPhotoContainerProps> = ({
  title,
  subtitle,
  iconPlaceholder,
  photo,
  iconCamera,
  style,
  onAddPhoto,
}) => {
  return (
    <Container style={style}>
      <DataContainer>
        <Text type="H4">{title}</Text>
        <Text type="caption">{subtitle}</Text>
      </DataContainer>
      {photo ? photo : <PlaceholderContainer>{iconPlaceholder}</PlaceholderContainer>}
      <AddIconTouch onPress={onAddPhoto}>{iconCamera}</AddIconTouch>
    </Container>
  )
}

export default AddPhotoContainer
