import styled from '@emotion/native'
import { View } from 'react-native'
import Text from '../../atoms/Text'

export const WheelSelectorContainer = styled(View)(({ theme }) => ({
  paddingTop: theme.sizes.md,
  paddingBottom: 30,
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
}))

export const Container = styled(View)(({ theme }) => ({
  width: '90%',
  justifyContent: 'center',
  alignItems: 'center',
  borderColor: '#00000020',
  borderTopWidth: 2,
}))

export const Label = styled(Text)(({ theme }) => ({
  textAlign: 'center',
}))

export const ItemContainer = styled(View)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 10,
  paddingTop: 10,
  paddingBottom: 10,
}))
