import styled from '@emotion/native'

export const DateContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.sizes.xs,
}))

export const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  padding: theme.sizes.sm,
  paddingTop: theme.sizes.xxs,
  justifyContent:'space-between',
  flex: 1,
  gap: theme.sizes.md,
}))

export const BodyScroll = styled.ScrollView(({ theme }) => ({
  flex: 1,
  gap: theme.sizes.xs,
}))

export const BodyContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.sizes.md,
  flex: 1
}))

export const InputWrapper = styled.View(({ theme }) => ({
  gap: theme.sizes.md,
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
}))

export const PreviewButtonContainer = styled.View(({ theme }) => ({
  paddingTop: theme.sizes.xs,
  paddingBottom: theme.sizes.xs,
}))

export const CameraPreviewContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
})) 

export const ModalContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: theme.sizes.md,
  borderRadius: 10,
  alignItems: 'center',
  width: '100%',
}))

export const ModalHeader = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.sizes.sm,
  padding: theme.sizes.xxxs,
  gap: theme.sizes.xxs,
  width: '100%',
}))

export const StyledKeyboardAvoidingView = styled.KeyboardAvoidingView(({ theme }) => ({
  flex: 1,
  gap: theme.sizes.md,
}))
