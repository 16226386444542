var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getPromocodeValidation } from 'apis';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { setLoading, useAppDispatch } from 'store';
import * as yup from 'yup';
export var useValidatePromocode = function (_a) {
    var onSuccess = _a.onSuccess;
    var dispatch = useAppDispatch();
    var t = useTranslation().t;
    var validationSchema = yup.object().shape({
        code: yup.string().required(t('showCart.promocodeNotValid')),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            code: '',
            product: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        dispatch(setLoading(true));
                        return [4 /*yield*/, getPromocodeValidation({ promocode: values.code, product: values.product })];
                    case 1:
                        response = _a.sent();
                        if (response.success) {
                            if (response.data.newPrice && response.data.newPrice >= 0) {
                                onSuccess && onSuccess(response.data.newPriceLabel, response.data.code);
                                console.debug('VALIDATE PROMOCODE RES', response);
                                Toast.show({
                                    type: 'success',
                                    text1: response.message,
                                    text2: response.messageDescription,
                                    autoHide: false,
                                });
                            }
                            else {
                                Toast.show({
                                    type: 'error',
                                    text1: response.message,
                                    text2: response.messageDescription,
                                });
                            }
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        Toast.show({
                            type: 'error',
                            text1: t('showCart.promocodeError'),
                            text2: e_1.message,
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    return {
        values: values,
    };
};
