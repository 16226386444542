import styled from '@emotion/native'

interface CircleSelected {
  isSelected?: boolean
}

export const Container = styled.TouchableOpacity(({ theme }) => ({
  width: '100%',
  paddingVertical: theme.sizes.sm,
  justifyContent: 'space-between',
  flexDirection: 'row',
}))

export const Circle = styled.View<CircleSelected>(({ theme, isSelected }) => ({
  width: theme.sizes.sm,
  height: theme.sizes.sm,
  borderWidth: 2,
  borderRadius: theme.sizes.sm,
  borderColor: isSelected ? theme.colors.primary : theme.colors.background3,
}))

export const InnerCircle = styled.View<CircleSelected>(({ theme, isSelected }) => ({
  flex: 1,
  backgroundColor: isSelected ? theme.colors.primary : 'transparent',
  borderWidth: 1,
  borderRadius: 50,
  borderColor: 'white',
}))
