import styled from '@emotion/native'
import { TouchableWithoutFeedback } from 'react-native'
import AppBackground from '../../atoms/AppBackground'
import { Text } from 'components'

export const MainWrapper = styled(AppBackground)(({ theme }) => ({
  paddingBottom: theme.sizes.xs,
  flex: 1,
  paddingHorizontal: theme.sizes.sm,
}))

export const ScrollWrapper = styled.ScrollView(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
}))

export const ImageContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  paddingVertical: theme.sizes.xs,
}))

export const Title = styled(Text)<{ flex?: number }>(({ theme, flex }) => ({
  flex,
  textAlign: 'center',
}))

export const SmallCaption = styled(Text)<{ flex: number }>(({ theme, flex }) => ({
  flex,
  padding: 0,
  margin: 0,
  alignSelf: 'flex-start',
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
}))

export const FormsContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.sm,
  marginVertical: theme.sizes.lg,
  flex: 1,
}))

export const ButtonContainer = styled.View(({ theme }) => ({
  justifyContent: 'center',
  paddingVertical: theme.sizes.md,
  paddingHorizontal: theme.sizes.xs,
  alignItems: 'center',
  flexDirection: 'row',
}))

export const ItemsContainer = styled.View(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  marginVertical: theme.sizes.lg,
  justifyContent: 'space-between',
  padding: theme.sizes.xs,
}))

export const UpperContainer = styled.View(() => ({
  alignContent: 'center',
  justifyContent: 'center',
  flex: 1,
}))

export const SubscribeButton = styled(TouchableWithoutFeedback)(({ theme }) => ({
  width: '100%',
  height: theme.sizes.xl,
  borderRadius: theme.sizes.xl,
  backgroundColor: theme.colors.primary,
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.sizes.xs,
}))
