import styled from '@emotion/native'
import webStyled from '@emotion/styled'

export const InputWrapper = styled.View(({ theme }) => ({
  gap: theme.sizes.md,
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
}))

export const MainContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.md,
  paddingVertical: theme.sizes.md,
  flexDirection: 'column',
  flex: 1,
}))

export const DateContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.sizes.md,
}))

export const PreviewButtonContainer = styled.View(({ theme }) => ({
  paddingTop: theme.sizes.xs,
  paddingBottom: theme.sizes.xs,
}))

export const ModalContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: theme.sizes.md,
  borderRadius: 10,
  alignItems: 'center',
  width: '80%',
}))

export const ModalHeader = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.sizes.sm,
  padding: theme.sizes.xxxs,
  gap: theme.sizes.xxs,
  width: '100%',
}))

export const ModalWrapper = webStyled.div(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  justifyContent: 'center',
  alignItems: 'center',
}))
