var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    name: '',
    contacts: {},
};
export var groupsSlice = createSlice({
    name: 'groups',
    initialState: initialState,
    reducers: {
        loadContacts: function (state, action) {
            state.contacts[action.payload.id] = action.payload;
        },
        removeContact: function (state, action) {
            delete state.contacts[action.payload];
        },
        resetContacts: function (state) {
            Object.assign(state, initialState);
        },
    },
});
export var loadContacts = (_a = groupsSlice.actions, _a.loadContacts), resetContacts = _a.resetContacts, removeContact = _a.removeContact;
