"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sizes = void 0;
var sizes = function (scale, selectDeviceType) {
    return {
        xxxs: selectDeviceType({ Small: scale(4), Medium: scale(4), Large: scale(4), "Extra-Large": scale(4) }, scale(4)),
        xxs: selectDeviceType({ Small: scale(8), Medium: scale(8), Large: scale(8), "Extra-Large": scale(8) }, scale(8)),
        xs: selectDeviceType({ Small: scale(12), Medium: scale(12), Large: scale(12), "Extra-Large": scale(12) }, scale(12)),
        sm: selectDeviceType({ Small: scale(16), Medium: scale(16), Large: scale(16), "Extra-Large": scale(16) }, scale(16)),
        md: selectDeviceType({ Small: scale(20), Medium: scale(20), Large: scale(20), "Extra-Large": scale(20) }, scale(20)),
        lg: selectDeviceType({ Small: scale(24), Medium: scale(24), Large: scale(24), "Extra-Large": scale(24) }, scale(24)),
        xl: selectDeviceType({ Small: scale(28), Medium: scale(28), Large: scale(28), "Extra-Large": scale(28) }, scale(28)),
        xxl: selectDeviceType({ Small: scale(32), Medium: scale(32), Large: scale(32), "Extra-Large": scale(32) }, scale(32)),
        xxxl: selectDeviceType({ Small: scale(36), Medium: scale(36), Large: scale(36), "Extra-Large": scale(36) }, scale(36)),
    };
};
exports.sizes = sizes;
