import styled from '@emotion/native'

export const Container = styled.View(({ theme }) => ({
  width: theme.scale(20),
  height: theme.scale(20),
  backgroundColor: theme.colors.foreground1,
  borderRadius: 5,
  borderWidth: 2,
  borderColor: theme.colors.secondary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
