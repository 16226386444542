var _a;
import { createSlice } from '@reduxjs/toolkit';
export var PetGender;
(function (PetGender) {
    PetGender["FEMALE"] = "FEMALE";
    PetGender["MALE"] = "MALE";
})(PetGender || (PetGender = {}));
var initialState = {
    owner: {
        name: '',
        lastName: '',
        phone: '',
        email: '',
        documentId: '',
        address: '',
        secondLastName: '',
        country: '',
        zipCode: '',
        locality: '',
        province: '',
        addressInfo: '',
        addressNumber: '',
        roadType: '',
    },
    pet: {
        name: '',
        typeGUID: '',
        breedGUID: '',
        motherBreedGUID: '',
        fatherBreedGUID: '',
        microchip: '',
        birthdate: '',
        age: '',
        gender: PetGender.MALE,
    },
};
export var missingDataSlice = createSlice({
    name: 'missingData',
    initialState: initialState,
    reducers: {
        loadOwner: function (state, action) {
            state.owner = action.payload;
        },
        loadPet: function (state, action) {
            state.pet = action.payload;
        },
    },
});
export var loadOwner = (_a = missingDataSlice.actions, _a.loadOwner), loadPet = _a.loadPet;
