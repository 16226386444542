import styled from '@emotion/native'
import { Text } from 'components'
import { heightPercentageToDP as hp } from 'react-native-responsive-screen'
import webStyled from '@emotion/styled'

export const Container = styled.View(({ theme }) => ({
  padding: theme.sizes.sm,
  gap: theme.sizes.xs,
  paddingBottom: theme.sizes.sm,
  flex: 1,
  alignItems: 'center',
}))

export const NoPetContainer = styled.View(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.sizes.xs,
}))

export const CardContainer = styled.View(({ theme }) => ({
  width: '100%',
  borderBottomWidth: 2,
  borderBottomColor: theme.colors.background3,
  padding: theme.sizes.xs,
  gap: theme.sizes.sm,
  flexDirection: 'row',
}))

export const CardInfoContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.sizes.xs,
}))

export const HeaderContainer = styled.View(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const HeaderButtonText = styled(Text)(({ theme }) => ({
  color: theme.colors.primary,
}))

export const NoPermissionContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  gap: theme.sizes.sm,
}))

export const BodyContainer = styled.View(({ theme }) => ({
  flex: 1,
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}))


export const WebImageResource = webStyled.img({
  width: 120,
  height: 120,
  borderRadius: 8,})