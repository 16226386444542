import styled from '@emotion/native'
import { Text } from 'components'
import { ScrollView, View } from 'react-native'

export const Container = styled(View)(({ theme }) => ({
  padding: theme.sizes.sm,
  gap: theme.sizes.sm,
  flex: 1,
}))

export const Title = styled(Text)(({}) => ({
  textAlign: 'center',
}))

export const PlacesItemsContainer = styled(ScrollView)(({ theme }) => ({
  width: '100%',
}))

export const PlaceholderContainer = styled(View)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
}))

export const MapContainer = styled(View)({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: 500,
});