import styled from '@emotion/native'
import webStyled from '@emotion/styled'
import { Text } from 'components'
import { View } from 'react-native'

export const ContainerVaccines = styled.ScrollView(({ theme }) => ({
  flex: 1,
  gap: theme.sizes.sm,
  marginBottom: theme.sizes.md,
}))

export const Container = styled.View(({ theme }) => ({
  flex: 1,
  padding: theme.sizes.xs,
  paddingTop: 0,
  gap: theme.sizes.sm,
}))

export const NameContainer = styled.View(({}) => ({
  alignItems: 'center',
  width: '100%',
}))

export const NameText = styled(Text)(({}) => ({
  margin: 0,
  padding: 0,
}))
export const HeaderContainer = styled.View(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const NoVaccinesTextContainer = styled.View(({ theme }) => ({
  backgroundColor: 'blue',
  width: '100%',
  height: '100%',
}))

export const ButtonsContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}))

export const PictureContainer = styled(View)<{ width: number; aspectRatio: number }>(({ theme, width, aspectRatio }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
    height: width,
    borderColor: '#ECECEC',
    borderWidth: 3,
    borderRadius: 100,
    margin: 5,
}))

export const PetImage = webStyled.img(({ theme }) => ({
    margin: '5%',
    padding: 0,
    with: '100%',
    height: '100%',
    objectFit: 'contain',
    backgroundColor: 'red',
    borderRadius: 100,
}))
