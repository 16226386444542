import React from 'react'
import { Container, Image } from './styles'
export interface MediaDisplayProps {
  source: string
  isFullWidth?: boolean
}

const MediaDisplay: React.FC<MediaDisplayProps> = ({ source, isFullWidth }) => {
  return (
    <Container isFullWidth={isFullWidth}>
      <Image source={{ uri: source }} />
    </Container>
  )
}

export default MediaDisplay
