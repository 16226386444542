import { createSlice } from '@reduxjs/toolkit';
export var loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        isLoading: false,
    },
    reducers: {
        setLoading: function (state, action) {
            state.isLoading = action.payload;
        },
    },
});
export var setLoading = loadingSlice.actions.setLoading;
