import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'

export const HeaderContainer = styled(View)(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.background0,
  padding: theme.sizes.md,
  alignItems: 'center',
  gap: theme.sizes.xs,
}))

export const HeaderFirstLine = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}))

export const BackButton = styled(TouchableOpacity)(() => ({
  borderRadius: 8,
}))

export const HeaderIndicatorContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 8,
  borderRadius: 100,
  backgroundColor: 'rgba(50, 50, 50, 0.1)',
}))

export const HeaderIndicator = styled(View)<{ step: number; totalSteps: number }>(({ theme, step, totalSteps }) => ({
  backgroundColor: theme.colors.secondary,
  height: 8,
  borderRadius: 100,
  position: 'absolute',
  left: 0,
  width: `${(step / totalSteps) * 100}%`,
}))
