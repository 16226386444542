var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    text: '',
    media: [],
    location: {
        name: '',
        latitude: 0,
        longitude: 0,
        latitudeDelta: 0,
        longitudeDelta: 0,
    },
    updateInMap: false,
    categories: [],
    visibility: [],
};
export var postSlice = createSlice({
    name: 'post',
    initialState: initialState,
    reducers: {
        loadText: function (state, action) {
            state.text = action.payload.text;
        },
        loadMedia: function (state, action) {
            state.media = action.payload.media;
        },
        loadLocation: function (state, action) {
            state.location = action.payload.location;
            if (action.payload.updateMap) {
                state.updateInMap = action.payload.updateMap;
            }
        },
        loadCategories: function (state, action) {
            state.categories = action.payload.categories;
        },
        loadVisibility: function (state, action) {
            state.visibility = action.payload.visibility;
        },
        resetPost: function (state) {
            Object.assign(state, initialState);
        },
        mapUpdated: function (state) {
            state.updateInMap = false;
        },
    },
});
export var loadMedia = (_a = postSlice.actions, _a.loadMedia), loadText = _a.loadText, resetPost = _a.resetPost, loadLocation = _a.loadLocation, mapUpdated = _a.mapUpdated, loadCategories = _a.loadCategories, loadVisibility = _a.loadVisibility;
