import { useTheme } from '@emotion/react'
import React, { PropsWithChildren } from 'react'
import { FlatList, ListRenderItem } from 'react-native'
import { IResource } from '../../molecules/Resource'
import { ResourceContentProps } from '../../molecules/ResourceContent'
import Container, { ContainerProps, IContainer } from '../../organisms/Container'
import { Loading, Separator } from './style'

export interface CatalogProps {
  data: IContainer[]
  containerSeparatorSize?: number
  onPressResource?: (resource: IResource) => void
  onRefresh?: () => void
  onEndReached?: () => void
  refreshing?: boolean
  newPageLoading?: boolean
  containerTitleConfig?: ContainerProps['titleConfig']
  imageUrlBuilder: ResourceContentProps['imageUrlBuilder']
}

const Catalog = ({
  data,
  onPressResource,
  onRefresh,
  onEndReached,
  newPageLoading,
  refreshing,
  containerSeparatorSize,
  containerTitleConfig,
  imageUrlBuilder,
}: PropsWithChildren<CatalogProps>): React.ReactElement<CatalogProps> => {
  const theme = useTheme()
  const resourceSeparatorWidth = containerSeparatorSize ?? theme.sizes.xxxs

  const ItemSeparator = () => <Separator size={resourceSeparatorWidth} />

  const renderItem: ListRenderItem<IContainer> = ({ item, index }) => {
    return (
      <Container
        data={item}
        containerIndex={index}
        titleConfig={containerTitleConfig}
        imageUrlBuilder={imageUrlBuilder}
      />
    )
  }
  console.debug('DATA DESDE CATALOG', data)
  const keys = data.map((item) => {
    return item.id
  })
  console.debug('KEYS DESDE CATALOG', keys)
  return (
    <FlatList
      testID="container-catalog"
      data={data}
      keyExtractor={(item) => item.id}
      renderItem={renderItem}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      ItemSeparatorComponent={ItemSeparator}
      onRefresh={onRefresh}
      refreshing={refreshing}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.1}
      ListFooterComponent={() =>
        newPageLoading ? <Loading testID="catalog-new-page-loading" color={theme.colors.primary} size="large" /> : null
      }
    />
  )
}

export default Catalog
