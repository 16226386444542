import styled from '@emotion/native'

export const BodyContainer = styled.View(({ theme }) => ({
  flex: 1,
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.sizes.xs,
}))

export const NoPermissionContainer = styled.View(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  gap: theme.sizes.sm,
}))

export const HeaderContainer = styled.View(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))