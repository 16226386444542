import { addXHeaders, checkUserStatus, getPetDetailApi, getServiceCarrefour } from 'apis'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  changeAppState,
  loadSelectedPet,
  loadSelectedPetGuid,
  loadTokenInformation,
  loadUserToken,
  setPetLoadingStatus,
  useAppDispatch,
  useAppSelector,
} from 'store'

const ServicePetition: React.FC = () => {
  // sample url
  // https://api.petpass.pro/common/service-petition?ownerCardNumber=123&ownerEmail=andres.montoya@quasarcodes.com&petGUID=ad1ec382-5365-446e-120a8-57b471c8681c&serviceName=sos
  /*
    VALORES QUE TENGO YA EN EL URL
    serviceName
    petGUID

    VALORES QUE NECESITO
    token
    appGUID
    appUserGUID

    */
  const urlParams = new URLSearchParams(window.location.search)
  const cardOwnerNumber = urlParams.get('ownerCardNumber')
  const ownerEmail = urlParams.get('ownerEmail')
  const petGUID = urlParams.get('petGUID')
  const service = urlParams.get('serviceName')
  // const petGUID = urlParams.get('petGUID');
  // const token = urlParams.get('token');
  // const service = urlParams.get('serviceName');
  // const appGUID = urlParams.get('appGUID');
  // const appUserGUID = urlParams.get('appUserGUID');

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const appState = useAppSelector((state) => state.appState.appNavigationState)

  const validateAndSetUser = async () => {
    const resUserStatus = await checkUserStatus({
      lat: 0,
      lon: 0,
    })

    dispatch(loadSelectedPetGuid(petGUID || ''))
    dispatch(setPetLoadingStatus(true))

    const res = await getPetDetailApi({ guid: petGUID || '' })
    dispatch(
      loadSelectedPet({
        name: res.data.name,
        guid: res.data.guid,
        ageByMonths: res.data.ageByMonths,
        avatar: res.data.avatar,
        birthday: res.data.birthday,
        detailDataProgress: res.data.detailDataProgress,
        hasInsurance: res.data.hasInsurance,
        hasAllVaccines: res.data.hasAllVaccines,
        hasDocument: res.data.hasDocument,
        isSterilised: res.data.isSterilised,
        microchip: res.data.microchip,
        petBreed: {
          guid: res.data.petBreedGUID ?? '',
          name: res.data.petBreedName,
        },
        fatherBreed: {
          guid: res.data.fatherBreedGUID ?? '',
          name: res.data.fatherBreedName ?? '',
        },
        motherBreed: {
          guid: res.data.motherBreedGUID ?? '',
          name: res.data.motherBreedName ?? '',
        },
        petType: {
          guid: res.data.petTypeGUID,
          name: res.data.petTypeName ?? '',
        },
        qrCode: res.data.qrCode,
        weight: res.data.weight,
        character: res.data.character,
        hairColor: res.data.hairColor,
        hairLength: res.data.hairLength,
        locatorActivationCode: res.data.locatorActivationCode,
        allowPkpass: res.data.allowPkpass,
      }),
    )

    dispatch(
      changeAppState({
        appNavigationState: 'LOGGED_IN_SERVICES',
        isSignUp: true,
        avatar: resUserStatus.data.avatar,
        counters: resUserStatus.data.counters,
        guid: resUserStatus.data.guid,
        petpassRole: resUserStatus.data.petPassRole,
        role: resUserStatus.data.role,
        status: resUserStatus.data.status,
        userName: resUserStatus.data.userName,
        favVetGUID: resUserStatus.data.petpassUserFavVetGUID,
        email: resUserStatus.data.email,
      }),
    )
  }

  useEffect(() => {
    // TODO llamar api para obtene rtoken y lo demas
    const validateToken = async () => {
      console.log('SERVICE CARREFOUR', cardOwnerNumber, ownerEmail, petGUID, service)
      const {
        data: { appGUID, appUserGUID, token },
      } = await getServiceCarrefour({
        ownerCardNumber: cardOwnerNumber || '',
        ownerEmail: ownerEmail || '',
        petGUID: petGUID || '',
        serviceName: service || '',
      })

      if (!!token && !!petGUID && !!service && !!appGUID && !!appUserGUID && appState !== 'LOGGED_IN_SERVICES') {
        dispatch(
          loadUserToken({
            token,
          }),
        )
        dispatch(
          loadTokenInformation({
            token,
            e360AppGUID: appGUID,
            e360AppUserGUID: appUserGUID,
          }),
        )
        await addXHeaders({
          token,
          e360AppGUID: appGUID,
          e360AppUserGUID: appUserGUID,
        })
        validateAndSetUser()
      }
    }
    validateToken()
  }, [])

  useEffect(() => {
    if (appState === 'LOGGED_IN_SERVICES') {
      validateAndSetUser().then(() => {
        navigate(`/services/${service}`) // Removed: ?petGUID=${petGUID}
      })
    }
  }, [appState])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
      }}>
      <h1>Redirecting...</h1>
    </div>
  )
}

export default ServicePetition
