import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../../atoms/Text'

export const ItemContainer = styled(View)<{ width: number; aspectRatio: number }>(({ theme, width, aspectRatio }) => ({
  borderRadius: 20,
  width: width,
  aspectRatio: aspectRatio,
  // height: 180
}))

export const Gradient = styled(LinearGradient)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '100%',
  justifyContent: 'flex-end',
  borderRadius: 10,
}))

export const VetName = styled(Text)(({ theme }) => ({
  color: 'white',
  fontWeight: 'bold',
  padding: theme.sizes.xs,
  paddingBottom: theme.sizes.xxxs,
}))

export const ReviewContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  padding: theme.sizes.xs,
  paddingTop: 0,
}))
