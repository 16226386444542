import React, { useCallback, useEffect, useMemo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AuthGroup from '../../modules/Auth/navigations/AuthGroup'
import CommonGroup from '../../modules/Common/navigations/CommonGroup'
import { AppNavigationState, useAppSelector } from 'store'
import { Loading } from '../../components/AppLoader'
import ServicesGroup from '../../modules/Services/navigations/ServicesGroup'
import { addXHeaders } from 'apis'

interface RootStackScreenProps {
  isLoggedIn: AppNavigationState
}

const RootStackScreen: React.FC<RootStackScreenProps> = ({ isLoggedIn = 'NOT_LOGGED_IN' }) => {
  const screenForAppState = useCallback(
    (isLoggedIn: AppNavigationState) => [
      isLoggedIn === 'NOT_LOGGED_IN' ? [<Route path="/auth/*" element={<AuthGroup />} key="auth"/>] // Not logged in
      : isLoggedIn === 'LOGGED_IN' ? [<Route path="/browse/*" element={<CommonGroup />} key="browse"/>] // Logged in
      : [<Route path="/services/*" element={<ServicesGroup/>} key="services"/>], // Logged in services only
    ],
    [isLoggedIn],
  )

  const initialRoute = useMemo(() => {
    if (isLoggedIn === 'LOGGED_IN') {
      return <Route path="/" element={<Navigate to="/browse/home" />} />
    } else if (isLoggedIn === 'LOGGED_IN_SERVICES') {
      return <Route path="/" element={<Navigate to="/services/" />} />
    } else {
      return <Route path="/" element={<Navigate to="/auth/login" />} />
    }
  }, [isLoggedIn])

  const unkownRoute = useMemo(() => {
    if (isLoggedIn === 'LOGGED_IN') {
      return <Route path="*" element={<Navigate to="/common/not-found" />} />
    } else if (isLoggedIn === 'LOGGED_IN_SERVICES') {
      return <Route path="*" element={<Navigate to="/common/not-found" />} />
    } else {
      return <Route path="*" element={<Navigate to="/auth/login" />} />
    }
  }, [isLoggedIn])

  return (
    <Routes>
      {initialRoute}
      {screenForAppState(isLoggedIn)}
      <Route path='/common/*' element={<CommonGroup />} />
      {/*Not found */}
      {unkownRoute}
    </Routes>
  )
}

const RootStackScreenMemo = React.memo(RootStackScreen, (prevProps, nextProps) => {
  return prevProps.isLoggedIn === nextProps.isLoggedIn
})

const AppNavigator: React.FC = () => {
  const appState = useAppSelector((state) => state.appState.appNavigationState)
  const isLoading = useAppSelector((state) => state.loading.isLoading)
  const token = useAppSelector((state) => state.user.token)
  const appGUID = useAppSelector((state) => state.user.e360AppGUID)
  const appUserGUID = useAppSelector((state) => state.user.e360AppUserGUID)

  if ((appState === 'LOGGED_IN_SERVICES' || appState === 'LOGGED_IN') && token && appGUID && appUserGUID) {
    addXHeaders({
      token,
      e360AppGUID: appGUID,
      e360AppUserGUID: appUserGUID,
    }).then(() => {
      return (
        <Loading loading={isLoading} lottieSource={require('../../assets/lottie/loading.json')}>
          <RootStackScreenMemo isLoggedIn={appState} />
        </Loading>
      )
    })
  }

  return (
    <Loading loading={isLoading} lottieSource={require('../../assets/lottie/loading.json')}>
      <RootStackScreenMemo isLoggedIn={appState} />
    </Loading>
  )
}

export default AppNavigator
