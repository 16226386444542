import styled from '@emotion/native'

export const Container = styled.TouchableOpacity(({ theme }) => ({
  borderWidth: theme.scale(2),
  borderColor: theme.colors.background1,
  backgroundColor: theme.colors.background2,
  width: theme.sizes.md,
  height: theme.sizes.md,
  borderRadius: theme.scale(4),
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
}))
