import React, { ReactElement } from 'react'
import { ResourceContentProps } from '../interfaces'
import {
  AddButton,
  ItemContainer,
  OldPriceText,
  PercentageOffContainer,
  PriceContainer,
  PriceText,
  Resource,
  TextContainer,
  Title,
} from './style'
import { PriceCard } from '../../InsuranceCard'
import { BulletItemProps } from '../../BulletItem'
import { useTheme } from '@emotion/react'
import Text from '../../../atoms/Text'

export interface ExtraDataAddResource {
  addIcon: ReactElement
  card: PriceCard
  bottomCardText?: string
  items?: BulletItemProps[]
  tabTitle: string
}

export type AddItem = ResourceContentProps<ExtraDataAddResource>

export const AddResource: React.FC<AddItem> = ({ value, imageUrlBuilder, onPress, width }) => {
  return (
    <ItemContainer width={width} aspectRatio={value.aspectRatio}>
      <Resource
        source={{ uri: imageUrlBuilder({ resource: value, width }) }}
        aspectRatio={16 / 11}
        placeHolder={false}
        width={width}
      />
      {value?.title.length > 0 && <Title type="caption">{}</Title>}
      <PriceContainer>
        <TextContainer>
          <PriceText
            type="paragraph_a"
            fontSize={19}
            fontWeight="bold"
            fontFamily="bold">{`${value.extraData?.card?.price}`}</PriceText>
          <OldPriceText type="small">{value.extraData?.card?.oldPrice}</OldPriceText>
        </TextContainer>
        {value.extraData?.addIcon}
      </PriceContainer>
    </ItemContainer>
  )
}
