import styled from '@emotion/native'
import webStyled from '@emotion/styled'

export const PkPassPress = styled.TouchableOpacity(() => ({}))

import { Input, ResourceImage, Text } from 'components'
import { TouchableOpacity, View } from 'react-native'

export const Container = styled(View)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.sizes.md,
  gap: theme.sizes.sm,
}))

export const DatacContainer = styled(View)(({ theme }) => ({
  alignItems: 'center',
}))

export const PassContainer = styled(View)(({ theme }) => ({
  alignItems: 'center',
  width: '100%',
  height: 25,
  maxHeight: 25,
  position: 'relative',
  bottom: 20,
}))

export const BenefitsContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: '100%',
  gap: theme.sizes.md,
}))

export const QRImage = styled(ResourceImage)(({  }) => ({
  padding: 0,
  width: 220,
  height: 220,
}))

export const ActiveContainer = styled.View<{
  active: boolean
}>(({ theme, active }) => ({
  backgroundColor: active ? '#57a75f' : '#c30000',
  width: '100%',
  padding: theme.sizes.xxxs,
  paddingHorizontal: theme.sizes.md,
  borderRadius: theme.sizes.lg,
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ActiveText = styled(Text)(({ theme }) => ({
  color: theme.colors.background0,
  textAlign: 'center',
}))

export const BenefitContainer = webStyled.div(({ theme }) => ({
    alignItems: 'center',
    maxWidth: 100,
    rowGap: 5,
    display: 'flex',
    flexDirection: 'column',
  }))

export const LabelText = styled(Text)(({ theme }) => ({
fontWeight: 'bold',
fontFamily: 'bold',
}))

export const PictureContainer = styled(View)<{ width: number; aspectRatio: number }>(({ theme, width, aspectRatio }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
    height: width,
    borderColor: '#ECECEC',
    borderWidth: 3,
    borderRadius: 100,
    margin: 5,
}))

export const PetImage = webStyled.img(({ theme }) => ({
    margin: '5%',
    padding: 0,
    with: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 100,
}))

// MODAL

export const ModalContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.background0,
  padding: theme.sizes.sm,
  borderRadius: 10,
  alignItems: 'center',
  margin: theme.sizes.md,
  gap: theme.sizes.sm,
}))


export const BulletsContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.sizes.sm,
  width: '100%',
}))

export const BulletButton = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: theme.sizes.xs,
}))