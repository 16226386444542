import styled from '@emotion/native'

export interface ContainerProps {
  width: number
  aspectRatio: number
}

export const Container = styled.View<ContainerProps>(({ width, aspectRatio }) => ({
  width,
  aspectRatio,
}))
