"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.darkTheme = void 0;
var Spacing = require("./sizes");
var Typography = require("./typography");
var colors_1 = require("./colors");
var darkTheme = function (scale, selectDeviceType) {
    return {
        name: 'dark',
        colors: colors_1.lightColors,
        sizes: Spacing.sizes(scale, selectDeviceType),
        fontFamilies: Typography.fontFamilies,
        fontSizes: Typography.fontSizes(scale, selectDeviceType),
        scale: scale,
        selectDeviceType: selectDeviceType,
    };
};
exports.darkTheme = darkTheme;
__exportStar(require("./colors"), exports);
__exportStar(require("./sizes"), exports);
__exportStar(require("./typography"), exports);
__exportStar(require("./typography"), exports);
__exportStar(require("../types/typography"), exports);
__exportStar(require("../types/colors"), exports);
__exportStar(require("./hooks"), exports);
__exportStar(require("../types/sizes"), exports);
