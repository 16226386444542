var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
export var useNewPassword = function (_a) {
    var onNextPress = _a.onNextPress;
    var t = useTranslation().t;
    var passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
    // min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
    var validationSchema = yup.object().shape({
        password: yup
            .string()
            .required(t('signup.password_required'))
            .min(8, t('signup.password_too_short'))
            .matches(passwordRules, { message: t('signup.password_is_not_strong') }),
        passwordConfirmation: yup
            .string()
            .required(t('signup.confirmPassword_required'))
            .oneOf([yup.ref('password')], t('signup.password_must_match')),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            passwordConfirmation: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return onNextPress(formValues); },
    }), []);
    return values;
};
