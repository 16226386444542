import React, { FC } from 'react'
import { Container, Subtitle, Title, DataContainer, PriceContainer, PriceText } from './style'

export interface PriceCard {
  title: string
  subtitle: string
  price: string
  color: string
  oldPrice?: string
  percentageOff?: string
  banner?: string
}

export const InsuranceCard: FC<PriceCard> = ({ title, subtitle, price, color }) => {
  return (
    <Container color={color}>
      <DataContainer>
        <Title type="H2">{title}</Title>
        <Subtitle type="paragraph_b">{subtitle}</Subtitle>
      </DataContainer>
      <PriceContainer>
        <PriceText type="H4" fontWeight="semibold" fontFamily="semibold">
          {price}
        </PriceText>
      </PriceContainer>
    </Container>
  )
}
