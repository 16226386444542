import React, { ReactElement, useMemo } from 'react'
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'
import { Colors } from 'themes'
import { Container, DinamycButton, Items, ScrollContainer, StaticButton, Subtitle, Title } from './style'

export type BottomActionContainerProps = {
  children?: ReactElement | ReactElement[]
  bottomAction?: ReactElement
  title?: string
  subtitle?: string
  staticFooter?: boolean
  style?: ViewStyle
  backgrounColor?: keyof Colors
  banner?: ReactElement | ReactElement[]
  titleStyle?: ViewStyle
}

const BottomActionContainer: React.FC<BottomActionContainerProps> = ({
  children,
  bottomAction,
  title,
  subtitle,
  staticFooter = true,
  style,
  backgrounColor = 'background0',
  banner,
  titleStyle,
}) => {
  const Wrapper = useMemo(() => (Platform.OS === 'android' ? View : KeyboardAvoidingView), [Platform.OS])

  return (
    <Container background={backgrounColor}>
      <Wrapper behavior="padding" style={{ flex: 1 }}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <>
            <ScrollContainer contentContainerStyle={!staticFooter ? { minHeight: '100%' } : {}} style={style}>
              <Items>
                {banner && banner}
                {title && (
                  <Title style={titleStyle} type="H2" color="foreground0" fontWeight="700" textAlign="center">
                    {title}
                  </Title>
                )}
                {subtitle && (
                  <Subtitle type="caption" color="foreground0" fontWeight="regular" textAlign="center">
                    {subtitle}
                  </Subtitle>
                )}
                {children}
                {bottomAction && !staticFooter && <DinamycButton>{bottomAction}</DinamycButton>}
              </Items>
            </ScrollContainer>
            {bottomAction && staticFooter && <StaticButton>{bottomAction}</StaticButton>}
          </>
        </TouchableWithoutFeedback>
      </Wrapper>
    </Container>
  )
}

export default BottomActionContainer
