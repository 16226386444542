import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../../atoms/Text'
import ResourceImage from '../../../atoms/ResourceImage'

export const Container = styled(View)<{ width: number; aspectRatio: number }>(({ theme, width, aspectRatio }) => ({
  width: width,
  // height: 180,
  aspectRatio: aspectRatio,
  borderRadius: 10,
  display: 'flex',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  flexDirection: 'row',
}))

export const ImageContainer = styled(View)({
  flex: 0.7,
  height: '100%',
})

export const ActionContainer = styled(View)({
  flex: 0.3,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const Resource = styled(ResourceImage)(({ width }: { width: number }) => ({
  width: width,
}))

export const PlusCircle = styled(View)(({ theme }) => ({
  padding: 10,
  paddingTop: 17,
  paddingBottom: 17,
  borderRadius: 40,
  borderWidth: 2,
  borderColor: theme.colors.primary,
}))
