import React, { ReactElement } from 'react'
import { ResourceContentProps } from '../interfaces'
import { Gradient, ItemContainer, ReviewContainer, VetName } from './style'
import ResourceImage from '../../../atoms/ResourceImage'

export interface ExtraDataRatingResource {
  rating: '1' | '2' | '3' | '4' | '5'
  goodStar: ReactElement
  badStar: ReactElement
}

export type RatingItem = ResourceContentProps<ExtraDataRatingResource>

export const RatingResource: React.FC<RatingItem> = ({ imageUrlBuilder, onPress, value, width }) => {
  return (
    <ItemContainer width={width} aspectRatio={value.aspectRatio}>
      <ResourceImage
        source={{ uri: imageUrlBuilder({ resource: value, width }) }}
        aspectRatio={value.aspectRatio}
        placeHolder={false}
        style={{
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
          objectFit: 'cover',
          borderRadius: 10,
        }}
      />
      <Gradient colors={['#FFFFFF00', '#323232']}>
        <VetName type="caption" fontWeight="900">
          {value.title}
        </VetName>
        <ReviewContainer>
          {value.extraData?.goodStar &&
            value.extraData?.badStar &&
            Array.from({ length: 5 }, (_, index) => {
              if (index < Number(value.extraData?.rating)) {
                return value.extraData?.goodStar
              }
              return value.extraData?.badStar
            })}
        </ReviewContainer>
      </Gradient>
    </ItemContainer>
  )
}
