import React, { Fragment } from 'react'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Colors, TextSizes } from 'themes'
import Text from '../../atoms/Text'
import { ButtonSize, ButtonWrapper, Container, ContentContainer, Separator } from './style'

export interface ButtonProps {
  onPress: () => void
  title: string
  size: ButtonSize
  textType: keyof Pick<TextSizes, 'cta_small' | 'cta_medium' | 'cta_large'>
  textStyle?: StyleProp<TextStyle>
  activeOpacity?: number
  isWarning?: boolean
  color?: keyof Colors
  textColor?: keyof Colors
  borderRadius?: number
  borderColor?: keyof Colors
  backgroundColor?: keyof Colors
  onFocus?: () => void
  style?: StyleProp<ViewStyle>
  icon?: React.ReactNode
  allowIconAndText?: boolean
  contentSeparator?: number
  buttonWrapperStyle?: StyleProp<ViewStyle>
}

const Button: React.FC<ButtonProps> = ({
  title,
  color,
  textColor,
  activeOpacity,
  textStyle,
  textType,
  size,
  borderRadius,
  onPress,
  style,
  borderColor,
  onFocus = () => {},
  icon,
  allowIconAndText,
  buttonWrapperStyle,
  contentSeparator = 10,
}) => (
  <Container style={style}>
    <ButtonWrapper
      color={color}
      borderRadius={borderRadius}
      size={size}
      onPress={onPress}
      onFocus={onFocus}
      activeOpacity={activeOpacity ? activeOpacity : 0.4}
      testID="buttonTest"
      borderColor={borderColor}
      style={buttonWrapperStyle}
      accessibilityLabel="Press me">
      {icon ? (
        <ContentContainer>
          {icon}
          {allowIconAndText && (
            <Fragment>
              <Separator size={contentSeparator} />
              <Text type={textType} color={textColor} style={textStyle} numberOfLines={1}>
                {title}
              </Text>
            </Fragment>
          )}
        </ContentContainer>
      ) : (
        <Text type={textType} color={textColor} style={textStyle} numberOfLines={1}>
          {title}
        </Text>
      )}
    </ButtonWrapper>
  </Container>
)

export default Button
