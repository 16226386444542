import { useTheme } from '@emotion/react'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'

interface BottomNavigationButtonProps {
  isActive: boolean
  onClick: () => void
}

const BottomNavigationButton: React.FC<BottomNavigationButtonProps> = ({ isActive, onClick }) => {
  const theme = useTheme()
  const ICON_DIMENSIONS = theme.scale(25);
  const handlePress = () => {
    onClick()
  }
  return (
    <TouchableOpacity onPress={handlePress}>
      {isActive ? (
        <View
          style={{
            borderRadius: 50,
            width: ICON_DIMENSIONS,
            height: ICON_DIMENSIONS,
            backgroundColor: theme.colors.primary,
          }}
        />
      ) : (
        <View
          style={{
            borderRadius: 50,
            width: ICON_DIMENSIONS,
            height: ICON_DIMENSIONS,
            backgroundColor: theme.colors.background1,
          }}
        />
      )}
    </TouchableOpacity>
  )
}

export default BottomNavigationButton
