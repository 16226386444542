import { Text } from 'components'
import React, { FC } from 'react'
import {
  DataContainer,
  ImageResource,
  InfoTitle,
  LocationContainer,
  MainContainer,
  NameText,
  StatusContainer,
  WebImageResource,
} from './style'

export interface Location {
  name: string
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
  type?: string
  images?: string[]
  description?: string
}

export interface LocationCardDataProps {
  name: string
  guid: string
  location: Location
  imageUrl: string
  description?: string
}

interface LocationCardInfoProps {
  title: string
  description: string
  color?: string
}

interface LocationCardProps {
  data: LocationCardDataProps
  pinIcon: React.ReactNode
  showImage?: boolean
  onPress?: (item: LocationCardDataProps) => void
  info?: LocationCardInfoProps
  isWeb?: boolean
}

export const LocationCard: FC<LocationCardProps> = ({
  data,
  pinIcon,
  showImage = true,
  onPress,
  info,
  isWeb = false,
}) => {
  const handlePress = () => {
    onPress && onPress(data)
  }

  const ImageResourceComponent = isWeb ? (
    <WebImageResource src={data?.imageUrl} />
  ) : (
    <ImageResource source={{ uri: data?.imageUrl }} aspectRatio={4 / 3} />
  )

  return (
    <MainContainer onPress={handlePress} key={data.guid}>
      {showImage && ImageResourceComponent}
      <DataContainer>
        <NameText type="caption">{data.name}</NameText>
        {info && (
          <StatusContainer>
            <InfoTitle type="small">{info?.title}</InfoTitle>
            <Text
              type="small"
              style={{
                color: info.color || '#000',
              }}>
              {info?.description}
            </Text>
          </StatusContainer>
        )}
        {data.description && <Text type="small">{data.description}</Text>}
        {data.location.name && (
          <LocationContainer>
            {pinIcon}
            <Text type="small">{data.location.name}</Text>
          </LocationContainer>
        )}
      </DataContainer>
    </MainContainer>
  )
}
