import React from 'react'
import { Container } from './style'

interface CheckBoxProps {
  isCheck: boolean
  onChange: (value: boolean) => void
  icon: React.ReactNode
}
const CheckBox: React.FC<CheckBoxProps> = ({ isCheck, icon, onChange }) => {
  const [isChecked, setIsChecked] = React.useState(isCheck)
  const onPress = () => {
    setIsChecked(!isChecked)
    onChange(!isChecked)
  }

  return (
    <Container onPress={onPress}>
      {isChecked && React.cloneElement(icon as React.ReactElement, { width: '100%', height: '100%' })}
    </Container>
  )
}

export default CheckBox
