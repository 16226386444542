import React from 'react'
import { Text, Input, Button, CheckBox } from 'components'
import { Keyboard, TouchableWithoutFeedback } from 'react-native'
import {
  Container,
  FormsContainer,
  ButtonContainer,
  InputContainer,
  MainWrapper,
  CheckBoxContainer,
  CheckBoxText,
} from './styles'
import { useTranslation } from 'react-i18next'
import { SvgProps } from 'react-native-svg'
import StepperHeader from '../../atoms/StepperHeader'
import { FormikValues, FormikErrors, FormikHandlers } from 'formik'
import { useTheme } from '@emotion/react'

interface CreateAccountTemplateProps {
  values: FormikValues
  errors: FormikErrors<FormikValues>
  handleChange: FormikHandlers['handleChange']
  handleBlur: FormikHandlers['handleBlur']
  handleSubmit: FormikHandlers['handleSubmit']
  isCheck: boolean
  CheckIcon?: React.FC<SvgProps>
}

const CreateAccountTemplate: React.FC<CreateAccountTemplateProps> = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  isCheck,
  CheckIcon,
}) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <MainWrapper isSafe>
          <Container>
            <StepperHeader titleFlex={1} title={t('create_account.create_account')} />
            <FormsContainer>
              <InputContainer>
                <Input
                  inputProps={{
                    value: values.name,
                    onChangeText: handleChange('name'),
                    onBlur: handleBlur('name'),
                    placeholder: t('create_account.name'),
                  }}
                />
                {errors.name && (
                  <Text type="small" color="error">
                    {errors.name.toString()}
                  </Text>
                )}
              </InputContainer>
              <CheckBoxContainer>
                {CheckIcon && <CheckBox icon={<CheckIcon />} isCheck={isCheck} />}
                <CheckBoxText type="small" color="background5">
                  {t('create_account.newsletter_message')}
                </CheckBoxText>
              </CheckBoxContainer>
            </FormsContainer>
          </Container>
          <ButtonContainer>
            <Button
              title={t('create_account.create_account')}
              size="block"
              textType="cta_large"
              borderRadius={50}
              textColor="background0"
              color="primary"
              onPress={handleSubmit}
            />
          </ButtonContainer>
        </MainWrapper>
      </TouchableWithoutFeedback>
    </React.Fragment>
  )
}

export default CreateAccountTemplate
