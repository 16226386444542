import styled from '@emotion/native'
import { Animated, TouchableOpacity } from 'react-native'
const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

export interface ContainerProps {
  width: number
  borderRadius: number
  resourceSeparatorWidth?: number
}

export const Container = styled(AnimatedTouchable)<ContainerProps>(
  ({ theme, borderRadius, resourceSeparatorWidth, width }) => ({
    overflow: 'hidden',
    borderRadius: borderRadius,
    // marginLeft: resourceSeparatorWidth,
    width: width,
    // elevation: placeHolder ? theme.sizes.xxxs : undefined,
    // shadowColor: placeHolder ? theme.colors.background5 : undefined,
    // shadowOffset: placeHolder ? { width: 0, height: theme.sizes.xxxs } : undefined,
    // shadowOpacity: placeHolder ? 0.4 : undefined,
    // shadowRadius: placeHolder ? theme.sizes.xxs : undefined,
  }),
)
