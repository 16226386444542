var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { checkIfUserExist, doSignup, removeAllHeaders, } from 'apis';
import { loadEmail, loadUserToken, useAppDispatch } from 'store';
export var useCreateAccount = function (_a) {
    var onCreateAccount = _a.onCreateAccount;
    var dispatch = useAppDispatch();
    var t = useTranslation().t;
    var passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
    // min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
    var validationSchema = yup.object().shape({
        name: yup.string().required(t('signup.invalid_name')),
        lastname: yup.string().required(t('signup.invalid_lastname')),
        secondLastname: yup.string().notRequired(),
        phone: yup.number().required(t('signup.invalid_phone')),
        email: yup.string().email(t('signup.invalid_email')).required(t('signup.email_required')),
        dni: yup.string().notRequired(),
        partner: yup.number().notRequired(),
        password: yup
            .string()
            .required(t('signup.password_required'))
            .min(8, t('signup.password_too_short'))
            .max(20, t('signup.password_too_long'))
            .matches(passwordRules, { message: t('signup.password_is_not_strong') }),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            lastname: '',
            secondLastname: '',
            phone: '',
            email: '',
            dni: '',
            password: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            var signupResponse, e_1;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 3, , 4]);
                        removeAllHeaders();
                        dispatch(loadEmail({
                            email: formValues.email,
                        }));
                        // Check if can create account
                        return [4 /*yield*/, checkIfUserExist({ email: formValues.email })
                            // create user
                        ];
                    case 1:
                        // Check if can create account
                        _e.sent();
                        return [4 /*yield*/, doSignup({
                                email: formValues.email,
                                phone: formValues.phone,
                                password: formValues.password,
                                name: formValues.name,
                                surname: formValues.lastname,
                                secondLastname: formValues.secondLastname,
                                dni: formValues.dni,
                            })];
                    case 2:
                        signupResponse = _e.sent();
                        dispatch(loadUserToken({
                            token: (_b = (_a = signupResponse === null || signupResponse === void 0 ? void 0 : signupResponse.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.userToken,
                        }));
                        // * SUCCESS enviar automaticament el codigo con resend
                        return [2 /*return*/, onCreateAccount({
                                formValues: formValues,
                                success: true,
                                message: signupResponse.message,
                                userToken: (_d = (_c = signupResponse === null || signupResponse === void 0 ? void 0 : signupResponse.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.userToken,
                            })
                            // Keychain.setGenericPassword('verifyToken', response.verifyToken)
                        ];
                    case 3:
                        e_1 = _e.sent();
                        // e es TIPO RESPONSE FROM API
                        console.debug('aaa', JSON.stringify(e_1));
                        return [2 /*return*/, onCreateAccount({
                                formValues: formValues,
                                success: false,
                                message: e_1.message,
                            })];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    return values;
};
