import styled from '@emotion/native'

export const InputWrapper = styled.View(({ theme }) => ({
  gap: theme.sizes.md,
  marginVertical: theme.sizes.sm,
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
}))

export const PreviewButtonContainer = styled.View(({ theme }) => ({
  paddingTop: theme.sizes.xs,
  paddingBottom: theme.sizes.xs,
}))

export const ModalContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: theme.sizes.md,
  borderRadius: 10,
  alignItems: 'center',
  width: '100%',
}))

export const ModalHeader = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.sizes.sm,
  padding: theme.sizes.xxxs,
  gap: theme.sizes.xxs,
  width: '100%',
}))

export const HeaderContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.sizes.xxs,
}))

export const TitleContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '85%',
}))

export const ButtonContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.sizes.xxs,
  width: '100%',
  paddingTop: theme.sizes.md,
}))
