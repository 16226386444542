import styled from '@emotion/native'

export const ItemContainer = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.sm,
  alignItems: 'center',
}))

export const InfoContainer = styled.View(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
}))
