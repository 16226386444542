import React, { useEffect } from 'react'
import { Container, FocusView, Image } from './styles'
import { useAppSelector } from 'store'
import { SvgProps } from 'react-native-svg'
import SelectionCheck from '../../atoms/SelectionCheck'
import { useTheme } from '@emotion/react'

interface CameraImageProps {
  media: string
  index: React.Key
  handleSelectImage: (media: string) => void
  selectedImage: string
  Check:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
}

const CameraImage: React.FC<CameraImageProps> = ({ media, index, Check, handleSelectImage, selectedImage }) => {
  const [isSelect, setIsSelect] = React.useState<boolean>(false)
  const theme = useTheme()

  useEffect(() => {
    if (selectedImage === media) {
      setIsSelect(true)
    } else {
      setIsSelect(false)
    }
  }, [selectedImage])

  const onImagePress = () => {
    setIsSelect((value) => !value)
    handleSelectImage?.(media)
  }
  return (
    <Container activeOpacity={1} onPress={onImagePress}>
      <SelectionCheck
        isChecked={isSelect}
        Check={Check}
        style={{
          position: 'absolute',
          top: theme.sizes.xxxs,
          right: theme.sizes.xxxs,
          zIndex: 1,
        }}
      />
      <Image key={index} source={{ uri: media }} />
      {isSelect && <FocusView />}
    </Container>
  )
}

export default CameraImage
