var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as yup from 'yup';
import { useFormik } from 'formik';
import { setLoading, useAppDispatch, useAppSelector, loadPetChip, loadPetPassport } from 'store';
import { getPetChipAPI, getPetDocumentDetailsAPI, updatePetChipPhotoAPI, updatePetDocumentDetailsAPI, updatePetDocumentPhotoAPI, updatePetMicrochipAPI, } from 'apis';
import Toast from 'react-native-toast-message';
var today = new Date();
export var usePetDocumentation = function () {
    var dispatch = useAppDispatch();
    var guid = useAppSelector(function (state) { return state.selectedPet; }).guid;
    var validationSchema = yup.object().shape({
        chipNumber: yup.string().required(('Por favor ingrese el número de chip')).matches(/^[0-9]{15}$/, ('Por favor ingrese un número de chip válido')),
        //solo letras espacios y tilde
        ownerName: yup.string().required(('Por favor ingrese el nombre del tutor legal')).matches(/^[a-zA-ZÀ-ÿ\s]*$/, ('Por favor ingrese un nombre válido')),
        ownerDNI: yup.string().required(('Por favor ingrese el DNI del tutor legal')).matches(/^[0-9]{8}[A-Z]$/, ('Por favor ingrese un DNI válido')),
        passportExpirationDate: yup.date().nullable(),
        passportNumber: yup.string().nullable(),
        passportUrl: yup.string().nullable(),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            chipUrl: '',
            chipNumber: '',
            ownerName: '',
            ownerDNI: '',
            passportExpirationDate: today,
            passportNumber: '',
            passportUrl: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            var errors, res, res, res, res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dispatch(setLoading(true));
                        errors = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 10, 11, 12]);
                        if (!(formValues.passportNumber || formValues.passportExpirationDate !== today)) return [3 /*break*/, 3];
                        return [4 /*yield*/, updatePetDocumentDetailsAPI({
                                guid: guid,
                                payload: {
                                    documentId: formValues.passportNumber,
                                    expirationDate: formValues.passportExpirationDate.toISOString(),
                                },
                            })];
                    case 2:
                        res = _a.sent();
                        if (!res.success) {
                            errors.push(res.message || ('Error'));
                        }
                        _a.label = 3;
                    case 3:
                        if (!(formValues.chipNumber || formValues.ownerName || formValues.ownerDNI)) return [3 /*break*/, 5];
                        return [4 /*yield*/, updatePetMicrochipAPI({
                                guid: guid,
                                payload: {
                                    microchip: formValues.chipNumber,
                                    legalGuardianDocumentId: formValues.ownerDNI,
                                    legalGuardianFullName: formValues.ownerName,
                                },
                            })];
                    case 4:
                        res = _a.sent();
                        if (!res.success) {
                            errors.push(res.message || ('Error'));
                        }
                        _a.label = 5;
                    case 5:
                        if (!formValues.passportUrl) return [3 /*break*/, 7];
                        return [4 /*yield*/, updatePetDocumentPhotoAPI({
                                guid: guid,
                                payload: {
                                    file: formValues.passportUrl,
                                },
                            })];
                    case 6:
                        res = _a.sent();
                        if (!res.success) {
                            errors.push(res.message || ('Error'));
                        }
                        _a.label = 7;
                    case 7:
                        if (!formValues.chipUrl) return [3 /*break*/, 9];
                        console.debug("ChipUrl passed to updatePetChipPhotoAPI", formValues.chipUrl);
                        return [4 /*yield*/, updatePetChipPhotoAPI({
                                guid: guid,
                                payload: {
                                    file: formValues.chipUrl,
                                },
                            })];
                    case 8:
                        res = _a.sent();
                        console.debug("ChipUrl passed to res", res);
                        if (!res.success) {
                            errors.push(res.message || ('Error'));
                        }
                        _a.label = 9;
                    case 9: return [3 /*break*/, 12];
                    case 10:
                        e_1 = _a.sent();
                        console.error('Error updating pet documentation', e_1);
                        Toast.show({
                            text1: ('Error'),
                            text2: e_1.message || ('Error'),
                            type: 'error',
                        });
                        return [3 /*break*/, 12];
                    case 11:
                        if (errors.length) {
                            Toast.show({
                                text1: ('Error'),
                                text2: errors.join('\n'),
                                type: 'error',
                            });
                        }
                        else {
                            Toast.show({
                                text1: ('Éxito'),
                                text2: ('Éxito'),
                                type: 'success',
                            });
                        }
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 12: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    var getPetChipDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_2;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    dispatch(setLoading(true));
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getPetChipAPI({ guid: guid })];
                case 2:
                    res = _d.sent();
                    if (res.success) {
                        dispatch(loadPetChip({
                            legalGuardianDocumentId: res.data.legalGuardianDocumentId,
                            legalGuardianFullName: res.data.legalGuardianFullName,
                            microchip: res.data.microchip,
                            microchipStatus: res.data.microchipStatus,
                            microchipUpdatedAt: res.data.microchipUpdatedAt,
                            microchipValidatedAt: res.data.microchipValidatedAt,
                            url: res.data.url,
                            message: res.data.message,
                            updatedMessage: res.data.updatedMessage,
                        }));
                        values.setFieldValue('chipNumber', (_a = res.data) === null || _a === void 0 ? void 0 : _a.microchip);
                        values.setFieldValue('ownerName', (_b = res.data) === null || _b === void 0 ? void 0 : _b.legalGuardianFullName);
                        values.setFieldValue('ownerDNI', (_c = res.data) === null || _c === void 0 ? void 0 : _c.legalGuardianDocumentId);
                        values.setFieldValue('chipUrl', res.data.url);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _d.sent();
                    console.error(e_2);
                    return [3 /*break*/, 4];
                case 4:
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
    var getPetDocumentDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(setLoading(true));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getPetDocumentDetailsAPI({ guid: guid })];
                case 2:
                    res = _a.sent();
                    if (res.success) {
                        dispatch(loadPetPassport({
                            documentId: res.data.documentId,
                            documentStatus: res.data.documentStatus,
                            documentUpdatedAt: res.data.documentUpdatedAt,
                            documentValidatedAt: res.data.documentValidatedAt,
                            documentExpirationDate: res.data.documentExpirationDate,
                            url: res.data.url,
                            message: res.data.message,
                            updatedMessage: res.data.updatedMessage,
                        }));
                        values.setFieldValue('passportNumber', res.data.documentId);
                        res.data.documentExpirationDate
                            ? values.setFieldValue('passportExpirationDate', new Date(res.data.documentExpirationDate))
                            : null;
                        values.setFieldValue('passportUrl', res.data.url);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    console.error(e_3);
                    return [3 /*break*/, 4];
                case 4:
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
    return __assign(__assign({}, values), { getPetDocumentDetails: getPetDocumentDetails, getPetChipDetails: getPetChipDetails });
};
