var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { setLoading, useAppDispatch } from 'store';
import { changeAllowNotificationsAPI, checkUserStatus, deleteUserAPI, getContactInfoAPI, getCountriesAPI, updateUserAPI, } from 'apis';
import Toast from 'react-native-toast-message';
export var useProfile = function () {
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var validationSchema = yup.object().shape({
        name: yup.string().required(t('editProfile.required_name')),
        lastName: yup.string().required(t('editProfile.required_lastname')),
        phone: yup.number().required(t('editProfile.required_phone')),
        email: yup.string().email(t('editProfile.invalid_email')).required(t('editProfile.required_email')),
        documentId: yup.string().notRequired(),
        secondLastName: yup.string().notRequired(),
        address: yup.string().required(t('editProfile.required_address')),
        shareEmail: yup.boolean().notRequired(),
        sharePhone: yup.boolean().notRequired(),
        shareAddress: yup.boolean().notRequired(),
        country: yup.string().notRequired(),
        zipCode: yup.string().notRequired(),
        locality: yup.string().notRequired(),
        province: yup.string().notRequired(),
        addressInfo: yup.string().notRequired(),
        addressNumber: yup.string().notRequired(),
        roadType: yup.string().notRequired(),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            lastName: '',
            phone: '',
            email: '',
            documentId: '',
            address: '',
            shareEmail: true,
            sharePhone: true,
            shareAddress: true,
            secondLastName: '',
            country: '',
            zipCode: '',
            locality: '',
            province: '',
            addressInfo: '',
            addressNumber: '',
            roadType: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        dispatch(setLoading(true));
                        return [4 /*yield*/, updateUserAPI(formValues)];
                    case 1:
                        res = _a.sent();
                        if (res.success) {
                            Toast.show({
                                text1: res.message || t('common.success'),
                                text2: res.messageDescription || t('common.success'),
                                type: res.messageStatus || 'success',
                            });
                        }
                        else {
                            Toast.show({
                                text1: res.message || t('common.error'),
                                text2: res.messageDescription || t('common.error'),
                                type: res.messageStatus || 'error',
                            });
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.error('Error updating user', e_1);
                        Toast.show({
                            text1: t('common.error'),
                            text2: e_1.message || t('common.error'),
                            type: 'error',
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    var getProfileContactInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(setLoading(true));
                    return [4 /*yield*/, getContactInfoAPI({})];
                case 1:
                    res = _a.sent();
                    if (res.data) {
                        values.setFieldValue('name', res.data.data.name);
                        values.setFieldValue('lastName', res.data.data.lastName);
                        values.setFieldValue('phone', res.data.data.phone);
                        values.setFieldValue('email', res.data.data.email);
                        values.setFieldValue('documentId', res.data.data.documentId);
                        values.setFieldValue('address', res.data.data.address);
                        values.setFieldValue('shareEmail', res.data.data.shareEmail);
                        values.setFieldValue('sharePhone', res.data.data.sharePhone);
                        values.setFieldValue('shareAddress', res.data.data.shareAddress);
                        values.setFieldValue('secondLastName', res.data.data.secondLastName);
                        values.setFieldValue('zipCode', res.data.data.zipCode);
                        values.setFieldValue('locality', res.data.data.locality);
                        values.setFieldValue('province', res.data.data.province);
                        values.setFieldValue('country', res.data.data.country);
                        values.setFieldValue('addressInfo', res.data.data.addressInfo);
                        values.setFieldValue('addressNumber', res.data.data.addressNumber);
                        values.setFieldValue('roadType', res.data.data.roadType);
                    }
                    dispatch(setLoading(false));
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.error('Error getting contact info', e_2);
                    dispatch(setLoading(false));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getNotificationStatus = function (lat, lon) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkUserStatus({ lat: lat, lon: lon })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, {
                            allowEmailNotifications: res.data.allowEmailNotifications,
                            allowPushNotifications: res.data.allowPushNotifications,
                            allowSmsNotifications: res.data.allowSmsNotifications,
                        }];
            }
        });
    }); };
    var changeAllowNotifications = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changeAllowNotificationsAPI(payload)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getCountries = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getCountriesAPI(payload)];
                case 1:
                    res = _a.sent();
                    if (res.success) {
                        return [2 /*return*/, res === null || res === void 0 ? void 0 : res.data];
                    }
                    return [2 /*return*/, []];
                case 2:
                    e_3 = _a.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var deleteUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    dispatch(setLoading(true));
                    return [4 /*yield*/, deleteUserAPI()];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, Promise.resolve(res.message || t('setting.confirmDeleteAcountSecond'))];
                case 2:
                    e_4 = _a.sent();
                    return [2 /*return*/, Promise.reject(e_4.message)];
                case 3:
                    dispatch(setLoading(false));
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return __assign(__assign({}, values), { getProfileContactInfo: getProfileContactInfo, getNotificationStatus: getNotificationStatus, changeAllowNotifications: changeAllowNotifications, getCountries: getCountries, deleteUser: deleteUser });
};
