import React, { Fragment, ReactElement } from 'react'
import { BottomCardText, CardContainer, StaticButton, Container, InsuranceTitle, ItemsContainer } from './style'
import { InsuranceCard, PriceCard } from '../../molecules/InsuranceCard'
import { BulletItem, BulletItemProps } from '../../molecules/BulletItem'
import { useTranslation } from 'react-i18next'
import { ResourceContentProps } from '../../molecules/ResourceContent'
import { Image, View } from 'react-native'
import { SvgUri } from 'react-native-svg'

export interface InsuranceProps {
  title: string
  card: PriceCard
  disclaimer: string
  items: BulletItemProps[]
  bottomAction: ReactElement
  imageUrlBuilder: ResourceContentProps['imageUrlBuilder']
}

export const InsuranceTemplate: React.FC<InsuranceProps> = ({ title, disclaimer, items, card, bottomAction }) => {
  console.debug('InsuranceTemplate', card)
  const { t } = useTranslation()
  return (
    <Fragment>
      <Container
        contentContainerStyle={{
          alignItems: 'center',
        }}>
        <CardContainer>
          {card && card.banner && (
            <Image
              style={{ width: '100%', height: 200 }}
              source={{
                uri: card.banner,
              }}
            />
          )}
          {disclaimer && <BottomCardText type="small">{disclaimer}</BottomCardText>}
        </CardContainer>
        {items && <ItemsContainer>{items?.map((item, index) => <BulletItem key={index} {...item} />)}</ItemsContainer>}
      </Container>
      {bottomAction && <StaticButton>{bottomAction}</StaticButton>}
    </Fragment>
  )
}
