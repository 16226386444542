import React from 'react'
import { ActiveIndicator, Container, ContainerIndicators, ImageContainer, InactiveIndicator } from './styles'

// TODO use ritle and subtitle

export type ItemsStepper = {
  image: React.ReactNode
  title?: string
  subtitle?: string
}

export interface StepperProps {
  active?: number
  items: ItemsStepper[]
  handleChangeStep?: (step: number) => void
}

const Stepper: React.FC<StepperProps> = ({ active, items, handleChangeStep }) => {
  const renderIndicators = (_value: any, index: number) => {
    if (active === index) {
      return <ActiveIndicator key={index} onPress={() => handleChangeStep && handleChangeStep(index)} />
    }

    return <InactiveIndicator key={index} onPress={() => handleChangeStep && handleChangeStep(index)} />
  }

  return (
    <Container>
      {items[active || 0].image && <ImageContainer>{items[active || 0].image}</ImageContainer>}
      <ContainerIndicators>{items.map(renderIndicators)}</ContainerIndicators>
    </Container>
  )
}

export default Stepper
