import { usePetEdit } from 'business-logic'
import { AppBackground, BottomActionContainer, Button, Input, Text } from 'components'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, TouchableOpacity } from 'react-native'
import Modal from 'react-native-modal'
import { loadSelectedPetAvatar, useAppDispatch, useAppSelector } from 'store'
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg'
import { ReactComponent as PawIcon } from '../../../../assets/icons/paw.svg'
import { ReactComponent as XSimpleIcon } from '../../../../assets/icons/x_simple_grey.svg'
import {
  ButtonContainer,
  HeaderContainer,
  InputContainer,
  InputWrapper,
  ModalContainer,
  ModalHeader,
  PreviewButtonContainer,
  TitleContainer,
} from './style'
import { useNavigate } from 'react-router-dom'
import { CamaraCard, ImageTypeSelector } from '../../components/CameraCard'

interface inputInterface {
  type: string
  label: string
  value: string
  handleChange: any
  handleBlur: any
  placeholder: string
  error?: string
}

export const PetEdit: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { avatar, name } = useAppSelector((state) => state.selectedPet)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false)

  const onNextPress = async () => {
    navigate(-1)
  }

  const { values, errors, handleBlur, handleChange, handleSubmit, deletePet, setFieldValue } = usePetEdit({ onNextPress })

  const inputs: inputInterface[] = useMemo(() => {
    return [
      {
        type: 'text',
        label: 'Nombre',
        value: values.name,
        handleChange: handleChange('name'),
        handleBlur: handleBlur('name'),
        placeholder: 'Nombre',
        error: errors.name?.toString(),
      },
      {
        type: 'smartPetTag',
        label: 'Smart Pet Tag',
        value: values.locatorActivationCode,
        handleChange: handleChange('locatorActivationCode'),
        handleBlur: handleBlur('locatorActivationCode'),
        placeholder: 'Smart Pet Tag',
        error: errors.locatorActivationCode?.toString(),
      },
    ]
  }, [values, handleBlur, handleChange, errors])

  const onBackPress = () => {
    navigate(-1)
  }

  const onGalleryPress = (file: File, preview: string, type: ImageTypeSelector) => {
    setFieldValue('newAvatar', file)
    dispatch(loadSelectedPetAvatar(preview))
  }

  const handlePreviewPress = () => {
    setShowModal(!showModal)
  }

  const handleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const handleDelete = () => {
    setShowConfirmationModal(false)
    // deletePet().then(() => {
    //   navigation.navigate('Home', {
    //     screen: 'HomeTab',
    //     params: {
    //       shouldCompleteProfile: false,
    //     },
    //   })
    // })
  }

  return (
    <AppBackground isSafe>
      <HeaderContainer>
        <TouchableOpacity onPress={onBackPress}>
          <BackIcon />
        </TouchableOpacity>
        <TitleContainer>
          <Text type="H4" color="foreground0" fontWeight="700" textAlign="center" style={{ paddingLeft: 30 }}>
            Editar
          </Text>
        </TitleContainer>
        {/* <TouchableOpacity onPress={handleConfirmationModal}>
          <Text type="caption" color="error" fontWeight="700" textAlign="center">
            Eliminar
          </Text>
        </TouchableOpacity> */}
      </HeaderContainer>
      <BottomActionContainer
        bottomAction={
          <Button
            title={'Actualizar'}
            icon={<PawIcon width={24} height={24} />}
            size="block"
            textType="cta_medium"
            borderRadius={5}
            textColor="background0"
            allowIconAndText
            color="primary"
            onPress={handleSubmit}
          />
        }>
        <CamaraCard
          title={'Elige una foto'}
          subtitle={'Sube una foto de tu mascota'}
          onGalleryPress={onGalleryPress}
          url={avatar}
          type={ImageTypeSelector.EDIT_PET}
        />
        {avatar ? (
          <PreviewButtonContainer>
            <Button
              title={'Vista previa'}
              icon={<PawIcon width={24} height={24} />}
              size="block"
              textType="cta_medium"
              borderRadius={5}
              textColor="background0"
              allowIconAndText
              color="primary"
              onPress={handlePreviewPress}
            />
          </PreviewButtonContainer>
        ) : (
          <></>
        )}
        <>
          {inputs.map((input, index) => (
            <InputWrapper key={`${input.label}-${index}`}>
              <Text type="H3" color="foreground0" fontWeight="700" textAlign="center">
                {input.label}
              </Text>
              <InputContainer>
                <Input
                  inputProps={{
                    value: input.value,
                    onChangeText: input.handleChange,
                    onBlur: input.handleBlur,
                    placeholder: input.placeholder,
                  }}
                />
                {input.error && (
                  <Text type="small" color="error">
                    {input.error.toString()}
                  </Text>
                )}
              </InputContainer>
            </InputWrapper>
          ))}
        </>
      </BottomActionContainer>
      <Modal
        isVisible={showModal}
        backdropOpacity={0.7}
        useNativeDriver
        useNativeDriverForBackdrop
        hideModalContentWhileAnimating
        onBackdropPress={handlePreviewPress}>
        <ModalContainer>
          <ModalHeader>
            <Text type="H5">Vista previa</Text>
            <TouchableOpacity onPress={handlePreviewPress}>
              <XSimpleIcon width={30} height={30} />
            </TouchableOpacity>
          </ModalHeader>
          <Image source={{ uri: avatar }} style={{ width: 300, height: 300 }} />
        </ModalContainer>
      </Modal>
      <Modal
        isVisible={showConfirmationModal}
        backdropOpacity={0.7}
        useNativeDriver
        useNativeDriverForBackdrop
        hideModalContentWhileAnimating
        onBackdropPress={() => setShowConfirmationModal(!showConfirmationModal)}>
        <ModalContainer>
          <ModalHeader>
            <Text type="H4" textAlign="center">
              {`¿Estas seguro que quieres borrar a ${name}?`}
            </Text>
          </ModalHeader>
          <Text type="H5" textAlign="center">
            {`Una vez que elimines a ${name} no podrás recuperar la información`}
          </Text>
          <ButtonContainer>
            <Button
              title={'Cancelar'}
              icon={<PawIcon width={24} height={24} />}
              size="small"
              textType="cta_medium"
              borderRadius={5}
              textColor="background0"
              allowIconAndText
              color="primary"
              onPress={handleConfirmationModal}
            />
            <Button
              title={'Eliminar'}
              icon={<PawIcon width={24} height={24} />}
              size="small"
              textType="cta_medium"
              borderRadius={5}
              textColor="background0"
              allowIconAndText
              color="error"
              onPress={handleDelete}
            />
          </ButtonContainer>
        </ModalContainer>
      </Modal>
    </AppBackground>
  )
}
