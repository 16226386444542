import styled from '@emotion/native'
import { SafeAreaViewProps } from 'react-native-safe-area-context'
import { Colors } from 'themes'
import Text from '../../atoms/Text'

interface ContainerProps extends SafeAreaViewProps {
  background?: keyof Colors
}

export const Container = styled.View<ContainerProps>(({ theme, background }) => ({
  flex: 1,
}))

export const Items = styled.View(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
  paddingBottom: theme.sizes.lg,
}))

export const Subtitle = styled(Text)(({ theme }) => ({
  marginBottom: theme.sizes.md,
  flex: 1,
}))

export const Title = styled(Text)(({ theme }) => ({
  marginTop: theme.sizes.lg,
  marginBottom: theme.sizes.sm,
}))

export const StaticButton = styled.View(({ theme }) => ({
  paddingVertical: theme.sizes.sm,
  paddingHorizontal: theme.sizes.sm,
}))

export const DinamycButton = styled.View(({ theme }) => ({
  marginTop: 'auto',
  paddingVertical: theme.sizes.sm,
}))

export const ScrollContainer = styled.ScrollView(({ theme }) => ({
  paddingVertical: theme.sizes.md,
  flex: 1,
}))
