import React from 'react'
import Button, { ButtonProps } from '../button'
import { Colors } from 'themes'

interface Props extends ButtonProps {
  selectionColor?: keyof Colors
  selectionTextColor?: keyof Colors
  isSelected?: boolean
}

const SelectionButton: React.FC<Props> = ({
  title,
  color,
  selectionColor,
  textColor,
  selectionTextColor,
  activeOpacity,
  textStyle,
  textType,
  size,
  borderRadius,
  onPress,
  style,
  borderColor,
  onFocus = () => {},
  isSelected = false,
}) => {
  const handlePress = () => {
    onPress()
  }
  return (
    <Button
      title={title}
      onPress={handlePress}
      size={size}
      textType={textType}
      borderRadius={borderRadius}
      textColor={isSelected ? selectionTextColor : textColor}
      color={isSelected ? selectionColor : color}
      style={style}
      borderColor={borderColor}
      onFocus={onFocus}
      activeOpacity={activeOpacity}
      textStyle={textStyle}
    />
  )
}

export default SelectionButton
