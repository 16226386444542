import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'

export const Container = styled(View)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}))

export const ImageContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginBottom: theme.sizes.md,
}))

export const ContainerIndicators = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.sizes.xxs,
  width: '100%',
}))

export const InactiveIndicator = styled(TouchableOpacity)(({ theme }) => ({
  width: theme.sizes.xs,
  height: theme.sizes.xs,
  borderRadius: 100,
  backgroundColor: 'rgba(50, 50, 50, 0.1)',
}))

export const ActiveIndicator = styled(TouchableOpacity)(({ theme }) => ({
  width: theme.sizes.lg,
  height: theme.sizes.xs,
  borderRadius: 100,
  backgroundColor: theme.colors.primary,
}))
