var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ResendVerificationBycode, addXHeaders, checkUserStatus, onVerificationBycode, axiosCall, } from 'apis';
import { changeAppState, loadTokenInformation, loadUserToken, setLoading, useAppDispatch, useAppSelector } from 'store';
import Toast from 'react-native-toast-message';
export var useOtp = function (_a) {
    var onNextPress = _a.onNextPress;
    var userToken = useAppSelector(function (state) { return state.user.token; });
    var _b = useAppSelector(function (state) { return state.user; }), e360AppGUID = _b.e360AppGUID, token = _b.token;
    var email = useAppSelector(function (state) { return state.user.email; });
    var dispatch = useAppDispatch();
    var t = useTranslation().t;
    var validationSchema = yup.object().shape({
        // TODO: LENGTH OF THE OTP
        otp: yup.string().required(t('otp.otp_required')),
        latitud: yup.number(),
        longitud: yup.number(),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: '',
            otpReady: false,
            maxLength: 4,
            latitud: 0,
            longitud: 0,
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            var responseVerificationCode, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        // * SEND THE CODE
                        dispatch(setLoading(true));
                        return [4 /*yield*/, onVerificationBycode({
                                code: formValues.otp,
                                userToken: userToken !== null && userToken !== void 0 ? userToken : '',
                            })];
                    case 1:
                        responseVerificationCode = _a.sent();
                        dispatch(loadTokenInformation({ token: '', e360AppGUID: '', e360AppUserGUID: '' }));
                        console.debug('SEND VERIFICATION CODE ACTION', responseVerificationCode);
                        // * everything all right then store the data and do the onNextPress
                        dispatch(loadTokenInformation({
                            token: responseVerificationCode.data.token,
                            e360AppGUID: responseVerificationCode.data.e360AppGUID,
                            e360AppUserGUID: responseVerificationCode.data.e360AppUserGUID,
                        }));
                        // * Add the tokens to the interceptors
                        addXHeaders({
                            e360AppGUID: responseVerificationCode.data.e360AppGUID,
                            e360AppUserGUID: responseVerificationCode.data.e360AppUserGUID,
                            token: responseVerificationCode.data.token,
                        }).then(function () {
                            console.debug('CHECK USER STATUS HEADERS (ACTION)', axiosCall.defaults.headers);
                            checkUserStatusApi({ lat: formValues.latitud, lon: formValues.longitud });
                        });
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.debug('ERROR VERIFY CODE', JSON.stringify(e_1));
                        Toast.show({
                            type: 'error',
                            text1: t('otp.error'),
                            text2: t('otp.errorDescription'),
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    var onResend = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ResendVerificationBycode({ email: email })];
                case 1:
                    res = _a.sent();
                    dispatch(loadUserToken({
                        token: res.data.data.userToken,
                    }));
                    console.debug('RESEND', res);
                    return [2 /*return*/, {
                            success: true,
                        }];
                case 2:
                    e_2 = _a.sent();
                    return [2 /*return*/, {
                            success: false,
                        }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var checkUserStatusApi = function (_a) {
        var lat = _a.lat, lon = _a.lon;
        return __awaiter(void 0, void 0, void 0, function () {
            var resUserStatus;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, checkUserStatus({
                            lat: lat,
                            lon: lon,
                        })];
                    case 1:
                        resUserStatus = _b.sent();
                        console.debug('USER STATUS BL', resUserStatus);
                        dispatch(changeAppState({
                            appNavigationState: 'LOGGED_IN',
                            isSignUp: true,
                            avatar: resUserStatus.data.avatar,
                            counters: resUserStatus.data.counters,
                            guid: resUserStatus.data.guid,
                            petpassRole: resUserStatus.data.petPassRole,
                            role: resUserStatus.data.role,
                            status: resUserStatus.data.status,
                            userName: resUserStatus.data.userName,
                            email: resUserStatus.data.email,
                        }));
                        return [2 /*return*/];
                }
            });
        });
    };
    return __assign(__assign({}, values), { onResend: onResend, checkUserStatusApi: checkUserStatusApi });
};
