import React, { useMemo, useState } from 'react'
import { Container, DropDownContainer, InputSearch, TitleModal, TitlePlaceHolder, TitleSelected } from './style'
import Button from '../../molecules/button'
import { useTranslation } from 'react-i18next'
import SelectableList from '../../molecules/SelectableList'
import { SelectableItemProps } from '../../atoms/SelectableItem'
import BottomSheet from '../BottomSheet'
import { Platform, ScrollView, TextInputProps, View } from 'react-native'
import { InputProps } from '../../molecules/input'

export interface DropProps {
  placeholder: string
  icon: React.ReactNode
  data: SelectableItemProps[]
  active: number
  buttonIcon?: React.ReactNode
  allowIconAndText?: boolean
  onPress?: (_: SelectableItemProps, __: number | undefined) => void
  selectedItem?: number
  inputProps?: InputProps
  variant?: 'outlined' | 'underlined'
}

const Drop: React.FC<DropProps> = ({
  placeholder,
  icon,
  data,
  active,
  buttonIcon,
  onPress,
  selectedItem,
  inputProps,
  allowIconAndText = false,
  variant = 'underlined',
}) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const toggleModal = () => {
    setModalVisible(!isModalVisible)
  }
  const label = useMemo(() => {
    return data[active]?.title ?? placeholder
  }, [active, data, placeholder])

  const ActualTitle = useMemo(() => {
    return active === -1 ? TitlePlaceHolder : TitleSelected
  }, [active])

  return (
    <Container
      variant={variant}
      
    >
      <DropDownContainer onPress={toggleModal}>
        <ActualTitle type="caption" fontWeight="700" textAlign="center">
          {label}
        </ActualTitle>
        <View
          style={{
            transform: isModalVisible ? [{ rotate: '90deg' }] : [{ rotate: '0deg' }],
          }}
        >
          {icon}
        </View>
      </DropDownContainer>
      <BottomSheet visible={isModalVisible} onHide={toggleModal}>
        <TitleModal type="H3" fontWeight="700" textAlign="center">
          {placeholder}
        </TitleModal>
        <ScrollView style={{ marginVertical: 20 }}>
          <SelectableList data={data} onPress={onPress} selectedItem={selectedItem} />
        </ScrollView>
        {inputProps && <InputSearch {...inputProps} />}
        <Button
          title={('Continuar')}
          size="block"
          textType="cta_medium"
          borderRadius={5}
          textColor="background0"
          color="primary"
          onPress={toggleModal}
          icon={buttonIcon}
          style={{ marginBottom: Platform.OS === 'ios' ? 20 : 0 }}
          allowIconAndText
        />
      </BottomSheet>
    </Container>
  )
}

export default Drop
