import styled from '@emotion/native'
import Text from '../Text'

export const Container = styled.TouchableOpacity(({ theme }) => ({
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const Badge = styled.View<{ maxReached: boolean }>(({ theme, maxReached }) => ({
  position: 'absolute',
  top: -5,
  left: maxReached ? -5 : 0,
  backgroundColor: theme.colors.tertiary,
  borderRadius: 50,
  paddingLeft: theme.sizes.xxxs,
  paddingRight: theme.sizes.xxxs,
  justifyContent: 'center',
  alignItems: 'center',
}))

export const NotificationText = styled(Text)(({ theme }) => ({
  fontWeight: 'bold',
}))
