import styled from '@emotion/native'
import AppBackground from '../../atoms/AppBackground'
import { Text } from 'components'

export const MainWrapper = styled(AppBackground)(({ theme }) => ({
  paddingBottom: theme.sizes.xs,
  flex: 1,
  paddingHorizontal: theme.sizes.sm,
}))

export const Title = styled(Text)<{ flex?: number }>(({ theme, flex }) => ({
  flex,
  textAlign: 'left',
  margin: 0,
  padding: 0,
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
}))

export const FormsContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.sm,
  marginVertical: theme.sizes.lg,
  flex: 1,
}))

export const ItemsContainer = styled.View(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  marginVertical: theme.sizes.lg,
  justifyContent: 'space-between',
  padding: theme.sizes.xs,
}))

export const UpperContainer = styled.View(() => ({
  alignContent: 'center',
  justifyContent: 'center',
  flex: 1,
}))
