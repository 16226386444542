import React from 'react'
import { Container } from './styles'

interface BottomTabsItemProps {
  Component: React.FC
}

const BottomTabsItem: React.FC<BottomTabsItemProps> = ({ Component }) => {
  return (
    <Container>
      <Component />
    </Container>
  )
}

export default BottomTabsItem
