var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { deletePetAPI, updatePetInfoAPI } from 'apis';
import { useFormik } from 'formik';
import { deleteSelectedPet, loadSelectedPetAvatar, loadSelectedPetGuid, loadSelectedPetName, setLoading, useAppDispatch, useAppSelector, } from 'store';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';
export var usePetEdit = function (_a) {
    var onNextPress = _a.onNextPress;
    var dispatch = useAppDispatch();
    var _b = useAppSelector(function (state) { return state.selectedPet; }), name = _b.name, locatorActivationCode = _b.locatorActivationCode, selectedPet = __rest(_b, ["name", "locatorActivationCode"]);
    var validationSchema = yup.object().shape({
        name: yup.string().required('Por favor ingrese el nombre de su mascota'),
        locatorActivationCode: yup.string().notRequired(),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name,
            locatorActivationCode: locatorActivationCode,
            newAvatar: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            var res, e_1;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        dispatch(setLoading(true));
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, 5, 6]);
                        console.debug('selectedPet', selectedPet);
                        return [4 /*yield*/, updatePetInfoAPI({
                                character: (_a = selectedPet.character) !== null && _a !== void 0 ? _a : '',
                                hairColor: (_b = selectedPet.hairColor) !== null && _b !== void 0 ? _b : '',
                                hairLength: (_c = selectedPet.hairLength) !== null && _c !== void 0 ? _c : '',
                                hasInsurance: selectedPet.hasInsurance,
                                isSterilised: selectedPet.isSterilised,
                                motherBreedGUID: selectedPet.motherBreed.guid,
                                fatherBreedGUID: selectedPet.fatherBreed.guid,
                                gender: '',
                                microchip: selectedPet.microchip,
                                guid: selectedPet.guid,
                                petTypeGUID: selectedPet.petType.guid,
                                name: formValues.name,
                                weight: selectedPet.weight,
                                birthday: selectedPet.birthday,
                                ageByMonths: selectedPet.ageByMonths,
                                locatorActivationCode: (_d = formValues.locatorActivationCode) !== null && _d !== void 0 ? _d : '',
                                petBreedGUID: selectedPet.petBreed.guid,
                                file: formValues.newAvatar ? formValues.newAvatar : '',
                            })];
                    case 2:
                        res = _e.sent();
                        if (res.success) {
                            Toast.show({
                                text1: res.message || 'Éxito',
                                text2: res.messageDescription || 'Éxito',
                                type: res.messageStatus || 'success',
                            });
                        }
                        else {
                            Toast.show({
                                text1: res.message || 'Error',
                                text2: res.messageDescription || 'Error',
                                type: res.messageStatus || 'error',
                            });
                        }
                        return [4 /*yield*/, (onNextPress === null || onNextPress === void 0 ? void 0 : onNextPress())];
                    case 3:
                        _e.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        e_1 = _e.sent();
                        console.error('ERROR UPDATING PET INFO', e_1);
                        Toast.show({
                            text1: 'Error',
                            text2: e_1.message || 'Error',
                            type: 'error',
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    var deletePet = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(setLoading(true));
            deletePetAPI({
                guid: selectedPet.guid,
                payload: {},
            })
                .then(function (res) {
                if (res.success) {
                    Toast.show({
                        text1: res.message || 'Éxito',
                        text2: res.messageDescription || 'Éxito',
                        type: res.messageStatus || 'success',
                    });
                    var pets = res.data.otherPets;
                    dispatch(deleteSelectedPet());
                    dispatch(loadSelectedPetName(''));
                    dispatch(loadSelectedPetAvatar(''));
                    dispatch(loadSelectedPetGuid(''));
                    console.debug('REMAINING PETS', pets);
                    if (pets.length > 0) {
                        dispatch(loadSelectedPetGuid(pets[0].guid));
                    }
                    Promise.resolve();
                }
                else {
                    Toast.show({
                        text1: res.message || 'Error',
                        text2: res.messageDescription || 'Error',
                        type: res.messageStatus || 'error',
                    });
                    Promise.reject(res.message || 'Error');
                }
            })
                .catch(function (e) {
                console.error('ERROR DELETING PET', e);
                Toast.show({
                    text1: 'Error',
                    text2: e.message || 'Error',
                    type: 'error',
                });
                Promise.reject(e.message || 'Error');
            })
                .finally(function () {
                dispatch(setLoading(false));
            });
            return [2 /*return*/];
        });
    }); };
    return __assign(__assign({}, values), { deletePet: deletePet });
};
