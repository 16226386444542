import React from 'react'
import CameraImage from '../../molecules/CameraImage'
import { MessageContainer } from './styles'
import { Text } from 'components'
import { useTranslation } from 'react-i18next'
import { SvgProps } from 'react-native-svg'
import { FlatList } from 'react-native'

interface MediaSelectionProps {
  hasPermission: boolean
  loadMore?: () => void
  handleSelectImage: (media: string) => void
  media: string[]
  selectedImage: string
  Check:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
}

const MediaSelection: React.FC<MediaSelectionProps> = ({
  hasPermission,
  loadMore,
  media,
  Check,
  handleSelectImage,
  selectedImage,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {hasPermission ? (
        <>
          {media.length > 0 ? (
            <FlatList
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              data={media}
              renderItem={({ item: photo, index }) => (
                <CameraImage
                  media={photo}
                  index={index}
                  Check={Check}
                  handleSelectImage={handleSelectImage}
                  selectedImage={selectedImage}
                />
              )}
              numColumns={2}
            />
          ) : (
            <MessageContainer>
              <Text type="caption">{t('selectMedia.noMedia')}</Text>
            </MessageContainer>
          )}
        </>
      ) : (
        <MessageContainer>
          <Text type="caption">{t('selectMedia.noPermission')}</Text>
        </MessageContainer>
      )}
    </>
  )
}

export default MediaSelection
