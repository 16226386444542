var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { axiosCall } from '..';
import FormData from 'form-data';
import { Platform } from 'react-native';
export var addPetPassPet = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var form, res, e_1;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _g.trys.push([0, 2, , 3]);
                form = new FormData();
                form.append('name', payload.name);
                form.append('petTypeGUID', payload.petTypeGUID);
                form.append('petBreedGUID', (_a = payload.petBreedGUID) !== null && _a !== void 0 ? _a : '');
                form.append('motherBreedGUID', (_b = payload.motherBreedGUID) !== null && _b !== void 0 ? _b : '');
                form.append('fatherBreedGUID', (_c = payload.fatherBreedGUID) !== null && _c !== void 0 ? _c : '');
                form.append('weight', payload.weight.toString());
                form.append('birthday', (_d = payload.birthday) !== null && _d !== void 0 ? _d : '');
                form.append('ageByMonths', (_f = (_e = payload.ageByMonths) === null || _e === void 0 ? void 0 : _e.toString()) !== null && _f !== void 0 ? _f : '');
                form.append('character', payload.character);
                form.append('hairColor', payload.hairColor);
                form.append('hairLength', payload.hairLength);
                form.append('hasInsurance', payload.hasInsurance.toString());
                form.append('isSterilised', payload.isSterilised.toString());
                form.append('smartPetTag', payload.smartPetTag);
                form.append('file', {
                    uri: Platform.OS === 'android' ? payload.photo : payload.photo.replace('file://', ''),
                    type: 'image/png',
                    name: payload.photo.split('/').pop(),
                });
                return [4 /*yield*/, axiosCall.post('/petpass/onboarding/AddPetPassPet', form, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })];
            case 1:
                res = _g.sent();
                console.debug('DATA ADD PET', res.data);
                if (res.data.isSuccess) {
                    return [2 /*return*/, {
                            success: true,
                            data: res.data.data.petpassPet,
                            message: res.data.message,
                            messageDescription: res.data.messageDescription,
                            messageStatus: res.data.messageStatus,
                        }];
                }
                return [2 /*return*/, Promise.reject({
                        success: false,
                        message: res.data.message,
                        messageDescription: res.data.messageDescription,
                        messageStatus: res.data.messageStatus,
                    })];
            case 2:
                e_1 = _g.sent();
                console.debug('ERROR ADD PET PASS PET', e_1);
                return [2 /*return*/, Promise.reject({
                        success: false,
                        message: e_1.message,
                    })];
            case 3: return [2 /*return*/];
        }
    });
}); };
