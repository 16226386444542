import React from 'react'
import { HeaderWithBackProps } from '../HeaderWithBack'
import { BackButton, HeaderContainer, HeaderFirstLine, HeaderIndicator, HeaderIndicatorContainer } from './style'
import Text from '../../atoms/Text'

export interface HeaderWithStepper extends HeaderWithBackProps {
  step: number
  totalSteps: number
}

const HeaderWithStepper: React.FC<HeaderWithStepper> = ({ backPress, backIcon, step, totalSteps }) => (
  <HeaderContainer>
    <HeaderFirstLine>
      <BackButton onPress={backPress}>{backIcon}</BackButton>
      <Text type="H5">{`${step}/${totalSteps}`}</Text>
    </HeaderFirstLine>
    <HeaderIndicatorContainer>
      <HeaderIndicator step={step} totalSteps={totalSteps} />
    </HeaderIndicatorContainer>
  </HeaderContainer>
)

export default HeaderWithStepper
