import { InsurangeProduct } from "apis";
import { usePetAddinsurance } from "business-logic";
import { AppBackground, BottomActionContainer, Button, HeaderWithBack, Text } from "components";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "store";
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg'
import { ReactComponent as PawIcon } from '../../../../assets/icons/paw.svg'
import { Banner, MainContainer, PolicyInfoContainer, SadPetImage } from "./styles";

interface ActualPolicyInfo {
  title: string
  subtitle: string
  price?: string
  color?: string
  policiInfo: { key: string; value: string }[]
  image: string
  isExternalInsurance: boolean
  products: InsurangeProduct[]
}

interface NoInsuranceInfoProps {
  title: string
  description: string
  image: string
}

export const Insurance: React.FC = () => {
  const { getInsurance } = usePetAddinsurance()
  const [hasInsurance, setHasInsurance] = React.useState<boolean | undefined>(undefined)
  const [insurance, setInsurance] = React.useState<ActualPolicyInfo | undefined>(undefined)
  const [noInsuranceInfo, setNoInsuranceInfo] = React.useState<NoInsuranceInfoProps | undefined>(undefined)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const getInsuranceDetails = async () => {
      dispatch(setLoading(true))
      const insurance = await getInsurance()
      setHasInsurance(insurance?.hasInsurance)
      if (!insurance) {
        dispatch(setLoading(false))
        return
      }
      insurance.hasInsurance ? 
        setInsurance({
          color: insurance.color,
          price: insurance.price,
          subtitle: insurance?.description ?? '',
          policiInfo: insurance.items.map((item) => ({
            key: item.title,
            value: item.content,
          })),
          title: insurance?.title ?? '',
          image: insurance?.image ?? '',
          isExternalInsurance: insurance?.isExternalInsurance ?? false,
          products: insurance?.products ?? [{} as any],
        }) :
        setNoInsuranceInfo({
          description: insurance?.description ?? '',
          image: insurance?.image ?? '',
          title: insurance?.title ?? '',
        })
      dispatch(setLoading(false))
    }
    getInsuranceDetails()
  }, [])

  const onBackPress = () => {
    navigate('/services/passport')
  }

  const onPetpassPlanesPress = () => {
    window.open('https://petpass.pro/promo/carrefour/Gpp9KYa1', '_blank')
  }

  if (hasInsurance === undefined) {
    return null
  } else if (hasInsurance && insurance) {
    return (
      <AppBackground isSafe>
        <HeaderWithBack title={('Tu seguro')} backPress={onBackPress} backIcon={<BackIcon />} />
        <BottomActionContainer
          bottomAction={
            insurance.isExternalInsurance ? (
              <Button
                title={('Ver planes PetPass')}
                icon={<PawIcon width={24} height={24} />}
                size="block"
                textType="cta_medium"
                borderRadius={5}
                textColor="background0"
                allowIconAndText
                color="primary"
                onPress={onPetpassPlanesPress}
              />
            ) : (
              <React.Fragment />
            )
          }>
          <Banner
              src= {insurance.image}
          />
          <React.Fragment>
            {insurance.policiInfo.map((item, index) => (
              <PolicyInfoContainer key={`${item.key}-${index}`}>
                <Text type="H5" fontWeight="bold" fontFamily="bold">
                  {item.key}
                </Text>
                <Text type="caption">{item.value}</Text>
              </PolicyInfoContainer>
            ))}
          </React.Fragment>
        </BottomActionContainer>
      </AppBackground>
    )
  } else {
    return (
      <AppBackground isSafe>
      <HeaderWithBack title={('Tu seguro')} backPress={onBackPress} backIcon={<BackIcon />} />
      <BottomActionContainer
        bottomAction={
          <Button
            title={('Ver seguros')}
            icon={<PawIcon width={24} height={24} />}
            size="block"
            textType="cta_medium"
            borderRadius={5}
            textColor="background0"
            allowIconAndText
            color="primary"
            onPress={onPetpassPlanesPress}
          />
        }>
        <MainContainer>
          <SadPetImage src={noInsuranceInfo?.image} />
          <Text type="H4" fontWeight="bold" fontFamily="bold" textAlign="center">
            {noInsuranceInfo?.title}
          </Text>
          <Text type="caption" textAlign="center">
            {noInsuranceInfo?.description}
          </Text>
        </MainContainer>
      </BottomActionContainer>
    </AppBackground>
    )
  }
};

export default Insurance;