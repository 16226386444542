import React, { useEffect } from 'react'
import './App.css'
import { Provider } from 'react-redux'
import { store, persistor, initLocalization } from 'store'
import Router from './navigations/AppNavigator'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context'
import ThemeProvider from './providers/ThemeProvider'
import { Host } from 'react-native-portalize'
import FullScreenContainer from './components/FullScreenContainer'

initLocalization(__DEV__)

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <ThemeProvider>
            <Host>
              <FullScreenContainer>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </FullScreenContainer>
            </Host>
          </ThemeProvider>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  )
}
export default App
