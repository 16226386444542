import React from 'react'
import { StateContainer, PetPictureContainer, Picture, PressableContainer, WebPicture } from './style'

export interface PetPictureProps {
  src: string
  showState?: boolean
  PencilIcon?: React.JSX.Element
  onStatePress?: () => void
  isWeb?: boolean
}

const PetPicture: React.FC<PetPictureProps> = ({ src, showState = true, PencilIcon, onStatePress, isWeb = false }) => {
  const [containerRadius, setContainerRadius] = React.useState(0)
  const stateAngle = Math.PI / 4

  return (
    <>
      {showState ? (
        <PressableContainer
          width={180}
          aspectRatio={1}
          onPress={() => onStatePress?.()}
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout
            setContainerRadius(width)
          }}>
          {isWeb ? (
            <WebPicture src={src?.length > 0 ? src : ' '} />
          ) : (
            <Picture
              source={{
                uri: src?.length > 0 ? src : ' ',
              }}
              aspectRatio={1}
              placeHolder={false}
            />
          )}
          {showState && !!containerRadius && (
            <StateContainer
              x={containerRadius * Math.cos(stateAngle)}
              y={containerRadius * Math.sin(stateAngle)}
              style={{
                backgroundColor: 'white',
              }}>
              {PencilIcon}
            </StateContainer>
          )}
        </PressableContainer>
      ) : (
        <PetPictureContainer width={180} aspectRatio={1}>
          {isWeb ? (
            <WebPicture src={src?.length > 0 ? src : ' '} />
          ) : (
            <Picture
              source={{
                uri: src?.length > 0 ? src : ' ',
              }}
              aspectRatio={1}
              placeHolder={false}
            />
          )}
        </PetPictureContainer>
      )}
    </>
  )
}

export default PetPicture
