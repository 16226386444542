/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, TouchableOpacity } from 'react-native'
import AppBackground from '../../atoms/AppBackground'
import Text from '../../atoms/Text'
import { LocationCard, LocationCardDataProps } from '../../molecules/LocationCard'
import PetPicture from '../../molecules/PetPicture'
import Button from '../../molecules/button'
import {
  ButtonsContainer,
  Container,
  DataContainer,
  ItemContainer,
  ItemText,
  ItemTitle,
  NameContainer,
  NameText,
  PairItemContainer,
  PinContainer,
  PublicInfoContainer,
  SectionContainer,
  SectionTitle,
  SosLocationContainer,
  SosText,
  TitleUnderLine,
  UnderlineContainer
} from './styles'

interface DogData {
  greetings: string | undefined
  avatar: string
  name: string | undefined
  isPetLost: boolean
  activeSos: {
    guid?: string
    address?: string
    lat?: number
    lon?: number
  }
  sections: {
    title: string
    items: { title: string; value: string }[]
  }[]
}

interface SosTemplateProps {
    handleMap: () => void
    onHistoryPress: () => void
    onSosPress: () => void
    mapPinIcon?: React.ReactNode
    dogToPublish?: DogData
    handleRightButton: () => void
}

const SosTemplate: React.FC<SosTemplateProps> = ({
    handleMap,
    onHistoryPress,
    onSosPress,
    mapPinIcon,
    dogToPublish,
    handleRightButton
}) => {
  const { t } = useTranslation()
  const maxTextLength = 30

  const card: LocationCardDataProps = useMemo(() => {
    // TODO viene del api
    return {
      name: dogToPublish?.name,
      imageUrl: `https://maps.googleapis.com/maps/api/staticmap?center=${dogToPublish?.activeSos?.lat},${dogToPublish?.activeSos?.lon}&zoom=18&size=500x500&markers=color:red%7Clabel:C%7C${dogToPublish?.activeSos?.lat},${dogToPublish?.activeSos?.lon}&key=AIzaSyADFUW8ESzmVt8pEGi2iZD8KlrNAZd6LYA
      `,
      location: {
        name: dogToPublish?.activeSos.address,
        latitude: dogToPublish?.activeSos.lat,
        longitude: dogToPublish?.activeSos.lon,
        latitudeDelta: 0,
        longitudeDelta: 0,
      },
    } as any
  }, [dogToPublish])

  const showActiveSearch = useMemo(() => {
    return dogToPublish?.isPetLost && dogToPublish.activeSos.lat && dogToPublish.activeSos.lon
  }, [dogToPublish])

  if (!dogToPublish) {
    return null
  }

  return (
    <AppBackground isSafe>
      <ScrollView>
        <Container>
          <PetPicture src={dogToPublish?.avatar || ''} showState={false} />
          <NameContainer>
            <NameText type="H3" fontFamily="bold" fontWeight="bold">
              {dogToPublish?.greetings}
            </NameText>
            <SosText type="H3" fontFamily="bold" fontWeight="bold">
              {t('sos.sos')}
            </SosText>
            <ButtonsContainer>
              {dogToPublish?.isPetLost ? (
                <Button
                  title={t('sos.solved')}
                  size="block"
                  textType="cta_medium"
                  color="success"
                  textColor="background0"
                  borderRadius={10}
                  onPress={handleRightButton}
                  textStyle={{ paddingHorizontal: 20 }}
                />
              ) : (
                <Button
                  title={t('sos.sendSos')}
                  size="block"
                  textType="cta_medium"
                  color="error"
                  textColor="background0"
                  borderRadius={10}
                  onPress={onSosPress}
                  textStyle={{ paddingHorizontal: 20 }}
                />
              )}
            </ButtonsContainer>
          </NameContainer>
          <PublicInfoContainer>
            <DataContainer>
              {dogToPublish?.sections?.map((item, index) => {
                return (
                  <SectionContainer key={index}>
                    <UnderlineContainer>
                      <SectionTitle type="H4">{item.title}</SectionTitle>
                    </UnderlineContainer>
                    <ItemContainer>
                      {item?.items?.map((pair, index) => {
                        return (
                          <PairItemContainer key={index}>
                            <ItemTitle type="caption" fontFamily="bold" fontWeight="bold">
                              {pair.title + ': '}
                            </ItemTitle>
                            {pair.value?.length > maxTextLength ? (
                              <ItemText type="caption" numberOfLines={1}>
                                {pair.value.substring(0, maxTextLength) + '...'}
                              </ItemText>
                            ) : (
                              <ItemText type="caption">{pair.value}</ItemText>
                            )}
                          </PairItemContainer>
                        )
                      })}
                    </ItemContainer>
                  </SectionContainer>
                )
              })}
            </DataContainer>
          </PublicInfoContainer>
          {showActiveSearch ? (
            <SosLocationContainer>
              <UnderlineContainer addHorizontalMargin>
                <TitleUnderLine type="H4" fontFamily="bold" fontWeight="bold">
                  {t('sos.sosActive')}
                </TitleUnderLine>
                <TouchableOpacity onPress={onHistoryPress}>
                  <Text type="caption" color="primary" fontFamily="bold" fontWeight="bold">
                    {t('common.history')}
                  </Text>
                </TouchableOpacity>
              </UnderlineContainer>
              <LocationCard
                data={card}
                onPress={handleMap}
                pinIcon={
                  <PinContainer>
                    {mapPinIcon}
                  </PinContainer>
                }
              />
            </SosLocationContainer>
          ) : (
            <Button
              onPress={onHistoryPress}
              title={t('common.history')}
              textType="cta_small"
              size="medium"
              textColor="background0"
              color="primary"
              borderRadius={20}
            />
          )}
        </Container>
      </ScrollView>
    </AppBackground>
  )
}

export default SosTemplate
