import { FC, useState } from "react";
import { Container, ContentContainer, HeaderContainer, InputContainer, StepContainer } from "./styles";
import Input from "../../molecules/input";
import Text from "../../atoms/Text";
import Button from "../../molecules/button";
import { OtpInputField } from "../../molecules/OtpInputField";
import { FormikErrors, FormikHandlers, FormikProps } from "formik";
import { TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";

interface ChangePasswordTemplateProps {
  isForgotPassword?: boolean;
  emailInputProps?: {
    onChange?: FormikHandlers['handleChange']
    onBlur?: FormikHandlers['handleBlur']
    value?: string
    errors?: FormikErrors<any>
  }
  emailSumbit?: (isForgotPassword: boolean) => void;
  codeInputProps: {
    onChange: FormikHandlers['handleChange']
    value: string
    errors: FormikErrors<any>
  }
  codeSumbit: () => void;
  passwordInputProps: {
    onChange: FormikHandlers['handleChange']
    onBlur: FormikHandlers['handleBlur']
    value: string
    errors: FormikErrors<any>
  }
  confirmationInputProps: {
    onChange: FormikHandlers['handleChange']
    onBlur: FormikHandlers['handleBlur']
    value: string
    errors: FormikErrors<any>
  }
  passwordSumbit: () => void;
  onResendCode?: () => void;
  backIcon?: React.ReactNode;
  onBack?: () => void;
  currentStep: number;
  blockedEyeIcon: React.ReactNode;
  eyeIcon: React.ReactNode;
  timer: number;
}

const ChangePasswordTemplate:FC<ChangePasswordTemplateProps> = ({
  isForgotPassword = false,
  emailInputProps,
  codeInputProps,
  emailSumbit,
  codeSumbit,
  passwordSumbit,
  passwordInputProps,
  confirmationInputProps,
  onResendCode,
  onBack,
  backIcon,
  currentStep,
  blockedEyeIcon,
  eyeIcon,
  timer
}) => {

  const handleStep0 = () => {
    emailSumbit && emailSumbit(isForgotPassword);
  };

  const handleStep1 = () => {
    codeSumbit && codeSumbit();
  };

  const handleStep2 = () => {
    passwordSumbit && passwordSumbit();
  };

  const handleBack = () => {
    onBack && onBack();
  }

  const [showPassword, setShowPassword] = useState(false);

  const {t} = useTranslation();

  return (
    <Container>
      <HeaderContainer>
        <TouchableOpacity
          onPress={() => handleBack()}
        >
          {!!backIcon && backIcon}
        </TouchableOpacity>
      </HeaderContainer>
      {currentStep === 0 && (
        <StepContainer>
          <ContentContainer>
            {isForgotPassword ? (
              <>
                <Input
                  inputProps={{
                    placeholder: t("common.email"),
                    onChangeText: emailInputProps?.onChange,
                    onBlur: emailInputProps?.onBlur,
                    value: emailInputProps?.value
                  }}
                />
                {emailInputProps && emailInputProps.errors && (
                  <Text type="small" color="error">
                    {emailInputProps.errors.toString()}
                  </Text>
                )}
                <Text
                  type="H5"
                  textAlign="center"
                >
                  {t("forgotPassword.registeredEmailTitle")}
                </Text>
              </>
            ):(
              <Text
                type="H5"
                textAlign="center"
              >
                {t("forgotPassword.otpSendTitle")}
              </Text>
            )}
          </ContentContainer>
          {timer > 0 && (
            <Text type="caption" textAlign="center">
              {t("forgotPassword.timer", {time: timer})}
            </Text>
          )}
          <Button
            onPress={() => handleStep0()}
            title={t("common.continue")}
            size="block"
            textType="cta_medium"
            borderRadius={5}
            textColor="background0"
            color="primary"
          />
        </StepContainer>
      )}
      {currentStep === 1 && (
        <StepContainer>
          <ContentContainer>
            <InputContainer>
              <Text
                type="H4"
                textAlign="center"
              >
                  {t("forgotPassword.otpTitle")}
              </Text>
              <OtpInputField
                value={codeInputProps.value}
                handleChange={codeInputProps.onChange}
                maxLength={4}
                errors={codeInputProps.errors}
              />
              {codeInputProps.errors && (
                <Text type="small" color="error">
                  {codeInputProps.errors.toString()}
                </Text>
              )}
              <TouchableOpacity
                onPress={onResendCode}
              >
                <Text
                  type="H5"
                  color="foreground3"
                >
                  {t("forgotPassword.otpResend")}
                </Text>
              </TouchableOpacity>
            </InputContainer>
          </ContentContainer>
          {timer > 0 && (
            <Text type="caption" textAlign="center">
              {t("forgotPassword.timer", {time: timer})}
            </Text>
          )}
          <Button
            onPress={() => handleStep1()}
            title={t("common.continue")}
            size="block"
            textType="cta_medium"
            borderRadius={5}
            textColor="background0"
            color="primary"
          />
        </StepContainer>
      )}
      {currentStep === 2 && (
        <StepContainer>
          <ContentContainer>
            <Text
              type="H4"
              textAlign="center"
            >
              {t("forgotPassword.newPasswordTitle")}
            </Text>
            <Input
              inputProps={{
                placeholder: t("forgotPassword.newPasswordPlaceholder"),
                onChangeText: passwordInputProps.onChange,
                onBlur: passwordInputProps.onBlur,
                value: passwordInputProps.value,
                secureTextEntry: true
              }}
              right={<TouchableOpacity
                onPress={() => setShowPassword(!showPassword)}
              >
                {showPassword ? blockedEyeIcon : eyeIcon }
              </TouchableOpacity>}
            />
            <Input
              inputProps={{
                placeholder: t("forgotPassword.newPasswordConfirmationPlaceholder"),
                onChangeText: confirmationInputProps.onChange,
                onBlur: confirmationInputProps.onBlur,
                value: confirmationInputProps.value,
                secureTextEntry: true
              }}
              right={<TouchableOpacity
                onPress={() => setShowPassword(!showPassword)}
              >
                {showPassword ? blockedEyeIcon : eyeIcon }
              </TouchableOpacity>}
            />
            {confirmationInputProps.errors && (
              <Text type="small" color="error">
                {confirmationInputProps.errors.toString()}
              </Text>
            )}
            {passwordInputProps.errors && (
              <Text type="small" color="error">
                {passwordInputProps.errors.toString()}
              </Text>
            )}
          </ContentContainer>
          <Button
            onPress={() => handleStep2()}
            title={t("common.continue")}
            size="block"
            textType="cta_medium"
            borderRadius={5}
            textColor="background0"
            color="primary"
          />
        </StepContainer>
      )}
      {currentStep === 3 && (
        <StepContainer>
          <ContentContainer>
            <Text
              type="H5"
            >
              {t("forgotPassword.successTitle")}
            </Text>
          </ContentContainer>
          <Button
            onPress={() => onBack && onBack()}
            title={t("common.continue")}
            size="block"
            textType="cta_medium"
            borderRadius={5}
            textColor="background0"
            color="primary"
          />
        </StepContainer>
      )}
    </Container>
  );
}

export default ChangePasswordTemplate;