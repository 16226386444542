import styled from '@emotion/native'
import AppBackground from '../../atoms/AppBackground'
import { Platform } from 'react-native'

export const Container = styled(AppBackground)(({ theme }) => ({
  padding: theme.sizes.xs,
  display: 'flex',
  paddingTop: theme.sizes.xs,
}))

export const MediaContainer = styled.View(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ButtonContainer = styled.View(({ theme }) => ({
  paddingTop: Platform.OS !== 'web' ? theme.sizes.sm : 0,
  marginBottom: Platform.OS === 'web' ? theme.sizes.xs : 0,
}))
