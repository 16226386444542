import styled from '@emotion/native'
import { ScrollView } from 'react-native'
import { Text } from 'components'
import AppBackground from '../../atoms/AppBackground'

export const MainWrapper = styled(AppBackground)(({ theme }) => ({
  paddingBottom: theme.sizes.xs,
}))

export const Container = styled(ScrollView)(({ theme }) => ({
  padding: theme.sizes.xs,
}))

export const InputContainer = styled.View(({ theme }) => ({
  gap: theme.sizes.xxxs,
}))

export const CheckBoxContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.sizes.xs,
}))

export const FormsContainer = styled.View(({ theme }) => ({
  flex: 1,
  gap: theme.sizes.md,
}))

export const ButtonContainer = styled.View(({ theme }) => ({
  justifyContent: 'flex-end',
  paddingBottom: 1,
  paddingHorizontal: theme.sizes.xs,
}))

export const CheckBoxText = styled(Text)({
  flexShrink: 1,
})
