import React from 'react'
import { Container } from './styles'
import { StyleProp, ViewStyle } from 'react-native'
import { SvgProps } from 'react-native-svg'

interface Props {
  isChecked: boolean
  style?: StyleProp<ViewStyle>
  Check:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
}

const SelectionCheck: React.FC<Props> = ({ isChecked, style, Check }) => {
  return <Container style={style}>{isChecked && <Check height="100%" width="100%" />}</Container>
}

export default SelectionCheck
