import styled from '@emotion/native'

export const Container = styled.View(({}) => ({}))

export const NavigationMenu = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-around',
  bottom: 0,
  alignItems: 'center',
  backgroundColor: theme.colors.background0,
  paddingHorizontal: 20,
  paddingVertical: 10,
}))
