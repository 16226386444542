import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NotFoundScreen from '../pages/NotFound'
import ServicePetition from '../pages/ServicePetition'

const CommonGroup: React.FC = () => {
  return (
    <Routes>
      <Route path="not-found" element={<NotFoundScreen />} />
      <Route path='service-petition' element={<ServicePetition/>} />
    </Routes>
  )
}

export default CommonGroup