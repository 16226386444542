import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'

export const Container = styled(View)(({ theme }) => ({
  padding: theme.sizes.md,
  borderRadius: 10,
  gap: theme.sizes.md,
  alignItems: 'center',
  marginHorizontal: theme.sizes.md,
}))

export const DataContainer = styled(View)(({ theme }) => ({
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
}))

export const PlaceholderContainer = styled(View)(({ theme }) => ({
  marginVertical: theme.sizes.md,
}))

export const AddIconTouch = styled(TouchableOpacity)(({ theme }) => ({}))

export const PhotoContainer = styled(View)(({ theme }) => ({
  backgroundColor: 'red',
}))
