import { useTheme } from '@emotion/react'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { FlatList, ListRenderItem, StyleProp, ViewStyle } from 'react-native'
import Resource, { IResource, ResourceOnPressProps, ResourceProps } from '../../molecules/Resource'
import { ResourceContentProps } from '../../molecules/ResourceContent/interfaces'
import { flatListStyles, Separator, Title, Wrapper } from './style'
import { CustomTextProps } from '../../atoms/Text'
import Carousel from 'react-native-reanimated-carousel'

export interface IContainer {
  id: string
  title?: string
  banner?: boolean
  resources: IResource[]
  numberOfResourcesVisible: number
  resourceSeparatorWidth?: number
  scrollType: 'normal' | 'paginated'
  ResourceContentComponent: React.FC<ResourceContentProps>
  listStyle?: StyleProp<ViewStyle>
  onPressResource?: (resource: ResourceOnPressProps) => void
}

export interface ContainerProps {
  data: IContainer
  containerIndex: number
  titleConfig?: Partial<CustomTextProps>
  imageUrlBuilder: ResourceContentProps['imageUrlBuilder']
}

const Container = ({
  data,
  containerIndex,
  titleConfig,
  imageUrlBuilder,
}: PropsWithChildren<ContainerProps>): React.ReactElement<ContainerProps> => {
  const [width, setWidth] = useState(0)
  const theme = useTheme()

  const resourceSeparatorWidth = Boolean(data.title) ? data.resourceSeparatorWidth ?? theme.sizes.sm : 0
  const ItemSeparator = () => <Separator size={resourceSeparatorWidth} />

  const resourceWidth = useMemo(() => {
    if (data.scrollType === 'paginated') {
      return width / data.numberOfResourcesVisible
    }
    return width / data.numberOfResourcesVisible - resourceSeparatorWidth
  }, [data.numberOfResourcesVisible, data.scrollType, resourceSeparatorWidth, width])

  const getItemLayout = (_data: IResource[] | null | undefined, index: number) => {
    const length = resourceWidth + resourceSeparatorWidth
    return { length, offset: length * index, index }
  }

  const renderItem: ListRenderItem<IResource> = ({ item, index }) => {
    return (
      <Resource
        value={item}
        width={resourceWidth}
        containerIndex={containerIndex}
        resourceIndex={index}
        ContentComponent={data.ResourceContentComponent}
        onPress={data.onPressResource}
        containerData={data}
        imageUrlBuilder={imageUrlBuilder}
      />
    )
  }
  return (
    <Wrapper testID="container-wrapper" onLayout={(event) => setWidth(event.nativeEvent.layout.width)}>
      {width > 0 && (
        <>
          {Boolean(data.title) && (
            <Title type="H5" fontWeight="medium" {...titleConfig}>
              {data.title}
            </Title>
          )}
          {Boolean(!data.banner) ? (
            <FlatList
              testID="container-list"
              style={[flatListStyles.style, data.listStyle]}
              horizontal
              data={data.resources}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item.id}
              renderItem={renderItem}
              getItemLayout={getItemLayout as any}
              showsHorizontalScrollIndicator={false}
              ItemSeparatorComponent={ItemSeparator}
              contentContainerStyle={{ flexGrow: 1, alignItems: 'center' }}
            />
          ) : (
            <Carousel
              // {...baseOptions}
              style={[flatListStyles.style, data.listStyle]}
              width={width}
              height={resourceWidth / (171 / 83)}
              loop
              pagingEnabled={true}
              snapEnabled={true}
              vertical={false}
              autoPlay={true}
              autoPlayInterval={3000}
              onProgressChange={
                (_, absoluteProgress) => {
                  // console.debug(absoluteProgress)
                }
                // (progressValue.value = absoluteProgress)
              }
              data={data.resources}
              renderItem={renderItem as any}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

export default Container
