import React from 'react'
import { BodyContainer, Container, HeaderContainer, MediaContainer, MediaPreview } from './styles'
import { Text } from 'components'

export interface PostProps {
  header: string
  time: number
  content: string
  source?: string
}

const Post: React.FC<PostProps> = ({ header, time, content, source }) => {
  return (
    <Container>
      <MediaContainer>{source && <MediaPreview source={{ uri: source }} />}</MediaContainer>
      <BodyContainer>
        <HeaderContainer>
          <Text type="H4" color="foreground0">
            {header}
          </Text>
          <Text type="caption" color="foreground1" fontWeight="bold">
            {time + 'm ago'}
          </Text>
        </HeaderContainer>
        <Text type="caption" color="foreground0">
          {content}
        </Text>
      </BodyContainer>
    </Container>
  )
}

export default Post
