import React from 'react'
import { ViewStyle } from 'react-native'
import { Container, ItemContainer, ItemText, ItemView } from './style'
import { TextSizes } from 'themes'
import { SvgXml } from 'react-native-svg'

export type SvgSizeItem = 'small' | 'medium' | 'large'

export interface SvgItemProps {
  icon?: React.ReactNode
  title?: string
  style?: ViewStyle
  color: string
  active?: boolean
  onPress?: () => void
  titleAsIcon?: boolean
  size?: SvgSizeItem
  textType?: keyof TextSizes
}

const SvgItem: React.FC<SvgItemProps> = ({
  icon,
  title,
  style,
  color,
  onPress,
  active = false,
  titleAsIcon = false,
  size = 'medium',
  textType,
}) => {
  return (
    <Container>
      <ItemContainer active={active} onPress={onPress} size={size}>
        <ItemView isActive={active} color={color}>
          {titleAsIcon ? <ItemText type={textType ?? 'caption'}>{title}</ItemText> : icon && icon}
        </ItemView>
      </ItemContainer>
      {!titleAsIcon && <ItemText type={textType ?? 'caption'}>{title}</ItemText>}
    </Container>
  )
}

export default SvgItem
