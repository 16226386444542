import styled from '@emotion/native'
import { Input, Text } from 'components'
import { ScrollView, TouchableOpacity, View } from 'react-native'

export const Container = styled(View)(({ theme }) => ({
  flex: 1,
  width: '100%',
  alignItems: 'center',
  gap: theme.sizes.md,
}))

export const ContentContainer = styled(View)(({ theme }) => ({
  flex: 1,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.sizes.md,
}))

export const StepContainer = styled(View)(({ theme }) => ({
  flex: 1,
  width: '100%',
  gap: theme.sizes.md,
}))

export const HeaderContainer = styled(View)(({ theme }) => ({
  width: '100%',
}))

export const InputContainer = styled(View)(({ theme }) => ({
  width: '100%',
  gap: theme.sizes.xs,
  justifyContent: 'center',
  alignItems: 'center',
}))