import { AppBackground, Button, LocationCard, LocationCardDataProps, PetPicture, Text } from 'components'
import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as MapPin } from '../../../../assets/icons/pin_filled.svg'
import { setLoading, useAppDispatch } from 'store'
import { useSos } from 'business-logic'
import { ScrollView, TouchableOpacity } from 'react-native'
//import { getSosDetail } from "apis"
import {
  ButtonsContainer,
  Container,
  DataContainer,
  ItemContainer,
  ItemText,
  ItemTitle,
  NameContainer,
  NameText,
  PairItemContainer,
  PinContainer,
  PublicInfoContainer,
  SectionContainer,
  SectionTitle,
  SosLocationContainer,
  SosText,
  TitleUnderLine,
  UnderlineContainer,
} from './styles'
import { useNavigate } from 'react-router-dom'

interface DogData {
  greetings: string | undefined
  avatar: string
  name: string | undefined
  isPetLost: boolean
  activeSos: {
    guid?: string
    address?: string
    lat?: number
    lon?: number
  }
  sections: {
    title: string
    items: { title: string; value: string }[]
  }[]
}

const Sos: React.FC = () => {
  const maxTextLength = 30
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { getDetail, resolveSos } = useSos({})

  const [dogToPublish, setDogToPublish] = useState<DogData>()

  const fetchDataToPublish = async () => {
    dispatch(setLoading(true))
    const { data, success: _ } = await getDetail()
    const dog: DogData = {
      avatar: data?.avatar ? data.avatar : '',
      greetings: data?.greetings,
      name: data?.name,
      isPetLost: !!data?.activeSOS,
      activeSos: {
        lat: data?.activeSOS?.lat,
        lon: data?.activeSOS?.lon,
        guid: data?.activeSOS?.guid,
        address: data?.activeSOS?.address,
      },
      sections: data?.sections || [],
    }
    setDogToPublish(dog)
    dispatch(setLoading(false))
  }

  const handleRightButton = async () => {
    await resolveSos()
    setTimeout(() => {
      fetchDataToPublish()
    }, 500)
  }

  useEffect(() => {
    fetchDataToPublish()
  }, [])

  const onSosPress = () => {
    navigate('/services/sos/selectLocation')
  }

  const onHistoryPress = () => {
    navigate('/services/sos/history')
  }

  const handleMap = () => {
    navigate('/services/sos/Map', {
      state: {
        sosGuid: dogToPublish?.activeSos.guid || '',
      },
    })
  }

  const card: LocationCardDataProps = useMemo(() => {
    return {
      name: dogToPublish?.name,
      imageUrl: `https://maps.googleapis.com/maps/api/staticmap?center=${dogToPublish?.activeSos?.lat},${dogToPublish?.activeSos?.lon}&zoom=18&size=500x500&markers=color:red%7Clabel:C%7C${dogToPublish?.activeSos?.lat},${dogToPublish?.activeSos?.lon}&key=AIzaSyADFUW8ESzmVt8pEGi2iZD8KlrNAZd6LYA
      `,
      location: {
        name: dogToPublish?.activeSos.address,
        latitude: dogToPublish?.activeSos.lat,
        longitude: dogToPublish?.activeSos.lon,
        latitudeDelta: 0,
        longitudeDelta: 0,
      },
    } as any
  }, [dogToPublish])

  const showActiveSearch = useMemo(() => {
    return dogToPublish?.isPetLost && dogToPublish.activeSos.lat && dogToPublish.activeSos.lon
  }, [dogToPublish])

  if (!dogToPublish) {
    return null
  }

  return (
    <AppBackground isSafe>
      <ScrollView>
        <Container>
          <PetPicture src={dogToPublish?.avatar || ''} showState={false} isWeb />
          <NameContainer>
            <NameText type="H3" fontFamily="bold" fontWeight="bold">
              {dogToPublish?.greetings}
            </NameText>
            <SosText type="H3" fontFamily="bold" fontWeight="bold">
              {'SOS'}
            </SosText>
            <ButtonsContainer>
              {dogToPublish?.isPetLost ? (
                <Button
                  title={'SOS Solucionado'}
                  size="block"
                  textType="cta_medium"
                  color="success"
                  textColor="background0"
                  borderRadius={10}
                  onPress={handleRightButton}
                  textStyle={{ paddingHorizontal: 20 }}
                />
              ) : (
                <Button
                  title={'Comunicar SOS'}
                  size="block"
                  textType="cta_medium"
                  color="error"
                  textColor="background0"
                  borderRadius={10}
                  onPress={onSosPress}
                  textStyle={{ paddingHorizontal: 20 }}
                />
              )}
            </ButtonsContainer>
          </NameContainer>
          <PublicInfoContainer>
            <DataContainer>
              {dogToPublish?.sections?.map((item, index) => {
                return (
                  <SectionContainer key={index}>
                    <UnderlineContainer>
                      <SectionTitle type="H4">{item.title}</SectionTitle>
                    </UnderlineContainer>
                    <ItemContainer>
                      {item?.items?.map((pair, index) => {
                        return (
                          <PairItemContainer key={index}>
                            <ItemTitle type="caption" fontFamily="bold" fontWeight="bold">
                              {pair.title + ': '}
                            </ItemTitle>
                            {pair.value?.length > maxTextLength ? (
                              <ItemText type="caption" numberOfLines={1}>
                                {pair.value.substring(0, maxTextLength) + '...'}
                              </ItemText>
                            ) : (
                              <ItemText type="caption">{pair.value}</ItemText>
                            )}
                          </PairItemContainer>
                        )
                      })}
                    </ItemContainer>
                  </SectionContainer>
                )
              })}
            </DataContainer>
          </PublicInfoContainer>
          {showActiveSearch ? (
            <SosLocationContainer>
              <UnderlineContainer addHorizontalMargin>
                <TitleUnderLine type="H4" fontFamily="bold" fontWeight="bold">
                  {'SOS Activo'}
                </TitleUnderLine>
                <TouchableOpacity onPress={onHistoryPress}>
                  <Text type="caption" color="primary" fontFamily="bold" fontWeight="bold">
                    {'Ver Todos'}
                  </Text>
                </TouchableOpacity>
              </UnderlineContainer>
              <LocationCard
                data={card}
                onPress={handleMap}
                pinIcon={<PinContainer>{<MapPin width={15} height={15} />}</PinContainer>}
                isWeb
              />
            </SosLocationContainer>
          ) : (
            <Button
              onPress={onHistoryPress}
              title={'Ver Todos'}
              textType="cta_small"
              size="medium"
              textColor="background0"
              color="primary"
              borderRadius={20}
            />
          )}
        </Container>
      </ScrollView>
    </AppBackground>
  )
}

export default Sos
