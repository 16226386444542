import React, { ReactElement } from 'react'
import { BackButton, EmptyView, HeaderContainer, IconContainer, TitleHeader } from './style'

export interface HeaderWithBackProps {
  title?: string
  backPress: () => void
  backIcon?: React.ReactNode
  style?: React.CSSProperties
  icon?: ReactElement
  backgroundColor?: string
  leftIcon?: React.ReactNode
}

const HeaderWithBack: React.FC<HeaderWithBackProps> = ({
  title,
  backPress,
  style,
  backIcon,
  icon,
  backgroundColor,
  leftIcon,
}) => {
  const [backIconWidth, setBackIconWidth] = React.useState(0)
  return(
    <HeaderContainer color={backgroundColor}>
    <BackButton onPress={backPress}
      onLayout={(event) => {
        setBackIconWidth(event.nativeEvent.layout.width)
      }}
    >{backIcon}</BackButton>
    {title && (
      <TitleHeader type="H4" fontWeight="semibold" color={'foreground0'} textAlign="center">
        {title}
      </TitleHeader>
    )}
    {icon && <IconContainer>{icon}</IconContainer>}
    {leftIcon ? leftIcon : <EmptyView width={backIconWidth}/>}
  </HeaderContainer>
  )
}

export default HeaderWithBack
