import styled from '@emotion/native'

export const Container = styled.View(({theme}) => ({
  flex: 1,
  padding: theme.sizes.sm,
  paddingTop: theme.sizes.xxs,
}))

export const ModalContainer = styled.View(({theme}) => ({
  width: '100%',
  padding: theme.sizes.xs,
  backgroundColor: theme.colors.background0,
  borderRadius: theme.sizes.xs,
}))

export const ModalHeader = styled.TouchableOpacity(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.sizes.sm,
  padding: theme.sizes.xxxs,
  gap: theme.sizes.xxs,
  width: '100%',
}))