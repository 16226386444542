import React from 'react'
import { Container, CrossContainer, Header, StyledButton, TextContainer, Title } from './styles'
import { Colors } from 'themes'
import { SvgProps } from 'react-native-svg'
import { useTheme } from '@emotion/react'
import Text from '../Text'

export interface StepperHeaderProps {
  title: string
  isLeftEnabled?: boolean
  isRightEnabled?: boolean
  leftValue?: string
  rightValue?: string
  rightAction?: () => void
  leftAction?: () => void
  ExitCross?:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
  titleColor?: keyof Colors
  buttonColor?: keyof Colors
  buttonFlex?: number
  titleFlex?: number
  type?: 'navigation' | 'exit'
  onExitPress?: () => void
}

const StepperHeader: React.FC<StepperHeaderProps> = ({
  title,
  rightAction,
  leftAction,
  rightValue = '',
  leftValue = '',
  isLeftEnabled = false,
  isRightEnabled = false,
  ExitCross,
  type = 'navigation',
  titleColor = 'foreground0',
  buttonColor = 'primary',
  buttonFlex = 0.2,
  titleFlex = 0.6,
  onExitPress,
}) => {
  const theme = useTheme()
  const ICON_DIMENSIONS = theme.scale(40)
  const handleLeft = () => {
    leftAction && leftAction()
  }

  const handleRight = () => {
    rightAction && rightAction()
  }
  if (type === 'exit') {
    return (
      <Header>
        <TextContainer>
          <Text type="H2" fontWeight="semibold">
            {title}
          </Text>
          {onExitPress && ExitCross && (
            <CrossContainer>
              <ExitCross width={ICON_DIMENSIONS} height={ICON_DIMENSIONS} onClick={onExitPress} onPress={onExitPress} />
            </CrossContainer>
          )}
        </TextContainer>
      </Header>
    )
  } else if (type === 'navigation') {
    return (
      <Container>
        {isLeftEnabled && leftAction && leftValue ? (
          <StyledButton
            title={leftValue}
            size="small"
            textType="cta_large"
            textColor={buttonColor}
            onPress={handleLeft}
            flex={buttonFlex}
          />
        ) : null}
        <Title type="H2" fontWeight="bold" color={titleColor} textAlign="center" flex={titleFlex}>
          {title}
        </Title>
        {isRightEnabled && rightAction && rightValue ? (
          <StyledButton
            title={rightValue}
            size="small"
            textType="cta_large"
            textColor={buttonColor}
            onPress={handleRight}
            flex={buttonFlex}
          />
        ) : null}
      </Container>
    )
  } else {
    return null
  }
}

export default StepperHeader
