var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
export var useAddPetVaccine = function (_a) {
    var onNextPress = _a.onNextPress;
    var t = useTranslation().t;
    var validationSchema = yup.object().shape({
        vaccineLote: yup.number().required(t('addPetVaccine.vaccineLote_required')),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            vaccineLote: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return onNextPress(formValues); },
    }), []);
    return values;
};
