import React from 'react'
import {
  Container,
  DeleteButton,
  ProfileDataContainer,
  ProfilePicture,
  ProfileTextContainer,
  UpperWrapper,
} from './styles'
import { Contact } from 'store'
import Text from '../../atoms/Text'
import { SvgProps } from 'react-native-svg'
import { useTranslation } from 'react-i18next'

interface ContactDetailsMenuProps {
  onClose: () => void
  onDelete: () => void
  contactData: Contact
  ExitIcon:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
  DeleteIcon:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
}

const ContactDetailsMenu: React.FC<ContactDetailsMenuProps> = ({
  onClose,
  onDelete,
  contactData,
  ExitIcon,
  DeleteIcon,
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <UpperWrapper>
        <ProfileDataContainer>
          <ProfilePicture />
          <ProfileTextContainer>
            <Text type="caption">{contactData.name}</Text>
            <Text type="caption">{contactData.phone}</Text>
          </ProfileTextContainer>
        </ProfileDataContainer>
        <ExitIcon onPress={onClose} width={40} height={40} />
      </UpperWrapper>
      <DeleteButton>
        <Text type="caption" color="tertiary" onPress={onDelete}>
          {t('group_details.delete')}
        </Text>
        <DeleteIcon width={30} height={30} />
      </DeleteButton>
    </Container>
  )
}

export default ContactDetailsMenu
