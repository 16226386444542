import styled from '@emotion/native'

export const NavBar = styled.View(({ theme }) => ({
    height: 60,
    backgroundColor: theme.colors.background0,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.foreground0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    width: '100%',
}))