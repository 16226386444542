import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    appNavigationState: 'NOT_LOGGED_IN',
};
export var appStateSlice = createSlice({
    name: 'appState',
    initialState: initialState,
    reducers: {
        changeAppState: function (state, action) {
            console.debug('changeAppState', action.payload);
            state.appNavigationState = action.payload.appNavigationState;
            state.isSignUp = action.payload.isSignUp;
        },
    },
});
export var changeAppState = appStateSlice.actions.changeAppState;
