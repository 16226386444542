import styled from '@emotion/native'
import { Dimensions } from 'react-native'

export const PHOTO_HEIGHT = Dimensions.get('window').width / 2

export const Image = styled.Image(({}) => ({
  resizeMode: 'cover',
  width: '100%',
  height: '100%',
  borderRadius: 8,
  zIndex: 0,
}))

export const Container = styled.View<{ isFullWidth?: boolean }>(({ theme, isFullWidth }) => ({
  width: isFullWidth ? '100%' : PHOTO_HEIGHT - theme.sizes.xs - 5,
  height: isFullWidth ? Dimensions.get('window').width / 1.6 : PHOTO_HEIGHT - theme.sizes.xs - 5,
  overflow: 'hidden',
  position: 'relative',
  margin: theme.sizes.xxxs,
}))

export const FocusView = styled.View(({ theme }) => ({
  position: 'absolute',
  borderWidth: 3,
  borderColor: theme.colors.primary,
  width: '100%',
  height: '100%',
  borderRadius: 8,
}))
