import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ErrorText, HiddenTextInput, OtPInputSection, OtpInput, OtpInputContainer, OtpInputFocused } from './style'
import { FormikValues, FormikErrors, FormikHandlers } from 'formik'
import { Text } from 'components'
import { Keyboard, TextInput } from 'react-native'
import { useTranslation } from 'react-i18next'

interface OtpInputFieldProps {
  value: string
  handleChange: FormikHandlers['handleChange']
  maxLength: number
  errors: FormikErrors<FormikValues>
}

export const OtpInputField: React.FC<OtpInputFieldProps> = ({ value, handleChange, maxLength, errors }) => {
  const { t } = useTranslation()
  const inputRef = useRef<TextInput>(null)
  const [InputContainerIsFocused, setInputContainerIsFocused] = useState(false)
  const codeDigitsArray = new Array(maxLength).fill(2)

  const handleOnPress = () => {
    setInputContainerIsFocused(true)
    inputRef?.current?.focus()
  }

  const handleOnBlur = () => {
    setInputContainerIsFocused(false)
  }

  const toCodeDigitInput = (codeDigit: number, index: number) => {
    const emptyInputChar = ' '
    const digit = value[index] || emptyInputChar

    const isCurrentDigit = index === value.length
    const isLastDigit = index === maxLength - 1
    const isCodeFull = value.length === maxLength

    const isDigitFocused = isCurrentDigit || (isLastDigit && isCodeFull)

    const StyledOtpInput = InputContainerIsFocused && isDigitFocused ? OtpInputFocused : OtpInput

    return (
      <StyledOtpInput key={index}>
        <Text type="caption">{digit}</Text>
      </StyledOtpInput>
    )
  }

  useLayoutEffect(() => {
    handleOnPress()
  }, [])

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setInputContainerIsFocused(true)
    })
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setInputContainerIsFocused(false)
      inputRef.current?.blur()
    })

    return () => {
      showSubscription.remove()
      hideSubscription.remove()
    }
  }, [])

  return (
    <OtPInputSection>
      <OtpInputContainer onPress={handleOnPress}>{codeDigitsArray.map(toCodeDigitInput)}</OtpInputContainer>
      <HiddenTextInput
        ref={inputRef}
        value={value}
        onChangeText={handleChange}
        maxLength={maxLength}
        textContentType="oneTimeCode"
        onBlur={handleOnBlur}
        autoFocus
        autoCapitalize="characters"
      />
      {errors && errors.otp && <ErrorText type="small">{errors.otp?.toString()}</ErrorText>}
    </OtPInputSection>
  )
}
