import styled from '@emotion/native'
import { Button, Input, Text } from 'components'
import { View } from 'react-native'

export const Container = styled(View)(({ theme }) => ({
  alignItems: 'center',
  paddingVertical: theme.sizes.xs,
  gap: theme.sizes.xxs,
}))

export const InputSearch = styled(Input)(({}) => ({
  width: '100%',
}))

export const NameContainer = styled(View)(({}) => ({
  alignItems: 'center',
  width: '100%',
}))

export const NameText = styled(Text)(({}) => ({
  margin: 0,
  padding: 0,
}))

export const SosText = styled(Text)(({}) => ({
  color: '#FF0000',
  margin: 0,
  padding: 0,
}))

export const ButtonsContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.sm,
  marginVertical: theme.sizes.xxxs,
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ButtonWithBorder = styled(Button)(({ theme }) => ({
  borderWidth: 1,
  borderColor: theme.colors.primary,
  borderRadius: 10,
}))

export const PublicInfoContainer = styled(View)(({ theme }) => ({
  width: '100%',
  marginHorizontal: 30,
  paddingHorizontal: theme.sizes.md,
  paddingBottom: theme.sizes.xs,
}))

export const TitleUnderLine = styled(Text)<{ addHorizontalMargin?: boolean }>(({ theme, addHorizontalMargin }) => ({}))

export const UnderlineContainer = styled(View)<{ addHorizontalMargin?: boolean }>(({ theme, addHorizontalMargin }) => ({
  borderColor: '#E8E8E8',
  borderBottomWidth: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
}))

export const DataContainer = styled(View)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.sizes.sm,
  paddingVertical: theme.sizes.xxs,
}))

export const PairItemContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxs,
}))

export const ItemContainer = styled(View)(({}) => ({
  width: '100%',
  flexDirection: 'column',
  flexWrap: 'wrap',
}))

export const PinContainer = styled(View)(({ theme }) => ({}))

export const SosLocationContainer = styled(View)(({ theme }) => ({
  width: '100%',
  paddingHorizontal: theme.sizes.md,
}))

export const BottonsContainer = styled(View)(({ theme }) => ({
  gap: theme.sizes.sm,
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
}))

export const SectionContainer = styled(View)(({ theme }) => ({
  width: '100%',
  gap: theme.sizes.xs,
  flexDirection: 'column',
}))

export const SectionTitle = styled(Text)(({}) => ({
  fontWeight: 'bold',
}))

export const ItemTitle = styled(Text)(({}) => ({
  fontWeight: 'bold',
  fontSize: 17,
}))

export const ItemText = styled(Text)(({}) => ({
  fontSize: 17,
}))
