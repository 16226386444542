import { LocationDetailResponse } from 'apis'
import {
  ContentWrapper,
  HeaderTextContainer,
  ContentContainer,
  StyledScrollView,
  ContactContainer,
  ContactIcon,
  ContactText,
  VetPictureContainer,
  HeaderItemsContainer,
  DetailsContainer,
  UnderlinedContainer,
  DetailsItem,
  DetailsText,
  AddressContainer,
  Subtitle,
  DetailSubSubtitle,
} from './styles'
import { AppBackground, Button, LocationCard, PetPicture, Text } from 'components'
import React, { ReactElement } from 'react'
import { Linking, Platform, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'

interface VetDetailsProps {
  backIcon: React.ReactNode
  pinIcon: React.ReactNode
  onBackdropPress?: () => void
  locationDetails: LocationDetailResponse | null
  showAllVets?: () => void
  isFavorite: boolean
  setFavoriteVeterinary: () => void
  favVetGUID?: string
  contactInfo: {
    backgroundColor: string
    icon: ReactElement
    text: string
    fontColor: string
    onPress: () => void
  }[]
}

const VetDetailsTemplate: React.FC<VetDetailsProps> = ({
  backIcon,
  pinIcon,
  onBackdropPress,
  locationDetails,
  showAllVets,
  isFavorite,
  setFavoriteVeterinary,
  contactInfo,
  favVetGUID = '',
}) => {
  const { t } = useTranslation()

  const handleOpenMapsApp = () => {
    const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=' })
    const latLng = `${locationDetails?.lat},${locationDetails?.long}`
    const label = locationDetails?.name || 'Vet'
    const url = Platform.select({
      ios: `${scheme}${label}@${latLng}`,
      android: `${scheme}${latLng}(${label})`,
    })
    if (url) {
      Linking.openURL(url)
    }
  }
  return (
    <AppBackground isSafe>
      <HeaderItemsContainer
        style={{
          justifyContent: isFavorite ? 'space-between' : 'flex-start',
        }}>
        <TouchableOpacity onPress={onBackdropPress}>{backIcon}</TouchableOpacity>
        {isFavorite && (
          <TouchableOpacity onPress={showAllVets}>
            <Text type="caption">{t('vetDetails.other')}</Text>
          </TouchableOpacity>
        )}
      </HeaderItemsContainer>
      <VetPictureContainer>
        <PetPicture src={locationDetails?.galleryHeader || ''} showState={false} />
      </VetPictureContainer>
      <ContentWrapper>
        <ContentContainer>
          <StyledScrollView showsVerticalScrollIndicator={false}>
            <HeaderTextContainer>
              <Text type="H3" style={{ textAlign: 'center' }}>
                {locationDetails?.name}
              </Text>
            </HeaderTextContainer>
            {!isFavorite && favVetGUID != locationDetails?.guid && (
              <Button
                onPress={setFavoriteVeterinary}
                title={t('vetDetails.saveAsFavorite')}
                textType="cta_small"
                size="large"
                textColor="background0"
                color="primary"
                borderRadius={10}
                style={{ paddingBottom: 20 }}
              />
            )}
            {locationDetails && locationDetails?.phone && (
              <>
                <ContactContainer>
                  {contactInfo.map((contact, index) => (
                    <ContactIcon backgroundColor={contact.backgroundColor} onPress={contact.onPress}>
                      {contact.icon}
                      <ContactText type="caption" fontColor={contact.fontColor} fontFamily="bold" fontWeight="bold">
                        {contact.text}
                      </ContactText>
                    </ContactIcon>
                  ))}
                </ContactContainer>
              </>
            )}
            <DetailsContainer>
              <UnderlinedContainer>
                <Subtitle type="headline_a">{t('vetDetails.details')}</Subtitle>
              </UnderlinedContainer>
              <DetailsItem>
                <DetailSubSubtitle type="headline_b">{t('vetDetails.vetName') + ':'}</DetailSubSubtitle>
                <DetailsText type="paragraph_b">{locationDetails?.contactName}</DetailsText>
              </DetailsItem>
              <DetailsItem>
                <DetailSubSubtitle type="headline_b">{t('vetDetails.phoneNumber') + ':'}</DetailSubSubtitle>
                <DetailsText type="paragraph_b">{locationDetails?.phone}</DetailsText>
              </DetailsItem>
              {locationDetails?.email && (
                <DetailsItem>
                  <DetailSubSubtitle type="headline_b">{t('vetDetails.email') + ':'}</DetailSubSubtitle>
                  <DetailsText type="paragraph_b">{locationDetails?.email}</DetailsText>
                </DetailsItem>
              )}
              <DetailsItem>
                <DetailSubSubtitle type="headline_b">{t('vetDetails.address') + ':'}</DetailSubSubtitle>
                <DetailsText type="paragraph_b">{locationDetails?.address}</DetailsText>
              </DetailsItem>
            </DetailsContainer>
            <AddressContainer>
              <UnderlinedContainer>
                <Subtitle type="headline_a">{t('vetDetails.address')}</Subtitle>
              </UnderlinedContainer>
              <LocationCard
                data={{
                  name: locationDetails?.name || '',
                  guid: locationDetails?.guid || '',
                  location: {
                    name: locationDetails?.address || '',
                    latitude: locationDetails?.lat || 0,
                    longitude: locationDetails?.long || 0,
                    latitudeDelta: 0.0922,
                    longitudeDelta: 0.0421,
                    type: 'VET',
                    description: locationDetails?.description || '',
                  },
                  imageUrl: `https://maps.googleapis.com/maps/api/staticmap?center=${locationDetails?.lat},${locationDetails?.long}&zoom=20&size=500x500&markers=color:red%7Clabel:C%7C${locationDetails?.lat},${locationDetails?.long}&key=AIzaSyADFUW8ESzmVt8pEGi2iZD8KlrNAZd6LYA`,
                }}
                pinIcon={pinIcon}
                info={{
                  title: t('vetDetails.schedule') + ':',
                  description: locationDetails?.isOpenNow ? t('vetDetails.open') : t('vetDetails.close'),
                  color: locationDetails?.isOpenNow ? 'green' : 'red',
                }}
                onPress={handleOpenMapsApp}
              />
            </AddressContainer>
          </StyledScrollView>
        </ContentContainer>
      </ContentWrapper>
    </AppBackground>
  )
}

export default VetDetailsTemplate
