import React from 'react'
import { Container, ItemContainer, Label, WheelSelectorContainer } from './style'
import Text from '../../atoms/Text'
import SmoothPicker from 'react-native-smooth-picker'

export interface WheelSelectorProps {
  selected: number
  handleChange: (index: number) => void
  data: any[]
  label: string
}

const opacities = {
  0: 1,
  1: 1,
  2: 0.6,
  3: 0.3,
  4: 0.1,
}
const sizeText = {
  0: 20,
  1: 15,
  2: 10,
}

const Item = React.memo(({ selected, vertical, name }: { selected: boolean; vertical: boolean; name: string }) => {
  return (
    <ItemContainer
      style={{
        opacity: selected ? 1 : 0.4,
        width: vertical ? 60 : 'auto',
      }}>
      <Text type="H3">{name}</Text>
    </ItemContainer>
  )
})

const ItemToRender = ({ item, index }: { item: string; index: number }, indexSelected: number, vertical: boolean) => {
  const selected = index === indexSelected
  const gap = Math.abs(index - indexSelected)

  let opacity = opacities[gap as keyof typeof opacities]
  if (gap > 3) {
    opacity = opacities[4]
  }
  let fontSize = sizeText[gap as keyof typeof sizeText]
  if (gap > 1) {
    fontSize = sizeText[2]
  }

  return <Item selected={selected} vertical={vertical} name={item} />
}

const WheelSelector: React.FC<WheelSelectorProps> = ({ selected, handleChange, data, label }) => {
  return (
    <WheelSelectorContainer>
      <Container>
        <SmoothPicker
          initialScrollToIndex={selected}
          onScrollToIndexFailed={() => {}}
          keyExtractor={(_, index) => index.toString()}
          showsVerticalScrollIndicator={false}
          data={data}
          onSelected={({ item, index }) => handleChange(index)}
          renderItem={(option) => ItemToRender(option, selected, true)}
          magnet
          horizontal
          // selectOnPress
        />
        <Label type="caption">{label}</Label>
      </Container>
    </WheelSelectorContainer>
  )
}

export default WheelSelector
