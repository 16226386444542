import styled from '@emotion/native'
import Text from '../Text'
import Button from '../../molecules/button'

export const Container = styled.View(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingVertical: theme.scale(10),
}))

export const Title = styled(Text)<{ flex: number }>(({ theme, flex }) => ({
  flex,
}))

export const StyledButton = styled(Button)<{ flex: number }>(({ flex }) => ({
  flex,
}))

export const Header = styled.View(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.sizes.xs,
}))

export const TextContainer = styled.View(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}))

export const CrossContainer = styled.View({
  position: 'absolute',
  left: 0,
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})
