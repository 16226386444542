import React, { PropsWithChildren } from "react";
import { Container } from "./styles";

const FullScreenContainer: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    );
}

export default FullScreenContainer;