import React, { FC } from 'react'
import { BodyContainer, HeaderContainer, NoPermissionContainer } from './style'
import { AppBackground, Button, Text } from 'components'
import { TouchableOpacity, View } from 'react-native'
// import PawIcon from '../../../../assets/icons/paw.svg'
// import BackIcon from '../../../../assets/icons/back_icon.svg'
import { useDispatch } from 'react-redux'
import { loadSelectedPetAvatar, loadSelectedPetGuid, loadSelectedPetName } from 'store'
import { useLocation, useNavigate } from 'react-router-dom'

const NoVetchatAccess: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { petName, petGuid, petImage } = location.state as {
    petName: string
    petGuid: string
    petImage: string
  }

  console.log('NoVetchatAccess', petName, petGuid, petImage, name)

  return (
    <AppBackground isSafe>
      <BodyContainer>
        <HeaderContainer>
          <TouchableOpacity onPress={() => navigate(-1)}>
            {/* <BackIcon width={35} height={35} /> */}
          </TouchableOpacity>
        </HeaderContainer>
        <NoPermissionContainer>
          <Text type="H3" textAlign="center">
            {`${petName} no tiene acceso a VetChat`}
          </Text>
        </NoPermissionContainer>
        <View
          style={{
            width: '100%',
          }}>
          {/* <Button
            title={'Ver opciones'}
            icon={<PawIcon width={24} height={24} />}
            size="block"
            textType="cta_medium"
            borderRadius={5}
            textColor="background0"
            allowIconAndText
            color="primary"
            onPress={() => {
              dispatch(loadSelectedPetName(petName))
              dispatch(loadSelectedPetAvatar(petImage))
              dispatch(loadSelectedPetGuid(petGuid))

              navigate(-1)
            }}
          /> */}
        </View>
      </BodyContainer>
    </AppBackground>
  )
}

export default NoVetchatAccess
