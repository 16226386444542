import React, { useState } from 'react'
import {
  CamaraContainer,
  CamaraSubtitleText,
  ImageToShow,
  ItemsContainer,
  OpacityLayer,
  OverLayer,
  TitleModal,
} from './styles'
import { ReactComponent as CamaraIcon } from '../../../../assets/icons/camara_outline_icon.svg'
import { BottomSheet, Text } from 'components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as File } from '../../../../assets/icons/video_file_icon.svg'
import { ReactComponent as Camara } from '../../../../assets/icons/camara_icon.svg'
import { ItemWithImage } from '../ItemWithImage'

export enum ImageTypeSelector {
  CHIP = 'CHIP',
  PASSPORT = 'PASSPORT',
  VACCINE = 'VACCINE',
}
export interface CamaraCardProps {
  title: string
  subtitle: string
  onGalleryPress?: (file: File, preview: string, type: ImageTypeSelector) => void
  url?: string
  type: ImageTypeSelector
}

export const CamaraCard: React.FC<CamaraCardProps> = ({ title, subtitle, onGalleryPress, url, type }) => {

  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()

        // Cuando el archivo se haya leído correctamente, actualiza el estado con la URL de la imagen
        reader.onloadend = () => {
          if (reader.result) {
             onGalleryPress?.(file, reader.result.toString(), type)
          }
        }

        reader.readAsDataURL(file) // Lee el archivo como URL de datos
      }
    }
  }

  return (
    <>
      <CamaraContainer
        onPress={() => {
          inputRef.current?.click()
        }}
      >
        <ImageToShow borderRadius={10} resizeMode="cover" source={{ uri: url }}>
          <OpacityLayer />
          <OverLayer hasImage={!!url}>
            <CamaraIcon />
            <Text type="H5" fontWeight="bold" fontFamily="bold">
              {title}
            </Text>
            <CamaraSubtitleText type="caption">{subtitle}</CamaraSubtitleText>
            <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="file-input"
                ref={inputRef}
              />
          </OverLayer>
        </ImageToShow>
      </CamaraContainer>
    </>
  )
}
