import React from 'react'
import { ButtonContainer, Container, MediaContainer } from './styles'
import { Button, Text } from 'components'
import { useTranslation } from 'react-i18next'
import StepperHeader, { StepperHeaderProps } from '../../atoms/StepperHeader'
import MediaSelection from '../../organisms/MediaSelection'
import { SvgProps } from 'react-native-svg'

interface SelectMediaTemplateProps {
  onExit: () => void
  hasPermission: boolean
  media: string[]
  loadMore?: () => void
  onSave: () => void
  handleSelectImage: (media: string) => void
  selectedImage: string
  Check:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
  CrossIcon:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
  PawIcon:
    | React.FC<SvgProps>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
}

const SelectMediaTemplate: React.FC<SelectMediaTemplateProps> = ({
  onExit,
  hasPermission,
  media,
  loadMore,
  onSave,
  Check,
  CrossIcon,
  handleSelectImage,
  PawIcon,
  selectedImage,
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <StepperHeader title={t('selectMedia.title')} type="exit" onExitPress={onExit} ExitCross={CrossIcon} />
      <Text type="H5">{t('selectMedia.subtitle') + ':'}</Text>
      <MediaContainer>
        <MediaSelection
          hasPermission={hasPermission}
          media={media}
          Check={Check}
          loadMore={loadMore}
          handleSelectImage={handleSelectImage}
          selectedImage={selectedImage}
        />
      </MediaContainer>
      <ButtonContainer>
        <Button
          title={t('selectMedia.continue')}
          size="block"
          textType="cta_large"
          borderRadius={10}
          textColor="background0"
          color="primary"
          onPress={onSave}
          icon={<PawIcon width={24} height={24} />}
          allowIconAndText
        />
      </ButtonContainer>
    </Container>
  )
}

export default SelectMediaTemplate
