var _a;
import { createSlice } from '@reduxjs/toolkit';
export var BenefitType;
(function (BenefitType) {
    BenefitType["DOCUMENTATION"] = "DOCUMENTATION";
    BenefitType["VACCINATION"] = "VACCINATION";
    BenefitType["INSURANCE"] = "INSURANCE";
})(BenefitType || (BenefitType = {}));
var initialState = {
    avatar: '',
    detailDataProgress: 0,
    fatherBreed: { guid: '', name: '' },
    guid: '',
    hasInsurance: false,
    hasAllVaccines: false,
    hasDocument: false,
    isSterilised: false,
    microchip: '',
    motherBreed: { guid: '', name: '' },
    name: '',
    petBreed: { guid: '', name: '' },
    petType: { guid: '', name: '' },
    qrCode: '',
    weight: 0,
    benefits: [BenefitType.DOCUMENTATION, BenefitType.VACCINATION],
    hasLoaded: false,
    allowPkpass: false,
    locatorActivationCode: '',
    character: '',
    hairColor: '',
    hairLength: '',
    ageByMonths: 0,
};
export var selectedPetSlice = createSlice({
    name: 'selectedPet',
    initialState: initialState,
    reducers: {
        loadSelectedPet: function (state, action) {
            state.name = action.payload.name;
            state.avatar = action.payload.avatar;
            state.guid = action.payload.guid;
            state.ageByMonths = action.payload.ageByMonths;
            state.birthday = action.payload.birthday;
            state.detailDataProgress = action.payload.detailDataProgress;
            state.hasInsurance = action.payload.hasInsurance;
            state.hasAllVaccines = action.payload.hasAllVaccines;
            state.hasDocument = action.payload.hasDocument;
            state.isSterilised = action.payload.isSterilised;
            state.microchip = action.payload.microchip;
            state.petBreed = action.payload.petBreed;
            state.fatherBreed = action.payload.fatherBreed;
            state.motherBreed = action.payload.motherBreed;
            state.petType = action.payload.petType;
            state.qrCode = action.payload.qrCode;
            state.weight = action.payload.weight;
            state.character = action.payload.character;
            state.hairColor = action.payload.hairColor;
            state.hairLength = action.payload.hairLength;
            state.locatorActivationCode = action.payload.locatorActivationCode;
            state.allowPkpass = action.payload.allowPkpass;
        },
        loadSelectedPetName: function (state, action) {
            state.name = action.payload;
        },
        loadSelectedPetAvatar: function (state, action) {
            state.avatar = action.payload;
        },
        loadSelectedPetGuid: function (state, action) {
            state.guid = action.payload;
        },
        setPetLoadingStatus: function (state, action) {
            state.hasLoaded = action.payload;
        },
        deleteSelectedPet: function () { return initialState; },
    },
});
export var loadSelectedPet = (_a = selectedPetSlice.actions, _a.loadSelectedPet), loadSelectedPetName = _a.loadSelectedPetName, loadSelectedPetAvatar = _a.loadSelectedPetAvatar, loadSelectedPetGuid = _a.loadSelectedPetGuid, setPetLoadingStatus = _a.setPetLoadingStatus, deleteSelectedPet = _a.deleteSelectedPet;
