var _a;
import { createSlice } from '@reduxjs/toolkit';
export var GenderType;
(function (GenderType) {
    GenderType["MALE"] = "MALE";
    GenderType["FEMALE"] = "FEMAL";
})(GenderType || (GenderType = {}));
export var BreedType;
(function (BreedType) {
    BreedType["PUREBREED"] = "PUREBREED";
    BreedType["MIXED"] = "MIXED";
    BreedType["UNKNOWN"] = "UNKNOWN";
})(BreedType || (BreedType = {}));
export var PassType;
(function (PassType) {
    PassType["PETPASS_S"] = "PETPASS_S";
    PassType["PETPASS_M"] = "PETPASS_M";
    PassType["PETPASS_L"] = "PETPASS_L";
})(PassType || (PassType = {}));
var initialState = {
    name: 'Max',
    // photo: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Golden_Retriever_9-year_old.jpg',
    photo: '',
    guid: '',
    specie: {},
    gender: GenderType.MALE,
    isSterilized: false,
    breedType: BreedType.PUREBREED,
    petPass: PassType.PETPASS_S,
    // Complete profile
    // Phase 1
    address: '',
    locality: '',
    province: '',
    zipCode: '',
    documentId: '',
    country: '',
    // Phase 2
    petIsHealthy: false,
    hasVaccines: false,
    petOrigin: '',
    // Phase 3
    petRole: '',
    qrUri: '',
    hasInsurance: false,
};
export var petDataSlice = createSlice({
    name: 'petData',
    initialState: initialState,
    reducers: {
        loadName: function (state, action) {
            state.name = action.payload;
        },
        loadPhoto: function (state, action) {
            state.photo = action.payload;
        },
        resetPetState: function (state) {
            Object.assign(state, initialState);
        },
        addSpecie: function (state, action) {
            state.specie = action.payload;
        },
        addGender: function (state, action) {
            state.gender = action.payload;
        },
        addSterilized: function (state, action) {
            state.isSterilized = action.payload;
        },
        addBreedType: function (state, action) {
            state.breedType = action.payload;
        },
        addDadBreed: function (state, action) {
            state.dadBreed = action.payload;
        },
        addMomBreed: function (state, action) {
            state.momBreed = action.payload;
        },
        addPetBreed: function (state, action) {
            state.petBreed = action.payload;
        },
        addBirthday: function (state, action) {
            state.birthday = action.payload;
        },
        addAge: function (state, action) {
            state.age = action.payload;
        },
        setGuid: function (state, action) {
            state.guid = action.payload;
        },
        addLocationData: function (state, action) {
            state.address = action.payload.address;
            state.locality = action.payload.locality;
            state.province = action.payload.province;
            state.zipCode = action.payload.zipCode;
            state.documentId = action.payload.documentId;
            state.country = action.payload.country;
        },
        addPetHealthData: function (state, _a) {
            var _b = _a.payload, hasVaccines = _b.hasVaccines, petIsHealthy = _b.petIsHealthy, petOrigin = _b.petOrigin;
            state.petOrigin = petOrigin;
            state.petIsHealthy = petIsHealthy;
            state.hasVaccines = hasVaccines;
        },
        addPetRole: function (state, _a) {
            var payload = _a.payload;
            state.petRole = payload;
        },
        addPetClinic: function (state, _a) {
            var _b = _a.payload, vetGUID = _b.vetGUID, vetEmail = _b.vetEmail, vetName = _b.vetName, vetPhone = _b.vetPhone;
            state.vetGUID = vetGUID;
            state.vetEmail = vetEmail;
            state.vetName = vetName;
            state.vetPhone = vetPhone;
        },
        loadTracker: function (state, _a) {
            var payload = _a.payload;
            state.tracker = payload;
        },
        loadQrUri: function (state, _a) {
            var payload = _a.payload;
            state.qrUri = payload;
        },
        loadPetDetails: function (state, _a) {
            var payload = _a.payload;
            state.name = payload.name;
            state.photo = payload.avatar;
            state.progress = payload.detailDataProgress;
            state.specie = {
                guid: payload.petTypeGUID,
                name: payload.petTypeName || '',
                numberOfBreeds: 1,
                avatar: '',
            };
            state.petBreed = {
                guid: payload.petBreedGUID || '',
                name: payload.petBreedName,
            };
            state.momBreed = {
                guid: payload.motherBreedGUID || '',
                name: payload.motherBreedName || '',
            };
            state.dadBreed = {
                guid: payload.fatherBreedGUID || '',
                name: payload.fatherBreedName || '',
            };
            state.birthday = payload.birthday;
            state.age = {
                years: 0,
                months: payload.ageByMonths || 0,
            };
            state.hasInsurance = payload.hasInsurance;
            state.isSterilized = payload.isSterilised;
            state.tracker = payload.microchip;
            state.qrUri = payload.qrCode;
        },
    },
});
export var loadName = (_a = petDataSlice.actions, _a.loadName), loadPhoto = _a.loadPhoto, resetPetState = _a.resetPetState, addSpecie = _a.addSpecie, addGender = _a.addGender, addSterilized = _a.addSterilized, addBreedType = _a.addBreedType, addDadBreed = _a.addDadBreed, addMomBreed = _a.addMomBreed, addPetBreed = _a.addPetBreed, addAge = _a.addAge, addBirthday = _a.addBirthday, setGuid = _a.setGuid, addLocationData = _a.addLocationData, addPetHealthData = _a.addPetHealthData, addPetRole = _a.addPetRole, loadPetDetails = _a.loadPetDetails, addPetClinic = _a.addPetClinic, loadTracker = _a.loadTracker, loadQrUri = _a.loadQrUri;
