import styled from '@emotion/native'
import { Text } from 'components'
import { View } from 'react-native'

interface SeparatorProps {
  size: number
}

export const ContentWrapper = styled.View(({ theme }) => ({
  width: '100%',
  flex: 1,
  paddingRight: theme.sizes.md,
  paddingLeft: theme.sizes.md,
  flexDirection: 'column',
}))

export const LocationContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.sizes.xxs,
}))

export const HeaderTextContainer = styled.View(({ theme }) => ({
  paddingBottom: theme.sizes.sm,
  alignItems: 'center',
}))

export const DescriptionContainer = styled.View(({ theme }) => ({
  paddingBottom: theme.sizes.xs,
}))

export const ReadMore = styled(Text)(({ theme }) => ({
  color: 'blue',
}))

export const Separator = styled.View<SeparatorProps>(({ size }) => ({
  height: size,
  width: size,
}))

export const CarrouselTitle = styled(Text)(({ theme }) => ({
  paddingBottom: theme.sizes.xs,
}))

export const BackIconContainer = styled(View)(({ theme }) => ({
  position: 'absolute',
  left: theme.sizes.md,
  top: theme.sizes.xl,
  zIndex: 1000,
}))

export const ContentContainer = styled.View(({ theme }) => ({
  margin: 0,
  height: '100%',
  justifyContent: 'space-between',
}))

export const StyledScrollView = styled.ScrollView(({ theme }) => ({
  marginBottom: theme.sizes.sm,
}))

export const ContactContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.sizes.xs,
  justifyContent: 'center',
}))

export const ContactIcon = styled.TouchableOpacity<{ backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor || theme.colors.primary,
  padding: theme.sizes.sm,
  flexDirection: 'row',
  borderRadius: 20,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.sizes.xs,
}))

export const ContactText = styled(Text)<{ fontColor?: string }>(({ theme, fontColor }) => ({
  color: fontColor ?? theme.colors.background0,
}))

export const VetPictureContainer = styled.View(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const HeaderItemsContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.sizes.xs,
}))

export const DetailsContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.sizes.xs,
  paddingTop: theme.sizes.sm,
}))

export const UnderlinedContainer = styled.View(({ theme }) => ({
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.background1,
  paddingBottom: theme.sizes.xs,
}))

export const Subtitle = styled(Text)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.sizes.sm,
}))

export const DetailSubSubtitle = styled(Text)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.sizes.xs,
}))

export const DetailsItem = styled.View(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxs,
  flex: 1,
}))

export const DetailsText = styled(Text)(({ theme }) => ({
  flexShrink: 1,
  fontSize: theme.sizes.xs,
}))

export const AddressContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.sizes.xs,
  paddingTop: theme.sizes.sm,
  paddingBottom: theme.sizes.xs,
}))
