import styled from '@emotion/native'
import { View } from 'react-native'

export const MapContainer = styled(View)(() => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: 700,
}))

export const ContentContainer = styled(View)(({ theme }) => ({
  padding: theme.sizes.sm,
  paddingTop: 0,
  gap: theme.sizes.xs,
  flex: 1,
}))

export const FormContainer = styled(View)(({ theme }) => ({
  width: '100%',
  gap: theme.sizes.xs,
}))
