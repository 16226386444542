import React, { useMemo } from 'react'
import { Text, Input, Button, Drop } from 'components'
import { FormsContainer, InputContainer, MainWrapper, Title, UpperContainer } from './styles'
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleProp,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { FormikValues, FormikErrors, FormikHandlers } from 'formik'
import { SelectableItemProps } from '../../atoms/SelectableItem'

export interface DropdownData {
  iconDropdown: React.ReactNode
  placeholder: string
  data: SelectableItemProps[]
  active: number
  onPress?: (_: SelectableItemProps, __: number | undefined) => void
  selectedItem?: number
  iconButton?: React.ReactNode
}

interface SignupInput {
  value: string
  onChangeText: FormikHandlers['handleChange']
  onBlur: FormikHandlers['handleBlur']
  placeholder: string
  error: string | undefined
  type?: 'drop' | 'input'
  dropdownData?: DropdownData
  secureTextEntry: boolean
  right?: React.ReactElement
}

export interface SignUpTemplateProps {
  handleSubmit: FormikHandlers['handleSubmit']
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
  buttonLabel?: string
  title: string
  subtitle: string
  inputs: SignupInput[]
  icon?: React.ReactNode
  allowIconAndText?: boolean
}
const SignUpTemplate: React.FC<SignUpTemplateProps> = ({
  handleSubmit,
  style,
  children,
  buttonLabel,
  title,
  subtitle,
  inputs,
  icon,
  allowIconAndText = false,
}) => {
  const { t } = useTranslation()

  const Wrapper = useMemo(() => (Platform.OS === 'android' ? View : KeyboardAvoidingView), [Platform.OS])

  return (
    <Wrapper behavior="padding" style={{ flex: 1 }}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <MainWrapper style={style} isSafe>
          <ScrollView>
            <UpperContainer>
              <Title type="H2" fontWeight="semibold" color={'foreground0'} textAlign="center">
                {title}
              </Title>
              <Title type="paragraph_b" fontWeight="normal" color={'foreground0'} textAlign="center">
                {subtitle}
              </Title>
              <FormsContainer>
                {inputs &&
                  inputs.map((input, index) => {
                    if (input?.type === 'drop' && input?.dropdownData) {
                      return (
                        <Drop
                          placeholder={input.placeholder ?? 'Select'}
                          icon={input?.dropdownData.iconDropdown}
                          data={input.dropdownData.data}
                          active={input.dropdownData.active}
                          buttonIcon={input.dropdownData.iconButton}
                          allowIconAndText={allowIconAndText}
                          onPress={input.dropdownData.onPress}
                          selectedItem={input.dropdownData.selectedItem}

                        />
                      )
                    }
                    return (
                      <InputContainer key={index}>
                        <Input
                          inputProps={{
                            value: input.value,
                            onChangeText: input.onChangeText,
                            onBlur: input.onBlur,
                            placeholder: input.placeholder,
                            secureTextEntry: input.secureTextEntry,
                          }}
                          right={input.right}
                        />
                        {input.error && (
                          <Text type="small" color="error">
                            {input.error.toString()}
                          </Text>
                        )}
                      </InputContainer>
                    )
                  })}
              </FormsContainer>
              <Button
                title={buttonLabel || t('login.submit')}
                size="block"
                textType="cta_medium"
                borderRadius={5}
                textColor="background0"
                color="primary"
                onPress={handleSubmit}
                icon={icon}
                allowIconAndText={allowIconAndText}
              />
              {children}
            </UpperContainer>
          </ScrollView>
        </MainWrapper>
      </TouchableWithoutFeedback>
    </Wrapper>
  )
}

export default SignUpTemplate
