import { useIsFocused } from '@react-navigation/native'
import { useVetchat } from 'business-logic'
import { AppBackground, IResource, Text } from 'components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Image, ListRenderItem, Platform, TouchableOpacity, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { setLoading, useAppSelector } from 'store'
import VetChatAndroidBridge from './professionaListAndroid'
import ProfessionalListIOSBridge from './professionalListIOS'
import { CardContainer, CardInfoContainer, Container, NoPetContainer, WebImageResource } from './style'
import { getVetChatUserCredentialsApi } from 'apis'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/native'

const VetContainer = styled.View(({ theme }) => ({
  width: '100%',
  height: '100%',
}))

const VetChat: React.FC = () => {
  const dispatch = useDispatch()
  const { isLoading } = useAppSelector((state) => state.loading)
  
  const { guid } = useAppSelector((state) => state.selectedPet)
  const navigate = useNavigate()

  //   const handlePressedPet = (resource: IResource) => {
  //     setSelectedPet(resource)
  //     console.debug(resource)
  //     console.log('PET', resource)
  //   }

  //   const navigateToNoAccess = () => {
  //     setSelectedPet(null)
  // TODO - navegar aqui si la mascota no tiene acceso - hacer un endpoint para eso
  //     navigate('/services/vetChat/NoVetChatAccess', {
  //       state: {
  //         petGuid: selectedPet?.id || '',
  //         petName: selectedPet?.title || '',
  //         petImage: selectedPet?.imageUrl || '',
  //       },
  //     })
  //   }

  //   const onClose = () => {
  //     setSelectedPet(null)
  //   }

  useEffect(() => {
    const initializaVetChat = async () => {
      console.log('INITIALIZING VETCHAT')
      const response = await getVetChatUserCredentialsApi({
        petPassPetGUID: guid || '',
      })
      console.log('RESPONSE', response)

      const w = window as any

      if (w.meetingdoctors) {
        w.meetingdoctors.initialize({
          apiKey: response.data.meetingKey,
          jwt: response.data.jwt,
          displayMode: 'contained',
          language: 'es',
          defaultOpen: false,
          containerId: 'meetingdoctors-container',
        })
      }
    }
    if (guid) initializaVetChat()
  }, [guid])

  const meettingDoctorsProfessionalsListWeb = guid && (
    <>
      <VetContainer id="meetingdoctors-container" />
    </>
  )

  return (
    <AppBackground isSafe>
      <>
        <Container>{meettingDoctorsProfessionalsListWeb}</Container>
      </>
    </AppBackground>
  )
}

export default VetChat
