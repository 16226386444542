import React, { useState } from 'react'
import { Badge, Container, NotificationText } from './styles'
import Text from '../Text'

interface NotificationBellProps {
  count: number
  bellIcon: React.ReactNode
  onPress: () => void
}

const NotificationBell: React.FC<NotificationBellProps> = ({ count, bellIcon, onPress }) => {
  return (
    <Container onPress={onPress}>
      {bellIcon}
      {count > 0 && (
        <Badge maxReached={count > 9 ? true : false}>
          <NotificationText type="small" color="background0">
            {count > 9 ? '9+' : count}
          </NotificationText>
        </Badge>
      )}
    </Container>
  )
}

export default NotificationBell
