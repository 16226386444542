import styled from '@emotion/native'
import { Text } from 'components'
import { TouchableOpacity, View } from 'react-native'

export const OtPInputSection = styled(View)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginVertical: theme.sizes.lg,
}))

export const HiddenTextInput = styled.TextInput(({ theme }) => ({
  position: 'absolute',
  width: 1,
  height: 1,
  opacity: 0,
}))

export const OtpInputContainer = styled(TouchableOpacity)(({ theme }) => ({
  width: '70%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const OtpInput = styled(View)(({ theme }) => ({
  borderColor: theme.colors.background1,
  minWidth: '15%',
  borderWidth: 1,
  borderRadius: 5,
  padding: theme.sizes.sm,
}))

export const OtpInputFocused = styled(OtpInput)(({ theme }) => ({
  borderColor: theme.colors.primary,
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ErrorText = styled(Text)(({ theme }) => ({
  color: theme.colors.error,
  marginTop: theme.sizes.sm,
  alignSelf: 'flex-start',
}))
