import styled from '@emotion/native'

export const Container = styled.TouchableOpacity(({ theme }) => ({
  borderRadius: 50,
  borderWidth: 1,
  borderColor: theme.colors.background1,
  backgroundColor: theme.colors.background2,
  flexDirection: 'row',
  width: '100%',
}))

export const Button = styled.View<{ isActive?: boolean }>(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.colors.background0 : theme.colors.background2,
  flex: 1,
  borderRadius: 50,
  alignItems: 'center',
  justifyContent: 'center',
  paddingVertical: theme.sizes.xs,
}))
