var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createSosApi, getSosDetail, getSosEvents, getSosHistory, resolvedSosApi, sendSosLocation, } from 'apis';
import { setLoading, useAppSelector } from 'store';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Toast from 'react-native-toast-message';
export var useSos = function (_a) {
    var onSubmit = _a.onSubmit;
    var guid = useAppSelector(function (state) { return state.selectedPet; }).guid;
    var _b = useState(null), sosEvents = _b[0], setSosEvents = _b[1];
    var _c = useState(''), error = _c[0], setError = _c[1];
    var dispatch = useDispatch();
    var validationSchema = yup.object().shape({
        description: yup.string().optional(),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            description: '',
            latitude: 0,
            longitude: 0,
        },
        validationSchema: validationSchema,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                onSubmit && onSubmit();
                return [2 /*return*/];
            });
        }); },
    }), []);
    var getDetail = function () { return __awaiter(void 0, void 0, void 0, function () {
        var details, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getSosDetail({
                            petGuid: guid,
                        })];
                case 1:
                    details = _a.sent();
                    return [2 /*return*/, {
                            success: details.success,
                            data: details.data,
                        }];
                case 2:
                    e_1 = _a.sent();
                    return [2 /*return*/, {
                            success: false,
                        }];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getHistory = function () { return __awaiter(void 0, void 0, void 0, function () {
        var history_1, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    dispatch(setLoading(true));
                    return [4 /*yield*/, getSosHistory({
                            petGuid: guid,
                        })];
                case 1:
                    history_1 = _a.sent();
                    return [2 /*return*/, {
                            success: history_1.success,
                            data: history_1.data,
                        }];
                case 2:
                    e_2 = _a.sent();
                    return [2 /*return*/, {
                            success: false,
                        }];
                case 3:
                    dispatch(setLoading(false));
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var sendLocation = function (_a) {
        var lat = _a.lat, lon = _a.lon;
        return __awaiter(void 0, void 0, void 0, function () {
            var location_1, e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, sendSosLocation({
                                guid: guid,
                                lat: lat,
                                lon: lon,
                                platform: 'APP',
                            })];
                    case 1:
                        location_1 = _b.sent();
                        return [2 /*return*/, {
                                success: location_1.success,
                            }];
                    case 2:
                        e_3 = _b.sent();
                        return [2 /*return*/, {
                                success: false,
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    var createSos = function (_a) {
        var lat = _a.latitude, lon = _a.longitude, description = _a.description;
        return __awaiter(void 0, void 0, void 0, function () {
            var res, e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        dispatch(setLoading(true));
                        return [4 /*yield*/, createSosApi({
                                guid: guid,
                                latitude: lat,
                                longitude: lon,
                                address: '',
                                description: description,
                            })];
                    case 1:
                        res = _b.sent();
                        if (res.success) {
                            Toast.show({
                                text1: res.message || 'Éxito',
                                text2: res.messageDescription || 'Éxito',
                                type: res.messageStatus || 'success',
                            });
                        }
                        else {
                            Toast.show({
                                text1: res.message || 'Error',
                                text2: res.messageDescription || 'Error',
                                type: res.messageStatus || 'error',
                            });
                        }
                        return [2 /*return*/, {
                                success: res.success,
                            }];
                    case 2:
                        e_4 = _b.sent();
                        Toast.show({
                            text1: 'Error',
                            text2: e_4.message || 'Error',
                            type: 'error',
                        });
                        return [2 /*return*/, {
                                success: false,
                            }];
                    case 3:
                        dispatch(setLoading(false));
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var resolveSos = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    dispatch(setLoading(true));
                    return [4 /*yield*/, resolvedSosApi({
                            guid: guid,
                        })];
                case 1:
                    res = _a.sent();
                    if (res.success) {
                        Toast.show({
                            text1: res.message || 'Éxito',
                            text2: res.messageDescription || 'Éxito',
                            type: res.messageStatus || 'success',
                        });
                    }
                    else {
                        Toast.show({
                            text1: res.message || 'Error',
                            text2: res.messageDescription || 'Error',
                            type: res.messageStatus || 'error',
                        });
                    }
                    return [2 /*return*/, {
                            success: res.success,
                        }];
                case 2:
                    e_5 = _a.sent();
                    Toast.show({
                        text1: 'Error',
                        text2: e_5.message || 'Error',
                        type: 'error',
                    });
                    return [2 /*return*/, {
                            success: false,
                        }];
                case 3:
                    dispatch(setLoading(false));
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var getEvents = function (sosGuid) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(setLoading(true));
                    return [4 /*yield*/, getSosEvents({
                            sosGuid: sosGuid,
                        })
                            .then(function (res) {
                            if (res.success) {
                                setSosEvents(res.data);
                            }
                        })
                            .catch(function (e) {
                            setError(e.message);
                        })
                            .finally(function () {
                            dispatch(setLoading(false));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return __assign({ getDetail: getDetail, getHistory: getHistory, sendLocation: sendLocation, createSos: createSos, resolveSos: resolveSos, getEvents: getEvents, sosEvents: sosEvents }, values);
};
