var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    step: 1,
    showHeader: true,
    totalSteps: 6,
};
export var petAdditionSlice = createSlice({
    name: 'petAddition',
    initialState: initialState,
    reducers: {
        changeShowHeader: function (state, action) {
            state.showHeader = action.payload;
        },
        changePetAdditionStep: function (state, action) {
            state.step = Number(action.payload);
        },
        onPetAdditionBack: function (state) {
            var newStep = state.step - 1;
            if (newStep >= 1) {
                state.step = newStep;
            }
        },
        changeTotalSteps: function (state, action) {
            state.totalSteps = Number(action.payload);
        },
    },
});
export var changePetAdditionStep = (_a = petAdditionSlice.actions, _a.changePetAdditionStep), onPetAdditionBack = _a.onPetAdditionBack, changeShowHeader = _a.changeShowHeader, changeTotalSteps = _a.changeTotalSteps;
