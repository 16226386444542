import styled from '@emotion/native'

export const Container = styled.View(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const HeaderContainer = styled.View(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const BodyContainer = styled.View(({ theme }) => ({
  padding: theme.sizes.xs,
  display: 'flex',
  flexDirection: 'column',
  flex: 0.8,
}))

export const MediaContainer = styled.View(({}) => ({
  flex: 0.2,
  aspectRatio: 1,
  maxWidth: '100%',
  maxHeight: '100%',
}))

export const MediaPreview = styled.Image(({ theme }) => ({
  width: '100%',
  height: '100%',
  resizeMode: 'contain',
  borderRadius: theme.sizes.xs,
}))
