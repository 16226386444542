import styled from '@emotion/native'
import Text from '../../atoms/Text'
import { sizeItem } from '.'

export const Container = styled.View({
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})

export const ItemContainer = styled.TouchableOpacity<{ active: boolean; size: sizeItem }>(({ theme, active, size }) => {
  const sizeMap = {
    small: theme.sizes.xl + theme.sizes.xxs,
    medium: theme.sizes.xxl + theme.sizes.sm,
    large: theme.sizes.xxxl,
  }
  return {
    borderWidth: active ? 2 : 0,
    borderColor: '#163B6C',
    padding: theme.sizes.xxxs,
    marginHorizontal: theme.sizes.xxs,
    borderRadius: theme.sizes.xxl,
    height: sizeMap[size],
    width: sizeMap[size],
  }
})

export const ItemView = styled.View<{ color: string; isActive: boolean }>(({ theme, color, isActive }) => ({
  backgroundColor: isActive ? '#C3E4F2' : color ? color : theme.colors.background0,
  width: '100%',
  height: '100%',
  borderRadius: theme.sizes.xxl,
  alignSelf: 'flex-start',
  justifyContent: 'center',
  alignItems: 'center',
  borderColor: 'rgba(50, 50, 50, 0.3)',
  borderWidth: ['white', '#ffffff'].includes(color) ? 1 : 0,
}))

export const ItemText = styled(Text)(() => ({}))
