import React, { ReactElement, useRef } from 'react'
import { TextInput, ViewStyle, TextInputProps, KeyboardTypeOptions } from 'react-native'
import { StyledTextInput, Container } from './style'
import { TextSizes } from 'themes'
import { useTheme } from '@emotion/react'

export interface InputProps {
  icon?: React.JSX.Element
  style?: ViewStyle
  variant?: 'filled' | 'standard'
  textType?: keyof TextSizes
  inputProps?: TextInputProps
  right?: ReactElement
}

const Input: React.FC<InputProps> = ({ style, inputProps, right, variant = 'filled' }) => {
  const theme = useTheme()
  const inputRef = useRef<TextInput>(null)

  return (
    <Container style={style} variant={variant}>
      <StyledTextInput
        ref={inputRef}
        {...inputProps}
        placeholderTextColor="grey"
        selectionColor={theme.colors.primary}
      />
      {right}
    </Container>
  )
}

export default Input
