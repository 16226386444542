import React, { useMemo } from 'react'
import { Text, Input, Button } from 'components'
import {
  FormsContainer,
  InputContainer,
  MainWrapper,
  ImageContainer,
  Title,
  ButtonContainer,
  SmallCaption,
  UpperContainer,
  SubscribeButton,
  ScrollWrapper,
} from './styles'
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { FormikValues, FormikErrors, FormikHandlers } from 'formik'

export interface LoginTemplateProps {
  values: FormikValues
  errors: FormikErrors<FormikValues>
  handleChange: FormikHandlers['handleChange']
  handleBlur: FormikHandlers['handleBlur']
  handleSubmit: FormikHandlers['handleSubmit']
  onForgotPasswordPress: () => void
  style?: StyleProp<ViewStyle>
  image?: React.ReactNode
  children?: React.ReactNode
  buttonLabel?: string
  handleSubscribe: () => void
  icon?: React.ReactNode
  allowIconAndText?: boolean
  formStyle: ViewStyle
  eyeIcon?: React.ReactNode
  blockedEyeIcon?: React.ReactNode
}
const LoginTemplate: React.FC<LoginTemplateProps> = ({
  image,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  onForgotPasswordPress,
  errors,
  style,
  children,
  buttonLabel,
  handleSubscribe,
  icon,
  formStyle,
  allowIconAndText = false,
  eyeIcon,
  blockedEyeIcon,
}) => {
  const { t } = useTranslation()

  const Wrapper = useMemo(() => (Platform.OS === 'android' ? View : KeyboardAvoidingView), [Platform.OS])

  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <Wrapper behavior="padding" style={{ flex: 1 }}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <MainWrapper style={style} isSafe>
          <ScrollWrapper>
            <UpperContainer>
              {image && <ImageContainer>{image}</ImageContainer>}
              <FormsContainer style={formStyle}>
                <InputContainer>
                  <Input
                    inputProps={{
                      value: values.email,
                      onChangeText: handleChange('email'),
                      onBlur: handleBlur('email'),
                      placeholder: t('login.email'),
                    }}
                  />
                  {errors.email && (
                    <Text type="small" color="error">
                      {errors.email.toString()}
                    </Text>
                  )}
                </InputContainer>
                <InputContainer>
                  <Input
                    inputProps={{
                      value: values.password,
                      onChangeText: handleChange('password'),
                      onBlur: handleBlur('password'),
                      placeholder: t('login.password'),
                      secureTextEntry: !showPassword,
                    }}
                    right={<TouchableOpacity
                      onPress={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? blockedEyeIcon : eyeIcon }
                    </TouchableOpacity>}
                  />
                  {errors.password && (
                    <Text type="small" color="error">
                      {errors.password.toString()}
                    </Text>
                  )}
                </InputContainer>
                <SubscribeButton onPress={onForgotPasswordPress}>
                  <SmallCaption
                    type="small"
                    fontWeight="bold"
                    color={'foreground2'}
                    textAlign="center"
                    flex={0}
                    onPress={() => onForgotPasswordPress && onForgotPasswordPress()}>
                    {t('login.forgotPassword')}
                  </SmallCaption>
                </SubscribeButton>
              </FormsContainer>
              <Button
                title={buttonLabel || t('login.submit')}
                size="block"
                textType="cta_medium"
                borderRadius={5}
                textColor="background0"
                color="primary"
                onPress={handleSubmit}
                icon={icon}
                allowIconAndText={allowIconAndText}
              />
              {children}
            </UpperContainer>
            <ButtonContainer>
              <SmallCaption type="caption" fontWeight="bold" color={'foreground0'} textAlign="center" flex={0}>
                {t('login.createAccount')}
              </SmallCaption>
              <SubscribeButton onPress={handleSubscribe}>
                <SmallCaption type="caption" fontWeight="bold" color={'foreground2'} textAlign="center" flex={0}>
                  {t('login.subscribe')}
                </SmallCaption>
              </SubscribeButton>
            </ButtonContainer>
          </ScrollWrapper>
        </MainWrapper>
      </TouchableWithoutFeedback>
    </Wrapper>
  )
}

export default LoginTemplate
