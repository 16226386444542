import { useLocation, useSos } from 'business-logic'
import { AppBackground, HeaderWithBack, LocationCard, Switch, Text } from 'components'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
// import Map, { Callout, Marker } from '@react-google-maps/api'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api'
import { useAppSelector } from 'store'
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg'
import { ReactComponent as MapPin } from '../../../../assets/icons/pin_filled.svg'
import { Container, MapContainer, PlaceholderContainer, PlacesItemsContainer } from './style'
import { useNavigate, useLocation as useRNDLocation } from 'react-router-dom'
import { PETPASS_sos_eventType } from 'apis'

const containerStyle = {
  width: '100%',
  height: '500px',
}

interface MapCoordinates {
  lat: number
  lng: number
}

interface Event {
  guid: string
  type: PETPASS_sos_eventType
  lat: number
  lon: number
  address: string
  comment: string
  typeLabel: string
}

export const SosMap: FC = () => {
  const navigate = useNavigate()
  const locationRN = useRNDLocation()
  const { name } = useAppSelector((state) => state.selectedPet)
  const { sosGuid } = locationRN.state as { sosGuid: string }
  const { isLoading } = useAppSelector((state) => state.loading)

  const [currentLocation, setCurrentLocation] = useState<MapCoordinates>()
  const [isMapScreen, setIsMapScreen] = useState(true)
  const [selectedLocation, setSelectedLocation] = useState<{
    latitude: number
    longitude: number
  } | null>(null)
  const [selectedMarker, setSelectedMarker] = useState<Event | null>()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD4iabOIt-PhhagyNLvnBxkKMH8_bXgViw',
  })

  const [googleMap, setMap] = useState<google.maps.Map>()

  const onLocateSuccess = async (lat: number, lon: number) => {
    handleZoomToLocation(lat, lon)
  }

  const onLocateError = (error: any) => {
    console.debug(error.code, error.message)
  }
  const locate = useLocation({ onSuccess: onLocateSuccess, onError: onLocateError })

  const { getEvents, sosEvents } = useSos({})

  useEffect(() => {
    locate(true)
    getEvents(sosGuid)
  }, [])

  //   const mapRef = useRef<Map>(null)

  const handleZoomToLocation = (lat: number, lon: number) => {
    // mapRef.current?.animateToRegion(
    //   {
    //     latitude: lat,
    //     longitude: lon,
    //     latitudeDelta: 0.1, // Small number for zooming in
    //     longitudeDelta: 0.1, // Small number for zooming in
    //   },
    //   1000,
    // ) // Duration of animation, in milliseconds
  }

  const onUnmount = useCallback(function callback() {
    setMap(undefined)
  }, [])

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          // seta(JSON.stringify(result.state))
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const coordinates = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }
              const bounds = new window.google.maps.LatLngBounds(coordinates)
              map.fitBounds(bounds)
              setCurrentLocation(coordinates)
            },
            (error) => {
              console.error('Error getting user location:', error)
              const bounds = new window.google.maps.LatLngBounds({
                lat: -3.745,
                lng: -38.523,
              })
              map.fitBounds(bounds)
            },
          )
        } else if (result.state === 'denied') {
          console.error('User denied geolocation permission')
          const bounds = new window.google.maps.LatLngBounds({
            lat: -3.745,
            lng: -38.523,
          })
          map.fitBounds(bounds)
        }
      })
    } else {
      const bounds = new window.google.maps.LatLngBounds({
        lat: -3.745,
        lng: -38.523,
      })
      map.fitBounds(bounds)
    }

    setMap(map)
  }, [])

  const onBackPress = () => {
    navigate(-1)
  }

  const handleMapLayout = () => {
    if (selectedLocation) {
      handleZoomToLocation(selectedLocation.latitude, selectedLocation.longitude)
      setSelectedLocation(null)
    } else {
      locate(true)
    }
  }

  const handleCardPress = (location: { latitude: number; longitude: number }) => {
    setSelectedLocation(location)
    setIsMapScreen(true)
  }

  const getRegionForCoordinates = async (region: MapCoordinates) => {
    const apiKey = 'AIzaSyCydlBSzE5sbL00JEoEf4BBviSduFu5rdk'
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${region.lat},${region.lng}&key=${apiKey}`,
    )
    const data = await response.json()

    let address = ''
    const length = data.results[0].address_components.length - 1
    data.results[0].address_components.map((name: any, index: number) => {
      if (name.long_name) {
        address += name.long_name + (index === length ? '' : ', ')
      }
    })
  }

  const handleRegionChangeComplete = () => {
    const newCenter = googleMap?.getCenter()
    if (newCenter) {
      const innerMarkerCoords = {
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      }
      getRegionForCoordinates(innerMarkerCoords)
    }
  }

  return (
    <AppBackground isSafe>
      <Container>
        <HeaderWithBack
          title={`${name} se ha avistado en estos lugares`}
          backPress={onBackPress}
          backIcon={<BackIcon />}
        />
        <Switch isFirst={isMapScreen} option1={'Mapa'} option2={'Lista'} onPress={() => setIsMapScreen(!isMapScreen)} />
        {isMapScreen ? (
          isLoaded ? (
            <MapContainer id="map-container">
              <GoogleMap
                onDragEnd={handleRegionChangeComplete}
                options={{ streetViewControl: false }}
                mapContainerStyle={containerStyle}
                zoom={14}
                onLoad={onLoad}
                onUnmount={onUnmount}
                id="map">
                {currentLocation && (
                  <Marker
                    position={{ lat: currentLocation.lat, lng: currentLocation.lng }}
                    icon={{
                      url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Blue marker icon
                    }}
                  />
                )}
                {sosEvents?.events.map((item, index) => {
                  alert(JSON.stringify(item))
                  return (
                    <Marker 
                      key={`location-marker-${index}`} 
                      position={{ lat: item.lat, lng: item.lon }}
                      onClick={() => setSelectedMarker({
                        guid: item.guid,
                        type: item.type,
                        lat: item.lat,
                        lon: item.lon,
                        address: item.address,
                        comment: item.comment,
                        typeLabel: item.typeLabel,
                      })}
                    />
                  )
                })}
                {sosEvents && (
                  <Marker
                    key={`location-marker-${sosEvents.guid}`}
                    position={{ lat: sosEvents.lostLat, lng: sosEvents.lostLon }}
                    onClick={() => setSelectedMarker({
                      guid: sosEvents?.guid || '',
                      type: PETPASS_sos_eventType.SOMEONE_SEND_LOCATION,
                      lat: sosEvents.lostLat,
                      lon: sosEvents.lostLon,
                      address: sosEvents?.lostIn || '',
                      comment: sosEvents?.description || '',
                      typeLabel: sosEvents?.statusLabel || '',
                    })}
                  />
                )}
                {selectedMarker && (
                  <InfoWindow
                    position={{ lat: selectedMarker.lat, lng: selectedMarker.lon }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <LocationCard
                      data={{
                        name: selectedMarker.typeLabel,
                        guid: selectedMarker.guid,
                        location: {
                          name: selectedMarker.address,
                          latitude: selectedMarker.lat,
                          longitude: selectedMarker.lon,
                          latitudeDelta: 0.1,
                          longitudeDelta: 0.1,
                        },
                        imageUrl: `https://maps.googleapis.com/maps/api/staticmap?center=${selectedMarker?.lat},${selectedMarker?.lon}&zoom=20&size=500x500&markers=color:red%7Clabel:C%7C${selectedMarker?.lat},${selectedMarker?.lon}&key=AIzaSyADFUW8ESzmVt8pEGi2iZD8KlrNAZd6LYA`,
                      }}
                      pinIcon={
                        <View>
                          <MapPin width={15} height={15} />
                        </View>
                      }
                      showImage={false}
                    />
                  </InfoWindow>
                )}  
              </GoogleMap>
              {/* <MapPin style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} /> */}
            </MapContainer>
          ) : null
        ) : (
          <>
            {sosEvents?.events.length === 0 ? (
              <>
                {!isLoading && (
                  <PlaceholderContainer>
                    <Text type="H4" style={{ textAlign: 'center' }}>
                      {'Esta alerta SOS no tiene eventos'}
                    </Text>
                  </PlaceholderContainer>
                )}
              </>
            ) : (
              <PlacesItemsContainer>
                {sosEvents?.events.map((item) => (
                  <LocationCard
                    data={{
                      name: item.typeLabel,
                      guid: item.guid,
                      location: {
                        name: item.address,
                        latitude: item.lat,
                        longitude: item.lon,
                        latitudeDelta: 0.1,
                        longitudeDelta: 0.1,
                      },
                      description: item.comment,
                      imageUrl: `https://maps.googleapis.com/maps/api/staticmap?center=${item?.lat},${item?.lon}&zoom=18&size=500x500&markers=color:red%7Clabel:C%7C${item?.lat},${item?.lon}&key=AIzaSyADFUW8ESzmVt8pEGi2iZD8KlrNAZd6LYA`,
                    }}
                    pinIcon={<View>{<MapPin width={15} height={15} />}</View>}
                    onPress={() => {
                      handleCardPress({ latitude: item.lat, longitude: item.lon })
                    }}
                  />
                ))}
              </PlacesItemsContainer>
            )}
          </>
        )}
      </Container>
    </AppBackground>
  )
}
