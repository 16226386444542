import styled from '@emotion/native'
import webStyled from '@emotion/styled'

export const PolicyInfoContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.sizes.xs,
}))

export const Banner = webStyled.img(({ theme }) => ({
  width: '100%',
  height: 200,
  marginBottom: theme.sizes.xs,
}))

export const MainContainer = styled.View(({ theme }) => ({
  flex: 1,
  gap: theme.sizes.sm,
}))

export const SadPetImage = webStyled.img(() => ({
  width: 200,
  height: 200,
  alignSelf: 'center',
  resizeMode: 'cover',
}))