import React from 'react'
import { ViewStyle } from 'react-native'
import { Container, ItemContainer, ItemText, ItemView } from './style'
import { TextSizes } from 'themes'
//import { SvgXml } from 'react-native-svg'

export type sizeItem = 'small' | 'medium' | 'large'

export interface ItemProps {
  title?: string
  style?: ViewStyle
  color: string
  active?: boolean
  onPress?: () => void
  titleAsIcon?: boolean
  size?: sizeItem
  textType?: keyof TextSizes
  svgXml: React.ReactNode
}

const Item: React.FC<ItemProps> = ({
  title,
  style,
  color,
  onPress,
  active = false,
  titleAsIcon = false,
  size = 'medium',
  textType,
  svgXml,
}) => {
  return (
    <Container>
      <ItemContainer active={active} onPress={onPress} size={size}>
        <ItemView isActive={active} color={color}>
          {titleAsIcon ? (
            <ItemText type={textType ?? 'caption'}>{title}</ItemText>
          ) : (
            svgXml
          )}
        </ItemView>
      </ItemContainer>
      {!titleAsIcon && <ItemText type={textType ?? 'caption'}>{title}</ItemText>}
    </Container>
  )
}

export default Item
