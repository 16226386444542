import styled from '@emotion/native'
import { StyleSheet } from 'react-native'
import Text from '../../atoms/Text'

export interface SeparatorProps {
  size: number
}

export const Wrapper = styled.View(() => ({
  flex: 1,
}))

export const Separator = styled.View<SeparatorProps>(({ size }) => ({
  height: size,
  width: size,
}))

export const Title = styled(Text)(({ theme }) => ({
  // textTransform: 'uppercase',
  paddingVertical: theme.sizes.xxxs,
}))

// Not using emotion for FlatList because it is not fully typescript supported
export const flatListStyles = StyleSheet.create({
  style: {
    flexGrow: 0,
  },
})
