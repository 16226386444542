var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { getEnvVariables } from './utils';
import { Platform } from 'react-native';
var API_URL = getEnvVariables().API_URL;
export var axiosCall = axios.create({
    baseURL: "".concat(API_URL),
    headers: {
        'Content-Type': 'application/json',
        'Allow-Cross-Origin': '*',
        'x-app-platform': Platform.OS === 'ios' ? 'IOS' : 'ANDROID',
    },
});
export var removeAllHeaders = function () {
    axiosCall.defaults.headers.common = {};
    axiosCall.interceptors.request.clear();
};
export var addHeadersInterceptors = function (headers) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axiosCall.interceptors.request.use(function (config) {
                    // * add the app guid
                    headers.map(function (header) {
                        config.headers[header.key] = header.value;
                    });
                    return config;
                }, function (error) {
                    return Promise.reject(error);
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var addXHeaders = function (_a) {
    var e360AppGUID = _a.e360AppGUID, e360AppUserGUID = _a.e360AppUserGUID, token = _a.token;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, addHeadersInterceptors([
                        {
                            key: 'x-app',
                            value: e360AppGUID,
                        },
                        {
                            key: 'x-app-user',
                            value: e360AppUserGUID,
                        },
                        {
                            key: 'x-app-user-token',
                            value: token,
                        },
                    ])];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
export * from './Auth';
export * from './Onboarding';
export * from './Home';
export * from './Texts';
export * from './Shop';
export * from './Pet';
export * from './Marketing';
export * from './Statics';
export * from './User';
export * from './Services';
