import React, { ReactElement } from 'react'
import { ResourceContentProps } from '../interfaces'
import { ActionContainer, Container, ImageContainer, PlusCircle, Resource } from './style'
import ResourceImage from '../../../atoms/ResourceImage'
import Text from '../../../atoms/Text'
import { Image, TouchableOpacity, View } from 'react-native'
import { ResourceOnPressProps } from '../../Resource'

export interface ExtraDataSelectedProductResource {
  action?: React.ReactNode
  onPress: (guid: string) => void
  onActionPress: () => void
}

export type SelectedProductItem = ResourceContentProps<ExtraDataSelectedProductResource>

export const SelectedProductResource: React.FC<SelectedProductItem> = ({ value, width }) => {
  const onResourcePress = () => {
    value.extraData?.onPress(value.id)
    console.debug('onResourcePress')
  }
  const onActionPress = () => {
    value.extraData?.onActionPress()
    console.debug('onActionPress')
  }
  return (
    <Container width={width} aspectRatio={value.aspectRatio}>
      <ImageContainer>
        <TouchableOpacity onPress={onResourcePress} style={{ borderRadius: 10, overflow: 'hidden' }}>
          <Image source={{ uri: value.imageUrl }} style={{ width: '100%', height: '100%', resizeMode: 'cover' }} />
        </TouchableOpacity>
      </ImageContainer>
      <ActionContainer>
        <TouchableOpacity onPress={onActionPress}>{value.extraData?.action}</TouchableOpacity>
      </ActionContainer>
    </Container>
  )
}
