import React, { useMemo, useState } from 'react'
import { Circle, Container, InnerCircle } from './styles'
import Text from '../Text'

export interface SelectableItemProps<T = any> {
  title: string
  id: string
  data: T
  onPress?: (_: SelectableItemProps, __: number | undefined) => void
  index?: number
  isSelected?: boolean
}
const SelectableItem: React.FC<SelectableItemProps> = ({ onPress, title, data, id, isSelected, index }) => {
  const onInnerPress = () => {
    onPress?.({ title, id, data }, index)
  }
  return (
    <Container key={id} onPress={onInnerPress}>
      <Text type="caption">{title}</Text>
      <Circle isSelected={isSelected}>
        <InnerCircle isSelected={isSelected} />
      </Circle>
    </Container>
  )
}

export default React.memo(SelectableItem)
