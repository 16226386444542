import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppBackground, BenfitItem, PetPicture, Text } from 'components'
import {
  ActiveContainer,
  ActiveText,
  BenefitsContainer,
  Container,
  DatacContainer,
  PassContainer,
  QRImage,
} from './style'
import { SvgProps } from 'react-native-svg'
import { ScrollView } from 'react-native'

interface BenefitItemProps {
  icon:
    | React.JSX.Element
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
  label: string
  onPress?: () => void
  mark:
    | React.JSX.Element
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
}

interface PetTemplateProps {
  petPassHolder?: React.JSX.Element
  qrUri?: string
  benefits: BenefitItemProps[]
  petPicture: string
  petName: string
  onPetNamePress?: () => void
  PencilIcon?: React.JSX.Element
  allowPkpass?: boolean
  active?: boolean
}

const PetTemplate: React.FC<PetTemplateProps> = ({
  petPassHolder,
  qrUri,
  benefits,
  petPicture,
  petName,
  PencilIcon,
  onPetNamePress,
  allowPkpass,
  active = false,
}) => {

  return (
    <AppBackground isSafe>
      <ScrollView>
        <Container>
          <DatacContainer>
            <PetPicture
              src={petPicture}
              isActive
              PencilIcon={PencilIcon}
              onStatePress={onPetNamePress}
              showState={true}
            />
            <Text type="H3">{`${('pet.greeting')}${petName}`}</Text>
          </DatacContainer>
          <BenefitsContainer>
            {benefits.map((benefit, index) => {
              return (
                <BenfitItem
                  key={index}
                  icon={benefit.icon}
                  label={benefit.label}
                  mark={benefit.mark}
                  onPress={benefit.onPress}
                />
              )
            })}
          </BenefitsContainer>
          {qrUri && (
            <QRImage
              aspectRatio={1}
              source={{
                uri: qrUri,
              }}
              placeHolder={false}
            />
          )}
          {petPassHolder && allowPkpass && <PassContainer>{petPassHolder}</PassContainer>}
          <ActiveContainer active={active}>
            <ActiveText type="H3">{active ? ('pet.active') : ('pet.inactive')}</ActiveText>
            <ActiveText type="H5">{active ? ('pet.activeDescription') : ('pet.inactiveDescription')}</ActiveText>
          </ActiveContainer>
        </Container>
      </ScrollView>
    </AppBackground>
  )
}

export default PetTemplate
