import styled from '@emotion/native'
import { View } from 'react-native'
import Text from '../../atoms/Text'

export const Container = styled(View)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const IconContainer = styled(View)(({ theme }) => ({
  paddingTop: theme.sizes.sm + theme.sizes.xxxs,
}))

export const TextContainer = styled(View)(({ theme }) => ({
  flex: 1,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: theme.sizes.sm,
  paddingBottom: 0,
  paddingTop: 0,
  paddingLeft: theme.sizes.xxs,
}))

export const Title = styled(Text)(({ theme }) => ({}))

export const Subtitle = styled(Text)(({ theme }) => ({
  flexShrink: 1,
}))
