import webStyled from '@emotion/styled'
import styled from '@emotion/native'
import { ResourceImage, Text } from 'components'
import { TouchableOpacity, View } from 'react-native'

export const MainContainer = styled(TouchableOpacity)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.sm,
  backgroundColor: theme.colors.background0,
  width: '100%',
  borderRadius: 8,
  borderColor: '#E8E8E8',
  paddingVertical: theme.sizes.xxs,
  borderWidth: 1,
}))

export const ImageResource = styled(ResourceImage)(({ theme }) => ({
  width: 120,
  height: 120,
  borderRadius: 8,
}))

export const WebImageResource = webStyled.img({
  width: 120,
  height: 120,
  borderRadius: 8,})

export const DataContainer = styled(View)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  gap: theme.sizes.xxs,
}))

export const NameText = styled(Text)(({ theme }) => ({
  fontWeight: '800',
}))

export const LocationContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxxs,
  paddingRight: theme.sizes.lg,
}))

export const StatusContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxxs,
}))

export const InfoTitle = styled(Text)(({ theme }) => ({
  fontWeight: 'bold',
}))
