import { AppBackground, HeaderWithBack, Text } from 'components'
import React, { FC } from 'react'
import { FlatList, Image } from 'react-native'
import Modal from 'react-native-modal'
import { Container, ModalContainer, ModalHeader } from './style'
import { VaccineHistory } from 'apis'
import { TouchableOpacity } from 'react-native'
import { useLocation, useNavigate } from 'react-router-dom'
import { ItemWithIcon } from '../../../components/itemWithIcon'
import { ReactComponent as XSimpleIcon } from '../../../assets/icons/x_simple_grey.svg'
import { ReactComponent as BackIcon } from '../../../assets/icons/back_icon.svg'

export const VaccineHistoryPage: FC = () => {
  const { state } = useLocation()
  console.log('VaccineHistoryPage', state)
  const navigate = useNavigate()
  const { history, name } = state
  const onBackPress = () => {
    navigate(-1)
  }

  const [selectedItemImage, setSelectedItemImage] = React.useState<string>('')

  const handleItemPress = (item: VaccineHistory) => {
    setSelectedItemImage(item.file)
  }

  return (
    <AppBackground isSafe>
      <HeaderWithBack title={'Historial de vacunas'} backPress={onBackPress} backIcon={<BackIcon />} />
      <Container>
        <Text type="H5" style={{ marginBottom: 10 }}>
          {name}
        </Text>
        <FlatList
          data={history}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <ItemWithIcon title={item.date} description={item.ref} onPress={() => handleItemPress(item)} />
          )}
        />
      </Container>
      <Modal
        backdropOpacity={0.7}
        useNativeDriver
        useNativeDriverForBackdrop
        isVisible={!!selectedItemImage}
        onBackdropPress={() => setSelectedItemImage('')}
        onBackButtonPress={() => setSelectedItemImage('')}
        style={{ margin: 20, justifyContent: 'center', alignItems: 'center' }}>
        <ModalContainer>
          <ModalHeader>
            <Text type="H5">{'Vista previa'}</Text>
            <TouchableOpacity onPress={() => setSelectedItemImage('')}>
              <XSimpleIcon width={30} height={30} />
            </TouchableOpacity>
          </ModalHeader>
          {!!selectedItemImage && (
            <Image source={{ uri: selectedItemImage }} style={{ width: '100%', height: 300 }} resizeMode="contain" />
          )}
        </ModalContainer>
      </Modal>
    </AppBackground>
  )
}
