import React from 'react'
import { Container, Line } from './styles'
import Text from '../Text'

const OrElement: React.FC = () => {
  return (
    <Container>
      <Line />
      <Text type="small">o</Text>
      <Line />
    </Container>
  )
}

export default OrElement
