import React, { FC, ReactElement } from 'react'
import { Container, IconContainer, TextContainer, Title, Subtitle } from './style'
import ResourceImage from '../../atoms/ResourceImage'

export interface BulletItemProps {
  title: string
  description: string
  icon: ReactElement
}

export const BulletItem: FC<BulletItemProps> = ({ title, description, icon }) => {
  return (
    <Container>
      {/* {icon && <IconContainer>{icon}</IconContainer>} */}
      {icon && (
        <ResourceImage
          source={{ uri: icon }}
          aspectRatio={1}
          placeHolder={false}
          style={{
            margin: 0,
            padding: 0,
            width: 40,
            height: 40,
          }}
        />
      )}
      <TextContainer>
        <Title type="headline_b">{title}</Title>
        <Subtitle type="paragraph_b" color="foreground4" numberOfLines={2}>
          {description}
        </Subtitle>
      </TextContainer>
    </Container>
  )
}
