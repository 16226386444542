import { AppBackground, Button, HeaderWithBack, PetPicture, Text } from "components";
import React, { useEffect } from "react";
import { ButtonsContainer, Container, ContainerVaccines, HeaderContainer, NameContainer, NameText, PetImage, PictureContainer } from "./styles";
import { ReactComponent as PawIcon } from '../../../../assets/icons/paw.svg'
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg'
import { loadActualVaccineUrl, useAppDispatch, useAppSelector } from "store";
import { useNavigate } from "react-router-dom";
import { usePetVaccines } from "business-logic";
import { Vaccine } from "apis";
import { ItemWithIcon } from "../../../../components/itemWithIcon";

export const Vaccines: React.FC = () => {
  const dogToPublish = useAppSelector((state) => state.selectedPet)
  const appliedVaccines = useAppSelector((state) => state.petDocumentation.appliedVaccines)
  const mandatoryVaccines = useAppSelector((state) => state.petDocumentation.mandatoryVaccines)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getVaccines } = usePetVaccines()

  const handleVaccinePress = (item: Vaccine) => {
    
  }

  const handleAddVaccinePress = (type: 'MANDATORY' | 'OPTIONAL') => {
    console.debug('ADD VACCINE')
    dispatch(loadActualVaccineUrl(''))
    navigate('/services/passport/vaccines/add', { state: { type } })
  }

  const onBackPress = () => {
    navigate('/services/passport')
  }

  useEffect(() => {
    getVaccines()
  }, [])

  return (
    <AppBackground isSafe>
      <HeaderWithBack title={('Vacunas')} backPress={onBackPress} backIcon={<BackIcon />} />
      <Container>
        <HeaderContainer>
          <PictureContainer
            width={180}
            aspectRatio={1}
          >
            <PetImage
              src={dogToPublish?.avatar || ''}
            />
          </PictureContainer>
          <NameContainer>
            <NameText type="H3" fontFamily="bold" fontWeight="bold">
              {('Hola soy ')+dogToPublish?.name}
            </NameText>
          </NameContainer>
        </HeaderContainer>
        {appliedVaccines?.length > 0 && (
          <Text type="H4" fontFamily="bold" fontWeight="bold" textAlign="left">
            {('Estas son mis vacunas')}
          </Text>
        )}
        <ContainerVaccines>
          {appliedVaccines?.length === 0 ? (
            <Text type="H5" textAlign="center">
              {('Aún no tengo vacunas aplicadas')}
            </Text>
          ):(
            <>
              {appliedVaccines?.map((item, index) => (
                <ItemWithIcon
                  title={item.name}
                  description={item.afterHowManyMonthsLabel}
                  key={`${item.guid}-${index}`}
                  onPress={() => handleVaccinePress(item)}
                />
              ))}
            </>
          )}
        </ContainerVaccines>
        {mandatoryVaccines?.length > 0 ? (
          <ButtonsContainer>
            <Button
              title={('Mandatorias')}
              size="small"
              textType="cta_medium"
              borderRadius={5}
              textColor="background0"
              allowIconAndText
              color="primary"
              onPress={() => handleAddVaccinePress('MANDATORY')}
            />
            <Button
              title={('Opcionales')}
              size="small"
              textType="cta_medium"
              borderRadius={5}
              textColor="background0"
              allowIconAndText
              color="primary"
              onPress={() => handleAddVaccinePress('OPTIONAL')}
            />
          </ButtonsContainer>
        ) : (
          <Button
            title={('common.add')}
            icon={<PawIcon width={24} height={24} />}
            size="block"
            textType="cta_medium"
            borderRadius={5}
            textColor="background0"
            allowIconAndText
            color="primary"
            onPress={() => handleAddVaccinePress('OPTIONAL')}
          />
        )}
      </Container>
    </AppBackground>
  );
};


export default Vaccines;