import { addAge, addBirthday, useAppDispatch } from 'store';
export var useAddPetBirthday = function () {
    var dispatch = useAppDispatch();
    var submite = function (_a) {
        var age = _a.age, birthday = _a.birthday;
        if (birthday)
            dispatch(addBirthday(birthday));
        dispatch(addAge(age));
    };
    return {
        submite: submite,
    };
};
