var _a;
import { createSlice } from '@reduxjs/toolkit';
import { Pet_validationStatus, } from 'apis';
var initialState = {
    passport: {
        documentId: '',
        documentStatus: Pet_validationStatus.PENDING,
        documentUpdatedAt: '',
        documentValidatedAt: '',
        documentExpirationDate: '',
        url: '',
        message: '',
        updatedMessage: '',
    },
    chip: {
        legalGuardianDocumentId: '',
        legalGuardianFullName: '',
        microchip: '',
        microchipStatus: Pet_validationStatus.PENDING,
        microchipUpdatedAt: '',
        microchipValidatedAt: '',
        url: '',
        message: '',
        updatedMessage: '',
    },
    vaccines: [],
    mandatoryVaccines: [],
    appliedVaccines: [],
    actualVaccineUrl: ''
};
export var petDocumentationSlice = createSlice({
    name: 'petDocumentation',
    initialState: initialState,
    reducers: {
        loadPetPassport: function (state, action) {
            state.passport.documentId = action.payload.documentId;
            state.passport.documentStatus = action.payload.documentStatus;
            state.passport.documentUpdatedAt = action.payload.documentUpdatedAt;
            state.passport.documentValidatedAt = action.payload.documentValidatedAt;
            state.passport.url = action.payload.url;
            state.passport.message = action.payload.message;
            state.passport.updatedMessage = action.payload.updatedMessage;
        },
        loadPetChip: function (state, action) {
            state.chip.microchip = action.payload.microchip;
            state.chip.microchipStatus = action.payload.microchipStatus;
            state.chip.microchipUpdatedAt = action.payload.microchipUpdatedAt;
            state.chip.microchipValidatedAt = action.payload.microchipValidatedAt;
            state.chip.url = action.payload.url;
            state.chip.message = action.payload.message;
            state.chip.updatedMessage = action.payload.updatedMessage;
        },
        loadPetChipImage: function (state, action) {
            state.chip.url = action.payload;
        },
        loadPetPassportImage: function (state, action) {
            state.passport.url = action.payload;
        },
        loadVaccines: function (state, action) {
            state.vaccines = action.payload;
        },
        loadAppliedVaccines: function (state, action) {
            state.appliedVaccines = action.payload;
        },
        loadMandatoryVaccines: function (state, action) {
            state.mandatoryVaccines = action.payload;
        },
        loadActualVaccineUrl: function (state, action) {
            state.actualVaccineUrl = action.payload;
        },
    },
});
export var loadPetPassport = (_a = petDocumentationSlice.actions, _a.loadPetPassport), loadPetChip = _a.loadPetChip, loadVaccines = _a.loadVaccines, loadPetChipImage = _a.loadPetChipImage, loadPetPassportImage = _a.loadPetPassportImage, loadActualVaccineUrl = _a.loadActualVaccineUrl, loadAppliedVaccines = _a.loadAppliedVaccines, loadMandatoryVaccines = _a.loadMandatoryVaccines;
