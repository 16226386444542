import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'
import Text from '../../atoms/Text'
import Input from '../../molecules/input'

export const Container = styled(View)<{
  variant?: 'outlined' | 'underlined'
}>(({ theme, variant }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  borderColor: variant === 'outlined' ? theme.colors.primary : '#BDBDBD',
  borderWidth: variant === 'outlined' ? 3 : 0,
  borderBottomWidth: variant === 'underlined' ? 1 : 3,
  width: '100%',
  padding: variant === 'outlined' ? theme.sizes.xs : 0 ,
  borderRadius: variant === 'outlined' ? theme.sizes.xs : 0,

}))

export const DropDownContainer = styled(TouchableOpacity)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const TitlePlaceHolder = styled(Text)(({ theme, color }) => ({
  color: '#BDBDBD',
}))

export const TitleSelected = styled(Text)(({ theme }) => ({
  color: 'black',
}))

export const TitleModal = styled(Text)(({ theme }) => ({
  alignSelf: 'flex-start',
}))

export const InputSearch = styled(Input)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.sizes.sm,
}))
