import styled from '@emotion/native'
import { TouchableOpacity, View } from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import Text from '../../../atoms/Text'

export const ItemContainer = styled(View)<{ width: number; aspectRatio: number; variant: string }>(
  ({ theme, width, aspectRatio, variant }) => ({
    borderRadius: 20,
    width: width,
    aspectRatio: aspectRatio,
    backgroundColor: variant == 'selected' ? 'white' : '#E4E4E4',
    borderWidth: variant == 'selected' ? 1 : 0,
    borderColor: theme.colors.primary,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    // height: 180
  }),
)

export const TextContainer = styled(View)<{ variant: string }>(({ theme, variant }) => ({
  flex: 1,
  padding: 10,
  gap: variant == 'selected' ? theme.sizes.xxxs : 0,
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const ActionContainer = styled(View)<{ variant: string }>(({ theme, variant }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: variant == 'selected' ? '100%' : 'auto',
  backgroundColor: variant == 'selected' ? theme.colors.primary : 'transparent',
  padding: variant == 'selected' ? 35 : 0,
}))

export const NameText = styled(Text)(({ theme }) => ({
  fontWeight: '800',
}))

export const LocationContainer = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.sizes.xxxs,
  alignItems: 'center',
}))
