var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { PetGender, loadOwner, loadPet, setLoading, useAppDispatch, useAppSelector } from 'store';
import { getMissingInfoAPI, updateMissingInfoApi } from 'apis';
export var useMissingData = function () {
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var missingData = useAppSelector(function (state) { return state; }).missingData;
    var guid = useAppSelector(function (state) { return state.selectedPet; }).guid;
    var ownerValidationSchema = yup.object().shape({
        name: yup.string().required(t('editProfile.required_name')),
        lastName: yup.string().required(t('editProfile.required_lastname')),
        phone: yup.number().required(t('editProfile.required_phone')),
        email: yup.string().email(t('editProfile.invalid_email')).required(t('editProfile.required_email')),
        documentId: yup.string().required(t('missingData.dni_required')),
        secondLastName: yup.string().notRequired(),
        address: yup.string().required(t('editProfile.required_address')),
        country: yup.string().required(t('missingData.country_required')),
        zipCode: yup.string().required(t('missingData.zipCode_required')),
        locality: yup.string().required(t('missingData.locality_required')),
        province: yup.string().required(t('missingData.province_required')),
        addressInfo: yup.string().notRequired(),
        addressNumber: yup.string().required(t('missingData.addressNumber_required')),
        roadType: yup.string().required(t('missingData.roadType_required')),
    });
    var petValidationSchema = yup.object().shape({
        name: yup.string().required(t('missingData.petName_required')),
        typeGUID: yup.string().required(t('missingData.petSpecies_required')),
        breedGUID: yup.string().required(t('missingData.petBreed_required')),
        motherBreedGUID: yup.string().required(t('missingData.petMotherBreed_required')),
        fatherBreedGUID: yup.string().required(t('missingData.petFatherBreed_required')),
        microchip: yup.string().notRequired(),
        birthdate: yup.string().required(t('missingData.petBirth_required')),
        gender: yup.string().required(t('missingData.petGender_required')),
    });
    var confirmationSchema = yup.object().shape({
        isConfirmed: yup.boolean().isTrue(t('missingData.confirmationError')),
    });
    var confirmationValues = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            isConfirmed: false,
        },
        validationSchema: confirmationSchema,
        validateOnChange: false,
        onSubmit: function () { },
    }), []);
    var petValues = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            typeGUID: '',
            breedGUID: '',
            motherBreedGUID: '',
            fatherBreedGUID: '',
            microchip: '',
            birthdate: '',
            gender: PetGender.MALE,
            isPureBreed: true,
        },
        validationSchema: petValidationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) {
            var isPureBreed = formValues.isPureBreed, trueValues = __rest(formValues, ["isPureBreed"]);
            dispatch(loadPet(trueValues));
        },
    }), []);
    var ownerValues = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            lastName: '',
            phone: '',
            email: '',
            documentId: '',
            address: '',
            secondLastName: '',
            country: '',
            zipCode: '',
            locality: '',
            province: '',
            addressInfo: '',
            addressNumber: '',
            roadType: '',
        },
        validationSchema: ownerValidationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) {
            dispatch(loadOwner(formValues));
        },
    }), []);
    var getMissingInfo = function (petPassPetGUID) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    _k.trys.push([0, 2, , 3]);
                    dispatch(setLoading(true));
                    console.debug('petPassPetGUID', petPassPetGUID);
                    return [4 /*yield*/, getMissingInfoAPI({ petPassPetGUID: petPassPetGUID })];
                case 1:
                    res = _k.sent();
                    if (res.data) {
                        ownerValues.setFieldValue('name', res.data.data.owner.name);
                        ownerValues.setFieldValue('lastName', res.data.data.owner.lastName);
                        ownerValues.setFieldValue('phone', res.data.data.owner.phone);
                        ownerValues.setFieldValue('email', res.data.data.owner.email);
                        ownerValues.setFieldValue('documentId', res.data.data.owner.documentId);
                        ownerValues.setFieldValue('address', res.data.data.owner.address);
                        ownerValues.setFieldValue('secondLastName', res.data.data.owner.secondLastName);
                        ownerValues.setFieldValue('zipCode', res.data.data.owner.zipCode);
                        ownerValues.setFieldValue('locality', res.data.data.owner.locality);
                        ownerValues.setFieldValue('province', res.data.data.owner.province);
                        ownerValues.setFieldValue('country', res.data.data.owner.country);
                        ownerValues.setFieldValue('addressInfo', res.data.data.owner.addressInfo);
                        ownerValues.setFieldValue('addressNumber', res.data.data.owner.addressNumber);
                        ownerValues.setFieldValue('roadType', res.data.data.owner.roadType);
                        petValues.setFieldValue('name', (_a = res.data.data.pet) === null || _a === void 0 ? void 0 : _a.name);
                        petValues.setFieldValue('typeGUID', (_b = res.data.data.pet) === null || _b === void 0 ? void 0 : _b.typeGUID);
                        petValues.setFieldValue('breedGUID', (_c = res.data.data.pet) === null || _c === void 0 ? void 0 : _c.breedGUID);
                        petValues.setFieldValue('motherBreedGUID', (_d = res.data.data.pet) === null || _d === void 0 ? void 0 : _d.motherBreedGUID);
                        petValues.setFieldValue('fatherBreedGUID', (_e = res.data.data.pet) === null || _e === void 0 ? void 0 : _e.fatherBreedGUID);
                        petValues.setFieldValue('microchip', (_f = res.data.data.pet) === null || _f === void 0 ? void 0 : _f.microchip);
                        petValues.setFieldValue('birthdate', (_g = res.data.data.pet) === null || _g === void 0 ? void 0 : _g.birthdate);
                        petValues.setFieldValue('gender', (_h = res.data.data.pet) === null || _h === void 0 ? void 0 : _h.gender);
                        petValues.setFieldValue('isPureBreed', (_j = res.data.data.pet) === null || _j === void 0 ? void 0 : _j.isPureBreed);
                    }
                    dispatch(setLoading(false));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _k.sent();
                    console.error('Error getting contact info', e_1);
                    dispatch(setLoading(false));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var updateMissingData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    dispatch(setLoading(true));
                    return [4 /*yield*/, updateMissingInfoApi({
                            owner: __assign({}, missingData.owner),
                            pet: __assign({ guid: guid }, missingData.pet),
                        })];
                case 1:
                    res = _a.sent();
                    console.debug('res', res);
                    return [2 /*return*/, res.data.isSuccess];
                case 2:
                    e_2 = _a.sent();
                    console.error('Error updating missing data', e_2);
                    return [2 /*return*/, false];
                case 3:
                    dispatch(setLoading(false));
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return {
        ownerValues: ownerValues,
        getMissingInfo: getMissingInfo,
        petValues: petValues,
        confirmationValues: confirmationValues,
        updateMissingData: updateMissingData,
    };
};
