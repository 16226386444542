import styled from '@emotion/native'

export const Container = styled.View(({ theme }) => ({
  width: '100%',
  height: '20%',
  backgroundColor: 'white',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  paddingLeft: theme.sizes.xs,
  paddingRight: theme.sizes.xs,
  paddingTop: theme.sizes.md,
  paddingBottom: theme.sizes.md,
  position: 'absolute',
  bottom: 0,
}))

export const UpperWrapper = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  marginBottom: theme.sizes.md,
}))

export const ProfileDataContainer = styled.View(({}) => ({
  flexDirection: 'row',
  alignItems: 'center',
}))

export const ProfileTextContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  marginLeft: theme.sizes.xs,
}))

export const ProfilePicture = styled.View(({ theme }) => ({
  width: theme.sizes.xl,
  height: theme.sizes.xl,
  borderRadius: theme.sizes.xl,
  borderWidth: 3,
  borderColor: theme.colors.primary,
}))

export const DeleteButton = styled.TouchableOpacity(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
}))
