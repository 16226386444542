import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Passport from '../pages/Passport'
import { TouchableOpacity, View } from 'react-native'
import { Text } from 'components'
import { changeAppState, loadUser, persistor } from 'store'
import { removeAllHeaders } from 'apis'
import { useDispatch } from 'react-redux'
import Sos from '../pages/Sos'
import { NavBar } from './styles'
import Documents from '../pages/Documents'
import Vaccines from '../pages/Vaccines'
import Insurance from '../pages/Insurance'
import { SosHistory } from '../pages/SosHistory'
import AddVaccine from '../pages/AddVaccines'
import { SosMap } from '../pages/SosMap'
import VetChat from '../pages/VetChat'
import NoVetchatAccess from '../pages/NoVetchatAccess'
import { PetEdit } from '../pages/petEdit'
import { VaccineHistoryPage } from '../VaccineHistory'
import SosSelectLocation from '../pages/SosSelectLocation'

const ServicesGroup: React.FC = () => {
  const dispatch = useDispatch()

  const handleLogOut = async () => {
    await persistor.purge()
    await removeAllHeaders()
    dispatch(
      changeAppState({
        appNavigationState: 'NOT_LOGGED_IN',
        isSignUp: false,
        avatar: '',
        counters: {
          pets: 0,
          nearVets: 0,
        },
        guid: '',
        petpassRole: 'OWNER',
        role: 'USER',
        status: 'INACTIVE',
        userName: '',
        email: '',
      }),
    )
    dispatch(
      loadUser({
        avatar: '',
        counters: {
          pets: 0,
          nearVets: 0,
        },
        guid: '',
        petpassRole: 'OWNER',
        role: 'USER',
        status: 'INACTIVE',
        userName: '',
        favVetGUID: '',
        email: '',
      }),
    )
  }

  return (
    <React.Fragment>
      {/* <NavBar>
        <TouchableOpacity onPress={handleLogOut}>
          <Text type="caption">LogOut</Text>
        </TouchableOpacity>
      </NavBar> */}
      <Routes>
        <Route path="passport" element={<Passport />} />
        <Route path="sos" element={<Sos />} />
        <Route path="vetChat" element={<VetChat />} />
        <Route path="vetChat/NoVetChatAccess" element={<NoVetchatAccess />} />
        <Route path="sos/Map" element={<SosMap />} />
        <Route path="sos/history" element={<SosHistory />} />
        <Route path="sos/selectLocation" element={<SosSelectLocation />} />
        <Route path="passport/documents" element={<Documents />} />
        <Route path="passport/PetEdit" element={<PetEdit />} />
        <Route path="passport/vaccines" element={<Vaccines />} />
        <Route path="passport/VaccineHistoryScreen" element={<VaccineHistoryPage />} />
        <Route path="passport/insurance" element={<Insurance />} />
        <Route path="passport/vaccines/add" element={<AddVaccine />} />
      </Routes>
    </React.Fragment>
  )
}

export default ServicesGroup
