var _a;
import { createSlice } from '@reduxjs/toolkit';
export var OAuthProvider;
(function (OAuthProvider) {
    OAuthProvider["GOOGLE"] = "GOOGLE";
    OAuthProvider["APPLE"] = "APPLE";
    OAuthProvider["LINKEDIN"] = "LINKEDIN";
    OAuthProvider["MICROSOFT"] = "MICROSOFT";
    OAuthProvider["NONE"] = "NONE";
})(OAuthProvider || (OAuthProvider = {}));
var initialState = {
    guid: '',
    status: 'DISABLED',
    avatar: '',
    counters: {
        pets: 0,
        nearVets: 0,
    },
    petpassRole: 'OWNER',
    role: 'USER',
    userName: '',
    token: '',
    e360AppGUID: '',
    e360AppUserGUID: '',
    email: '',
    favVetGUID: '',
    provider: OAuthProvider.NONE,
};
export var userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        loadUser: function (state, action) {
            state.guid = action.payload.guid;
            state.avatar = action.payload.avatar;
            state.counters = action.payload.counters;
            state.role = action.payload.role;
            state.petpassRole = action.payload.petpassRole;
            state.status = action.payload.status;
            state.userName = action.payload.userName;
            state.favVetGUID = action.payload.favVetGUID;
            state.email = action.payload.email;
        },
        loadUserToken: function (state, action) {
            state.token = action.payload.token;
        },
        loadUserProvider: function (state, action) {
            state.provider = action.payload.provider;
        },
        setFavVet: function (state, action) {
            state.favVetGUID = action.payload.favVetGUID;
        },
        resetUserState: function (state) {
            Object.assign(state, initialState);
        },
        loadTokenInformation: function (state, action) {
            state.e360AppGUID = action.payload.e360AppGUID;
            state.e360AppUserGUID = action.payload.e360AppUserGUID;
            state.token = action.payload.token;
        },
        loadEmail: function (state, action) {
            state.email = action.payload.email;
        },
    },
});
export var loadUser = (_a = userSlice.actions, _a.loadUser), resetUserState = _a.resetUserState, loadUserToken = _a.loadUserToken, loadTokenInformation = _a.loadTokenInformation, loadEmail = _a.loadEmail, setFavVet = _a.setFavVet, loadUserProvider = _a.loadUserProvider;
