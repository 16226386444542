import React from 'react'
import { ResourceContentProps } from '../interfaces'
import { BannerButton, DataContainer, ImageContainer, ItemContainer, Resource, Subtitle, Title } from './style'
import ResourceImage from '../../../atoms/ResourceImage'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'

export interface ExtraDataBanner {
  buttonLabel: string
}

export type BannerItem = ResourceContentProps<ExtraDataBanner>

export const BannerResource: React.FC<BannerItem> = ({ imageUrlBuilder, onPress, value, width }) => {
  return (
    <ItemContainer width={width} aspectRatio={value.aspectRatio}>
      {/* <DataContainer>
        <Title type="paragraph_a">{value.title}</Title>
        <Subtitle type="caption">{value.description}</Subtitle>
      </DataContainer> */}
      <ImageContainer>
        <Resource
          source={{ uri: imageUrlBuilder({ resource: value, width }) }}
          aspectRatio={value.aspectRatio}
          placeHolder={false}
        />
      </ImageContainer>
    </ItemContainer>
  )
}
