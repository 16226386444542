"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontSizes = exports.fontFamilies = void 0;
var react_native_1 = require("react-native");
var fontFamilies = {
    light: 'Inter-Light',
    regular: 'Inter-Regular',
    medium: 'Inter-Medium',
    semibold: 'Inter-SemiBold',
    bold: 'Inter-Bold',
};
exports.fontFamilies = fontFamilies;
var fontSizes = function (scale, selectDeviceType) {
    return {
        H0A: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(52), Medium: scale(52), Large: scale(52), 'Extra-Large': scale(52) }, scale(52)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        H0B: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(40), Medium: scale(40), Large: scale(40), 'Extra-Large': scale(40) }, scale(40)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        H1A: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(36), Medium: scale(36), Large: scale(36), 'Extra-Large': scale(36) }, scale(36)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        H1B: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(34), Medium: scale(34), Large: scale(34), 'Extra-Large': scale(34) }, scale(34)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        H2: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(28), Medium: scale(28), Large: scale(28), 'Extra-Large': scale(28) }, scale(28)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        H3: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(24), Medium: scale(24), Large: scale(24), 'Extra-Large': scale(24) }, scale(24)),
            fontWeight: 'semibold',
            fontFamily: 'semibold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        H4: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(20), Medium: scale(20), Large: scale(20), 'Extra-Large': scale(20) }, scale(20)),
            fontWeight: 'semibold',
            fontFamily: 'semibold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        H5: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(16), Medium: scale(16), Large: scale(16), 'Extra-Large': scale(16) }, scale(16)),
            fontWeight: 'regular',
            fontFamily: 'regular',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        headline_a: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(18), Medium: scale(18), Large: scale(18), 'Extra-Large': scale(18) }, scale(18)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        paragraph_a: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(18), Medium: scale(18), Large: scale(18), 'Extra-Large': scale(18) }, scale(18)),
            fontWeight: 'regular',
            fontFamily: 'regular',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        headline_b: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(16), Medium: scale(16), Large: scale(16), 'Extra-Large': scale(16) }, scale(16)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        paragraph_b: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(16), Medium: scale(16), Large: scale(16), 'Extra-Large': scale(16) }, scale(16)),
            fontWeight: 'regular',
            fontFamily: 'regular',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        caption: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(14), Medium: scale(14), Large: scale(14), 'Extra-Large': scale(14) }, scale(14)),
            fontWeight: 'regular',
            fontFamily: 'regular',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        small: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(11), Medium: scale(11), Large: scale(11), 'Extra-Large': scale(11) }, scale(11)),
            fontWeight: 'regular',
            fontFamily: 'regular',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: 0,
        },
        cta_small: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(11), Medium: scale(11), Large: scale(11), 'Extra-Large': scale(11) }, scale(11)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: scale(1.12),
        },
        cta_medium: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(14), Medium: scale(14), Large: scale(14), 'Extra-Large': scale(14) }, scale(14)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: scale(1.12),
        },
        cta_large: {
            color: 'foreground0',
            fontSize: selectDeviceType({ Small: scale(16), Medium: scale(16), Large: scale(16), 'Extra-Large': scale(16) }, scale(16)),
            fontWeight: 'bold',
            fontFamily: 'bold',
            lineHeight: react_native_1.Platform.OS === 'ios' ? 0 : undefined,
            letterSpacing: scale(1.12),
        },
    };
};
exports.fontSizes = fontSizes;
